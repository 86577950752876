import { FeatureCollection } from '@deck.gl-community/editable-layers';
import { ArrowsPointingInIcon, XCircleIcon } from '@heroicons/react/24/solid';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useEffect, useRef, useState } from 'react';
import { MapRef } from 'react-map-gl';
import { useUser } from '../../../contexts/auth-context';
import { HistoricalInsightsContext } from '../../../hooks/historical-insights/use-historical-insights';
import MapSection from './MapSection';
import MapSelections from './MapSelections';
import MapToolbar from './MapToolbar';
import StudyList from './StudyList';
import TableData from './TableData';
import YieldCharts from './YieldCharts';

const HistoricalInsights = () => {
  const [year, setYear] = useState<string>('2024');
  const [crop, setCrop] = useState<string>('Corn');
  const [relationship, setRelationship] = useState<string>('variety_label');
  const [varietyYieldPerformance, setVarietyYieldPerformance] =
    useState<string>('x');
  const [page, setPage] = useState<number>(0);
  const [newCenter, setNewCenter] = useState<number[]>([]);
  const [layers, setLayers] = useState<any[]>([]);
  const [creatingStudy, setCreatingStudy] = useState<boolean>(false);
  // actually create the study
  const [createStudy, setCreateStudy] = useState<boolean>(false);
  const [studyDetails, setStudyDetails] = useState<any>({});
  const [studies, setStudies] = useState<any[]>([]);
  const [selectedStudyIndex, setSelectedStudyIndex] = useState<number>(-1);
  const [selectionGridIndicies, setSelectionGridIndicies] = useState<any[]>([]);
  const user = useUser();
  const [selectedDrawMode, setSelectedDrawMode] = useState<number>(0);
  const [modalMode, setModalMode] = useState<string>('');
  // const selectedFeatureIndexes = useRef<number[]>([]);
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState<
    number[]
  >([]);
  const map = useRef<MapRef | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const deck = useRef<any>(null);
  const [viewState, setViewState] = useState({
    longitude: user?.lon ? parseFloat(user?.lon) : -98.381933,
    latitude: user?.lat ? parseFloat(user?.lat) : 44.704991,
    zoom: 12,
    pitch: 30,
    bearing: 0,
    padding: { top: 0, right: 0, bottom: 0, left: 0 },
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [trigger, setTrigger] = useState<number>(0);
  const [rgb, setRgb] = useState<number[]>([0, 0, 0]);
  const [myFeatureCollection, setMyFeatureCollection] =
    useState<FeatureCollection>({
      type: 'FeatureCollection',
      features: [
        /* insert features here */
      ],
    });
  const [editMode, setEditMode] = useState<string>('');
  const [saveEditingStudy, setSaveEditingStudy] = useState<any>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const yieldChartRef = useRef(null);
  const yieldPerformanceChartRef = useRef(null);
  const [pageSize, setPageSize] = useState<number>(5);
  const tableChartRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    if (modalMode === 'drawingModeSelected') {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [modalMode]);

  const [xbin, setxbin] = useState<number[]>([-1, -1]);

  const drawModes = [
    'Move',
    'Polygon',
    'Square',
    'Rectangle',
    'Circle',
    'Drag & Draw',
    'Edit',
    'Transform',
  ];

  const [tableDataContext, setTableDataContext] = useState<any[]>([]);
  const [centerTrigger, setCenterTrigger] = useState<number>(0);

  return (
    <HistoricalInsightsContext.Provider
      value={{
        centerTrigger,
        setCenterTrigger,
        tableDataContext,
        setTableDataContext,
        xbin,
        setxbin,
        layers,
        setLayers,
        tableChartRef,
        pageSize,
        setPageSize,
        yieldChartRef,
        yieldPerformanceChartRef,
        modalMode,
        setModalMode,
        saveEditingStudy,
        setSaveEditingStudy,
        editMode,
        setEditMode,
        myFeatureCollection,
        setMyFeatureCollection,
        rgb,
        setRgb,
        trigger,
        setTrigger,
        viewState,
        setViewState,
        deck,
        selectedDrawMode,
        setSelectedDrawMode,
        selectedFeatureIndexes,
        setSelectedFeatureIndexes,
        selectionGridIndicies,
        setSelectionGridIndicies,
        studies,
        setStudies,
        selectedStudyIndex,
        setSelectedStudyIndex,
        createStudy,
        setCreateStudy,
        studyDetails,
        setStudyDetails,
        creatingStudy,
        setCreatingStudy,
        drawRef,
        map,
        newCenter,
        setNewCenter,
        year,
        setYear,
        crop,
        setCrop,
        relationship,
        setRelationship,
        varietyYieldPerformance,
        setVarietyYieldPerformance,
        page,
        setPage,
      }}
    >
      <div className="space-y-8">
        <MapSelections />
        <div className="grid grid-cols-12 gap-8">
          {/* sidebar */}
          <div className="col-span-4 space-y-4">
            <TableData />
            <YieldCharts />
          </div>
          {/* main content */}
          <div className="col-span-8 space-y-4">
            <div className="relative flex justify-center items-center">
              <div
                onClick={() => setCenterTrigger(centerTrigger + 1)}
                className="flex gap-2 absolute items-center justify-center right-4 top-4 cursor-pointer z-[99] bg-darkerGray px-4 py-2 rounded-md"
              >
                <ArrowsPointingInIcon className="h-5 w-5 cursor-pointer text-white" />
                <p>Recenter</p>
              </div>
              {/* map content */}
              {/* <ReactMapSection /> */}
              {editMode && (
                <div className="flex items-center gap-2 absolute top-4 cursor-pointer z-[99] bg-darkerGray px-4 py-2 rounded-md">
                  <p>Editing Study: </p>
                  <input
                    className="bg-transparent px-2 border-[1px] rounded-md"
                    value={saveEditingStudy?.name}
                    onChange={(e) =>
                      setSaveEditingStudy({
                        ...saveEditingStudy,
                        name: e.target.value,
                      })
                    }
                  />
                  <XCircleIcon
                    onClick={() => {
                      setEditMode('');
                      setMyFeatureCollection({
                        type: 'FeatureCollection',
                        features: [],
                      });
                    }}
                    className="h-5 w-5 cursor-pointer text-white"
                  />
                </div>
              )}

              {modalMode === 'drawingModeSelected' && (
                <>
                  <div
                    className={`absolute z-[99] bg-white text-black ${editMode ? 'top-16' : 'top-4'} px-4 py-2 rounded-md`}
                  >
                    <p>
                      Click point to start drawing {drawModes[selectedDrawMode]}
                    </p>
                  </div>
                  <div
                    className={`absolute z-[99] ${editMode ? 'top-24' : 'top-14'}  w-6 h-3 bg-white`}
                    style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                  ></div>
                </>
              )}

              {modalMode === 'drawingShape' && (
                <>
                  <div
                    className={`absolute z-[99] bg-white text-black ${editMode ? 'top-16' : 'top-4'} px-4 py-2 rounded-md`}
                  >
                    {drawModes[selectedDrawMode] === 'Polygon' ? (
                      <p>Click first point again to close Polygon</p>
                    ) : (
                      <p>Draw {drawModes[selectedDrawMode]}</p>
                    )}
                  </div>
                  <div
                    className={`absolute z-[99] ${editMode ? 'top-24' : 'top-14'}  w-6 h-3 bg-white`}
                    style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                  ></div>
                </>
              )}

              {modalMode === 'editPolygons' && (
                <>
                  <div
                    className={`absolute z-[99] bg-white text-black ${editMode ? 'top-16' : 'top-4'} px-4 py-2 rounded-md`}
                  >
                    <p>Edit Polygon Points</p>
                  </div>
                  <div
                    className={`absolute z-[99] ${editMode ? 'top-24' : 'top-14'}  w-6 h-3 bg-white`}
                    style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                  ></div>
                </>
              )}

              {modalMode === 'transformPolygons' && (
                <>
                  <div
                    className={`absolute z-[99] bg-white text-black ${editMode ? 'top-16' : 'top-4'} px-4 py-2 rounded-md`}
                  >
                    <p>Transform Polygons</p>
                  </div>
                  <div
                    className={`absolute z-[99] ${editMode ? 'top-24' : 'top-14'}  w-6 h-3 bg-white`}
                    style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                  ></div>
                </>
              )}

              {modalMode === 'drawingCompleted' && (
                <>
                  <div
                    className={`cursor-pointer flex gap-2 items-center absolute z-[99] bg-white text-black ${editMode ? 'top-16' : 'top-4'} px-4 py-2 rounded-md`}
                  >
                    <p className="text-center">
                      {drawModes[selectedDrawMode]} Finished
                    </p>
                    <div
                      onClick={() => setModalMode('drawingModeSelected')}
                      className="bg-primary text-white px-2 py-1 rounded-md"
                    >
                      Ok
                    </div>
                    <div
                      onClick={() => {
                        let newFeatures = myFeatureCollection.features.slice(
                          0,
                          -1
                        );
                        setMyFeatureCollection({
                          type: 'FeatureCollection',
                          features: newFeatures,
                        });
                        setModalMode('drawingModeSelected');
                      }}
                      className="bg-darkGray text-white px-2 py-1 rounded-md text-center"
                    >
                      Delete Shape
                    </div>
                  </div>
                  <div
                    className={`absolute z-[99] ${editMode ? 'top-24' : 'top-16'}  w-6 h-3 bg-white`}
                    style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                  ></div>
                </>
              )}

              {editMode && (
                <div
                  onClick={() => {
                    setEditMode('save');
                    setSelectedDrawMode(0);
                    setModalMode('');
                  }}
                  className="absolute bottom-4 cursor-pointer z-[99] bg-primary px-4 py-2 rounded-md"
                >
                  <p>Save Study</p>
                </div>
              )}

              <MapSection />
            </div>
            <MapToolbar />
            <StudyList />
          </div>
        </div>
      </div>
    </HistoricalInsightsContext.Provider>
  );
};

export default HistoricalInsights;
