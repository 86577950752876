import { PlusCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import FlipLayout from '../../commons/FlipLayout';
import AddStorageForm from './AddStorageForm';

export default function StorageOptions() {
  return (
    <FlipLayout>
      {({ side, setSide: setMode }) => (
        <div>
          {side === 'front' ? (
            <div className="space-y-8">
              <CardHeader title="Add Storage" tooltip={'tooltip'} />

              <div className={clsx('flex justify-center')}>
                <Button
                  color="primary"
                  size="lg"
                  shadow
                  startIcon={<PlusCircleIcon className="size-6" />}
                  onClick={() => setMode('back')}
                >
                  Add Storage
                </Button>
              </div>
            </div>
          ) : (
            <AddStorageForm onCancel={() => setMode('front')} />
          )}
        </div>
      )}
    </FlipLayout>
  );
}
