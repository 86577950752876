import {
  CreateInsurancePayload,
  DeleteInsurancePayload,
  UpdateInsurancePayload,
} from '../types/insurance';
import apiClient from './api-client';

export const getInsurancesApi = ({
  userId,
  year,
  scenarioId,
}: {
  userId: string;
  year: string;
  scenarioId?: string;
}) => {
  let query = `?user_id=${userId}&year=${year}`;
  query = scenarioId ? query + `&scenario_id=${scenarioId}` : query;
  return apiClient.get(`/insurance${query}`);
};

export const createInsuranceApi = (payload: CreateInsurancePayload) => {
  return apiClient.post('/insurance', payload);
};

export const updateInsuranceApi = (payload: UpdateInsurancePayload) => {
  return apiClient.post('/insurance/update', payload);
};

export const deleteInsuranceApi = (payload: DeleteInsurancePayload) => {
  return apiClient.post('/insurance/delete', payload);
};
