import CropIcon from '../../../components/commons/CropIcon';
import Currency from '../../../components/commons/Currency';
import Fluctuation from '../../../components/commons/Fluctuation';
import PageHeader from '../../../components/commons/PageHeader';
import ExpectedRevenue14ChangeChart from '../../../components/dashboard/expected-revenue-card/ExpectedRevenueChart';
import { SupportedCrops } from '../../../constants';
import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getTotalNetIncome,
  getTotalNetIncomeByCrop,
  getTotalRevenue,
  getTotalRevenueByCrop,
  useFarmRevenues,
} from '../../../hooks/farm/use-farm-revenues';

function ExpectedRevenuePage() {
  const user = useUser();
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();
  const { data } = useFarmRevenues(user?.id ?? '', year || '');

  const totalRevenue = getTotalRevenue(data);
  const totalNetIncome = getTotalNetIncome(data);
  const totalRevenueByCrop = getTotalRevenueByCrop(data);
  const totalNetIncomeByCrop = getTotalNetIncomeByCrop(data);

  const incomeToView = incomeView === 'revenue' ? totalRevenue : totalNetIncome;
  const incomeByCropToView =
    incomeView === 'revenue' ? totalRevenueByCrop : totalNetIncomeByCrop;

  return (
    <div className="space-y-8">
      <PageHeader
        title={`Expected ${incomeView === 'revenue' ? 'Revenue' : 'Net Income'}`}
      />

      {/* Totals */}
      <div className="flex flex-col items-center space-y-3">
        <Currency
          value={incomeToView.total}
          className="text-secondary display-xl-bold"
        />
        <Fluctuation
          value={incomeToView.change}
          currency="USD"
          context="Prev. Close"
        />
      </div>

      {/* Blocks */}
      <div className="grid grid-cols-12 gap-8">
        {SupportedCrops.map((crop) => (
          <div className="card p-12 col-span-12 lg:col-span-4 flex flex-col items-center space-y-2">
            <CropIcon size="large" cropName={crop} />
            <span className="flex flex-col items-center">
              <Currency
                value={incomeByCropToView[crop]?.total ?? 0}
                className="display-md-bold"
              />
              <Fluctuation
                value={incomeByCropToView[crop]?.change ?? 0}
                currency="USD"
                context="Prev. Close"
              />
            </span>
            <span className="flex flex-col items-center">
              <Currency
                value={incomeByCropToView[crop]?.totalPerAcre ?? 0}
                className="display-md-bold"
              />
              <Fluctuation
                value={incomeByCropToView[crop]?.changePerAcre ?? 0}
                currency="USD"
                context="Per Acre"
              />
            </span>
          </div>
        ))}
      </div>

      {/* Chart */}
      <div className="card card-elevated p-8 flex flex-col items-center">
        <ExpectedRevenue14ChangeChart width={1230} height={350} />
        <p className="display-md-bold text-center ">Last 14 Days</p>
      </div>
    </div>
  );
}
export default ExpectedRevenuePage;
