import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import {
  BellAlertIcon,
  ChartBarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardIcon,
  CogIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { useMemo, useState } from 'react';

import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import AddToWatchList from './AddToWatchList';
import CreateAlert from './CreateAlert';

import { useUser } from '../../../contexts/auth-context';
import { useAlerts, useDeleteAlert } from '../../../hooks/market/use-alerts';
import { useAllFutures } from '../../../hooks/market/use-futures';
import {
  capitalizeFirst,
  formatAlertDate,
  formatSpreadDate,
  formatStrikePriceToDollars,
} from '../../../utils';
import Button from '../../commons/Button';
import Currency from '../../commons/Currency';
import Fluctuation from '../../commons/Fluctuation';
import AddToWatchlistButton from './AddCurrentViewToWatchList';
import EditAlert from './EditAlert';
import { commodityOptions } from './PriceChart';
import FutureIcon from '../../../assets/icons/FutureIcon';
import BasisIcon from '../../../assets/icons/BasisIcon';
import OptionIcon from '../../../assets/icons/OptionIcon';
import { useMarketContext } from '../../../contexts/market-context';
import Table from '../../commons/table/Table';
import { alertLogTableConfigs } from '../../../constants/tableConfigs';
import CardHeader from '../../commons/CardHeader';
import { intervalTitles } from '../../../constants';
import { TriangleDownIcon } from '../../../assets/icons';

const Icon: Record<string, any> = {
  futures: <FutureIcon />,
  basis: <BasisIcon />,
  options: <OptionIcon />,
  cash: <FutureIcon />,
  spread: <FutureIcon />,
};

const MarketAlertsSidebar = () => {
  const [isCreatingNewAlert, setIsCreatingNewAlert] = useState(false);
  const [creatingNewAlertType, setCreatingNewAlertType] = useState('');
  const [alertNotificationType, setAlertNotificationType] = useState('');
  const [showAddAlertDropdown, setShowAddAlertDropdown] = useState(-1);

  const [isCreatingWatchItem, setIsCreatingWatchItem] = useState(false);
  const user = useUser();
  const { mutate: deleteAlert } = useDeleteAlert(user?.id || '');
  const queryClient = useQueryClient();
  const alerts = useAlerts(user!.id);

  const futures = useAllFutures('KE');

  const {
    isUpdatingEditAlert,
    setIsUpdatingEditAlert,
    updatingAlertIndex,
    setUpdatingAlertIndex,
  } = useMarketContext();

  const handleAddClick = (alertType: string, index: number) => {
    setCreatingNewAlertType(alertType);
    setShowAddAlertDropdown(showAddAlertDropdown === -1 ? index : -1);
  };

  const handleOptionClick = (alertNotificationType: string, option: string) => {
    setCreatingNewAlertType(option);
    setIsCreatingNewAlert(true);
    setShowAddAlertDropdown(-1);
    setAlertNotificationType(alertNotificationType);
  };

  // Function to determine if an alert is triggered
  const isTriggered = (alert: any) => {
    if (alert.condition.toLowerCase().includes('below')) {
      return parseFloat(alert.price) < parseFloat(alert.alertPrice);
    } else if (alert.condition.toLowerCase().includes('above')) {
      return parseFloat(alert.price) > parseFloat(alert.alertPrice);
    } else {
      return parseFloat(alert.price) === parseFloat(alert.alertPrice);
    }
  };

  const formattedAlerts = useMemo(() => {
    const groupedAlertsByType: any = {
      futures: { triggered: [], active: [], 'Market Update': [] },
      options: { triggered: [], active: [], 'Market Update': [] },
      basis: { triggered: [], active: [], 'Market Update': [] },
      cash: { triggered: [], active: [], 'Market Update': [] },
      spread: { triggered: [], active: [], 'Market Update': [] },
    };

    alerts?.data?.alerts.forEach((alert: any) => {
      let currentPrice;
      let change;
      if (alert.type === 'future') {
        const futurescontract = futures.data?.find((item: any) => {
          return item.symbol === alert.symbol;
        });
        currentPrice = parseFloat(futurescontract?.last!).toFixed(2) ?? 0;
        change = parseFloat(futurescontract?.change!).toFixed(2) ?? 0;
      }

      const formattedAlert = {
        id: alert['user_id-type-crop-basis_location'],
        sk: alert['date'],
        alertVia: alert.alertVia,
        basis: alert?.basis_value,
        commodity: alert.crop.charAt(0).toUpperCase() + alert.crop.slice(1),
        condition: `${capitalizeFirst(alert.dateRange)} at or ${capitalizeFirst(alert.priceRange)} $${alert.type === 'basis' ? alert.basis_value : alert.price}`,
        price:
          alert.type === 'basis'
            ? parseFloat(alert.lastBasis).toFixed(2)
            : alert.type === 'cash'
              ? (
                  parseFloat(alert.lastBasis) + parseFloat(alert.lastPrice)
                ).toFixed(2)
              : parseFloat(alert.lastPrice).toFixed(2),
        change:
          alert.type === 'basis'
            ? parseFloat(
                alert.basisChange == '' ? '0' : alert.basisChange
              ).toFixed(2)
            : parseFloat(alert.change == '' ? '0' : alert.change).toFixed(2),
        date:
          alert.type === 'spread'
            ? formatSpreadDate(
                alert.month1,
                alert.month2,
                alert.year1,
                alert.year2
              )
            : formatAlertDate(alert['date']),
        unformattedDate: alert['date'],
        type: alert.type.charAt(0).toUpperCase() + alert.type.slice(1),
        optionType: alert.option_type ?? '',
        strikePrice: formatStrikePriceToDollars(alert.strike_price) ?? '',
        alertPrice: alert.price,
        basis_company: alert.basis_company,
        basis_location: alert.basis_location,
        updatedAt: alert.updatedAt,
        interval: intervalTitles[alert.interval as keyof typeof intervalTitles],
      };

      if (alert.interval) {
        groupedAlertsByType[alert.type]['Market Update'].push(formattedAlert);
      } else if (isTriggered(formattedAlert)) {
        groupedAlertsByType[alert.type].triggered.push(formattedAlert);
      } else {
        groupedAlertsByType[alert.type].active.push(formattedAlert);
      }
    });

    return groupedAlertsByType;
  }, [alerts]);

  const logFormattedAlerts = useMemo(() => {
    // format by contract type: futures, options, etc
    if (!alerts?.data?.alerts) return;

    const formattedAlerts: any = {};

    alerts.data.alerts.forEach((alert: any) => {
      const formattedAlert = {
        id: alert['user_id-type-crop-basis_location'],
        commodity: alert.crop,
        date:
          alert.type === 'spread'
            ? formatSpreadDate(
                alert.month1,
                alert.month2,
                alert.year1,
                alert.year2
              )
            : formatAlertDate(alert['date']),
        price: alert.price,
        change: alert.change,
        type: alert.type,
        basisLocation: alert.basisLocation,
        basisCompany: alert.basisCompany,
        updatedAt: new Date(alert.updatedAt).toLocaleString(),
        userid: alert.user_id,
        username: alert?.username,
      };

      if (!formattedAlerts[alert.type]) {
        formattedAlerts[alert.type] = [];
      }

      formattedAlerts[alert.type].push(formattedAlert);
    });

    return formattedAlerts;
  }, [alerts.data?.alerts]);

  return (
    <div className="w-full space-y-4">
      <div onClick={() => setIsCreatingWatchItem(!isCreatingWatchItem)}>
        <AddToWatchlistButton />
      </div>

      {isCreatingWatchItem && (
        <AddToWatchList setIsCreatingWatchItem={setIsCreatingWatchItem} />
      )}

      <div className="flex items-center space-x-2">
        <BellAlertIcon className="size-6" />
        <h2 className="text-2xl">Market Alerts</h2>
      </div>

      {isCreatingNewAlert ? (
        <CreateAlert
          isCreatingNewAlert={isCreatingNewAlert}
          setIsCreatingNewAlert={setIsCreatingNewAlert}
          creatingNewAlertType={creatingNewAlertType}
          alertNotificationType={alertNotificationType}
        />
      ) : (
        formattedAlerts &&
        Object.keys(formattedAlerts).map((alertType, index) => (
          <div key={index} className="card overflow-hidden">
            <div className="flex items-center justify-between hlGradiant py-3 px-4">
              <div className="flex space-x-2">
                {/* Display Icon and alertType text */}
                {Icon[alertType]}
                <p className="font-bold text-xl">
                  {alertType.slice(0, 1).toUpperCase() + alertType.slice(1)}
                </p>
              </div>

              <div className="relative">
                <div
                  onClick={() => handleAddClick(alertType, index)}
                  className="flex items-center gap-2 bg-yellow p-2 rounded-md cursor-pointer"
                >
                  <span>{isCreatingNewAlert ? 'Cancel' : 'Add'}</span>
                  <TriangleDownIcon
                    className="size-8 text-white bg-white"
                    fill="white"
                  />
                </div>

                {showAddAlertDropdown === index && (
                  <div className="w-40 absolute mt-2 right-0 bg-gray-700 rounded-md shadow-lg">
                    <div
                      onClick={() =>
                        handleOptionClick('Price Trigger', alertType)
                      }
                      className="px-4 py-2 text-white cursor-pointer rounded-md hover:bg-gray-600"
                    >
                      Price Trigger Alert
                    </div>
                    <div
                      onClick={() =>
                        handleOptionClick('Market Update', alertType)
                      }
                      className="px-4 py-2 rounded-md text-white cursor-pointer hover:bg-gray-600"
                    >
                      Market Update Alert
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="p-4">
              {['triggered', 'active', 'Market Update'].map((category) => (
                <Disclosure defaultOpen={true} key={alertType + category}>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="my-2 flex p-0 border-[0px] justify-between items-center w-full">
                        <h3 className="text-lg-bold">
                          {capitalizeFirst(category)}
                        </h3>
                        {open ? (
                          <ChevronUpIcon className="h-5 w-5" />
                        ) : (
                          <ChevronDownIcon className="h-5 w-5" />
                        )}
                      </DisclosureButton>
                      <DisclosurePanel className="space-y-4">
                        {formattedAlerts[alertType][category].map(
                          (alert: any, index: number) => (
                            <>
                              <div
                                key={alert.id}
                                className="flex card items-center justify-between p-2 px-4"
                              >
                                <div className="flex items-center space-x-4">
                                  <Button
                                    onClick={() => {
                                      if (updatingAlertIndex === -1) {
                                        setIsUpdatingEditAlert(true);
                                        setUpdatingAlertIndex(
                                          alert.id + alert.sk
                                        );
                                      } else {
                                        setIsUpdatingEditAlert(false);
                                        setUpdatingAlertIndex(-1);
                                      }
                                    }}
                                    className="max-w-2 h-8 bg-gray"
                                  >
                                    <PencilSquareIcon className="h-5 w-5" />
                                  </Button>
                                  {commodityOptions.filter(
                                    (option) => option.value === alert.commodity
                                  )[0].icon ?? <></>}
                                  <div>
                                    <p className="">
                                      {alert.commodity} {alert.date}
                                    </p>
                                    {(alert.type === 'Basis' ||
                                      alert.type === 'Cash') && (
                                      <p className="">
                                        {alert.basis_company} &{' '}
                                        {alert.basis_location}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                  {alert.type === 'Options' && (
                                    <div
                                      className={`${alert.change < 0 ? 'text-red' : 'text-green'} mr-2`}
                                    >
                                      <p className="">{alert.strikePrice}</p>

                                      <p className="text-sm flex">
                                        {alert.optionType}
                                      </p>
                                    </div>
                                  )}
                                  <div className="flex flex-col mr-2">
                                    <div
                                      className={`${alert.change < 0 ? 'text-red' : 'text-green'} w-full items-center justify-between gap-2 mr-2 flex`}
                                    >
                                      <Currency
                                        value={Number(parseFloat(alert.price))}
                                      />
                                      <Fluctuation
                                        value={Number(alert.change)}
                                        currency="USD"
                                      />
                                    </div>
                                    <p className="text-sm">
                                      {alert.interval
                                        ? alert.interval
                                        : alert.condition}
                                    </p>
                                  </div>

                                  <Button
                                    onClick={() => {
                                      const resp = confirm(
                                        `Are you sure you want to delete alert: ${alert.commodity} ${alert.date}`
                                      );
                                      if (!resp) {
                                        return;
                                      }
                                      deleteAlert(
                                        {
                                          id: alert.id,
                                          date: alert.unformattedDate,
                                        },
                                        {
                                          onSuccess: () => {
                                            queryClient.invalidateQueries([
                                              'market/alerts',
                                              user?.id ?? '',
                                            ] as InvalidateQueryFilters);
                                            window.alert(
                                              'Alert deleted successfully'
                                            );
                                          },
                                          onError: (error: Error) => {
                                            console.error(
                                              'Error creating alert:',
                                              error
                                            );
                                            window.alert(
                                              'Failed to delete alert. Please try again.'
                                            );
                                          },
                                        }
                                      );
                                    }}
                                    className="max-w-2 h-8"
                                  >
                                    <XMarkIcon className="size-6 cursor-pointer" />
                                  </Button>
                                </div>
                              </div>

                              <div className="w-full">
                                {isUpdatingEditAlert &&
                                  updatingAlertIndex ===
                                    alert.id + alert.sk && (
                                    <EditAlert
                                      alert={alert}
                                      isUpdatingEditAlert={isUpdatingEditAlert}
                                      setIsUpdatingEditAlert={
                                        setIsUpdatingEditAlert
                                      }
                                      alertNotificationType={
                                        alertNotificationType as
                                          | 'Price Trigger'
                                          | 'Market Update'
                                      }
                                    />
                                  )}
                              </div>
                            </>
                          )
                        )}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        ))
      )}

      <div className="w-full mt-8 card p-0">
        <Disclosure>
          {({ open }) => (
            <>
              <DisclosureButton className="flex justify-between items-center w-full p-4 rounded-lg">
                <p className="text-2xl text-bold flex items-center gap-2">
                  <ClipboardIcon className="size-6" /> Alert Log
                </p>
                {open ? (
                  <ChevronUpIcon className="w-5 h-5" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5" />
                )}
              </DisclosureButton>
              <DisclosurePanel className="p-4">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex justify-between items-center w-full p-4 rounded-lg">
                        <span className="font-semibold text-base-500">
                          Futures
                        </span>
                        {open ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5" />
                        )}
                      </DisclosureButton>
                      <DisclosurePanel className="p-4">
                        <Table
                          data={logFormattedAlerts?.futures}
                          configs={alertLogTableConfigs('')}
                        />
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex justify-between items-center w-full p-4 rounded-lg">
                        <span className="font-semibold text-base-500">
                          Options
                        </span>
                        {open ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5" />
                        )}
                      </DisclosureButton>
                      <DisclosurePanel className="p-4">
                        <Table
                          data={logFormattedAlerts?.options}
                          configs={alertLogTableConfigs('')}
                        />
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex justify-between items-center w-full p-4 rounded-lg">
                        <span className="font-semibold text-base-500">
                          Basis
                        </span>
                        {open ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5" />
                        )}
                      </DisclosureButton>
                      <DisclosurePanel className="p-4">
                        <Table
                          data={logFormattedAlerts?.basis}
                          configs={alertLogTableConfigs('')}
                        />
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex justify-between items-center w-full p-4 rounded-lg">
                        <span className="font-semibold text-base-500">
                          Spreads
                        </span>
                        {open ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5" />
                        )}
                      </DisclosureButton>
                      <DisclosurePanel className="p-4">
                        <Table
                          data={logFormattedAlerts?.spread}
                          configs={alertLogTableConfigs('')}
                        />
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default MarketAlertsSidebar;
