import clsx from 'clsx';
interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary';
}

export default function IconButton(props: IconButtonProps) {
  const { children, className, color = 'secondary', ...rest } = props;

  return (
    <button
      className={clsx(
        'iconButton border border-block-fill rounded flex items-center justify-center',
        'min-h-6 min-w-6 p-1.5',
        color,
        className
      )}
      style={{
        backgroundImage:
          'linear-gradient(313.91deg, #222C34 53.63%, #3E4951 98.05%',
      }}
      {...rest}
    >
      {children}
    </button>
  );
}
