import React from 'react';
import { useHealthData } from '../../../hooks/health/use-health';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import { capitalizeFirst } from '../../../utils';

function Health() {
  const healthTableConfig = (): TableConfigs => {
    return {
      cols: [
        {
          key: 'type',
          name: 'Type',
          type: 'element',
          element: (item) => (
            <div>{capitalizeFirst(item.type.split('_').join(' '))}</div>
          ),
        },
        {
          key: 'status',
          name: 'Status',
          type: 'element',
          element: (item) => (
            <div>
              <span
                className={`badge ${item.status === 'UP' ? 'bg-success' : 'bg-danger'}`}
              >
                {item.status === 'Success' ? '✅' : '❌'}
              </span>
            </div>
          ),
        },
        {
          key: 'createdAt',
          name: 'Created At',
          type: 'element',
          element: (item) => (
            <div>{new Date(item.createdAt).toLocaleString()}</div>
          ),
        },
      ],
    };
  };

  const { data: healthChecks } = useHealthData(['all']);

  return (
    <div className="flex flex-col gap-8">
      <p className="text-2xl text-primary">AgriVaR Health Checks</p>
      <Table
        title={{ name: 'Web Socket Health' }}
        data={healthChecks}
        configs={healthTableConfig()}
      />
      <Table
        title={{ name: 'Farm Revenue Health' }}
        data={[]}
        configs={healthTableConfig()}
      />
    </div>
  );
}

export default Health;
