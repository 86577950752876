import { useMutationState } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../Button';
import TextInput from '../inputs/TextInput';

export default function NoteForm({
  item,
  onSave,
  onSaveAll,
}: {
  item: any;
  onSave: (item: any, note: string) => void;
  onSaveAll?: (item: any, note: string) => void;
}) {
  const form = useForm({
    defaultValues: {
      note: item?.note,
    },
  });

  const createNoteStatus = useMutationState({
    filters: {
      mutationKey: ['CREATE', 'note'],
    },
    select: ({ state }) => state.status,
  });
  const creatingNote = createNoteStatus.includes('pending');

  const handleSaveNote = ({ note }: { note: string }) => {
    onSave(item, note);
  };

  const handleSaveNoteAll = ({ note }: { note: string }) => {
    onSaveAll?.(item, note);
  };

  return (
    <FormProvider {...form}>
      <form className="space-y-4">
        <TextInput
          name="note"
          className="!bg-base-000 !border-0 text-base-1100"
          required
        />
        <div className="flex space-x-2 justify-end">
          <Button
            size="sm"
            color="primary"
            className="!w-fit"
            onClick={form.handleSubmit(handleSaveNote)}
            loading={creatingNote}
            disabled={!form.formState.isValid || creatingNote}
          >
            Save
          </Button>
          {onSaveAll && (
            <Button
              size="sm"
              color="primary"
              className="!w-fit"
              onClick={form.handleSubmit(handleSaveNoteAll)}
              loading={creatingNote}
              disabled={!form.formState.isValid || creatingNote}
            >
              Save and Apply to all zone
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
