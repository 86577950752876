import {
  Menu as HMenu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuItemsProps,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';

export default function Menu({
  items,
  children,
  buttonClassName,
  anchor = 'bottom end',
  disabled = false,
}: {
  items: { value: string; label: string; onClick?: () => void }[];
  children: string | React.ReactNode;
  buttonClassName?: string;
  anchor?: MenuItemsProps['anchor'];
  disabled?: boolean;
}) {
  return (
    <HMenu>
      <MenuButton
        className={clsx(buttonClassName, 'cursor-pointer')}
        disabled={disabled}
        as="div"
      >
        {children}
      </MenuButton>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          unmount={false}
          anchor={anchor}
          className={clsx(
            'menuItems origin-top-right rounded-xl mt-2',
            'bg-block-fill text-base-500 shadow-xl whitespace-nowrap p-2 flex flex-col',
            'border border-base-1000'
          )}
        >
          {items.map((item) => (
            <MenuItem key={item.label}>
              {({ focus }) => (
                <button
                  className={clsx(
                    'rounded text-base-000',
                    'flex hover:!text-primary py-2 px-3',
                    'group-data-[theme=Heartland]/root:text-onsurface',
                    'selectOption',
                    focus ? 'bg-base-900' : '',
                    { focus: focus }
                  )}
                  onClick={() => item.onClick?.()}
                >
                  {item.label}
                </button>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </HMenu>
  );
}
