import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useBLMData,
  useSoilSampleZoneFieldOptions,
  useUpdateSoilSample,
} from '../../../hooks/soil-sample/use-soil-sample';
import { useDebounce } from '../../../hooks/use-debounce';
import { SoilSampleFormValues, SoilSampleItem } from '../../../types';
import Button from '../../commons/Button';
import ComboDatePicker from '../../commons/inputs/ComboDatePicker';
import TextInput from '../../commons/inputs/TextInput';
import Select, { AwaitSelect } from '../../commons/Select';

interface EditSoilSampleFormProps {
  data: SoilSampleItem;
  onClose: () => void;
}

export default function EditSoilSampleForm({
  data,
  onClose,
}: EditSoilSampleFormProps) {
  const user = useUser();

  const { mutateAsync: update, isPending } = useUpdateSoilSample();
  const form = useForm({
    defaultValues: {
      Date: data.Date,
      legal_description: data.legal_description,
      geometry: data.geometry,
      other_zone: data.other_zone,
      range: data.range,
      section: data.section,
      soil_sample_type: data.soil_sample_type,
      township: data.township,
      Zn_ppm: data.Zn_ppm,
      S1_lb: data.S1_lb,
      S2_lb: data.S2_lb,
      Salt1: data.Salt1,
      Salt2: data.Salt2,
      pH: data.pH,
      P_ppm: data.P_ppm,
      N1_lb: data.N1_lb,
      N2_lb: data.N2_lb,
      Na_ppm: data.Na_ppm,
      OM: data.OM,
      Cu_ppm: data.Cu_ppm,
      K_ppm: data.K_ppm,
      Mg_ppm: data.Mg_ppm,
      Ca_ppm: data.Ca_ppm,
      CEC_meq: data.CEC_meq,
      BS_Na_pct: data.BS_Na_pct,
      BS_Mg_pct: data.BS_Mg_pct,
      BS_K_pct: data.BS_K_pct,
      BS_Ca_pct: data.BS_Ca_pct,
    } as SoilSampleFormValues & {
      field: string;
      zone: string;
      legals_json: string;
    },
  });

  const ignoreValues = ['field', 'zone'];
  const handleSubmit = async (values: SoilSampleFormValues) => {
    if (!user) return;
    const updates = Object.entries(values)
      .filter(([key]) => !ignoreValues.includes(key))
      .map(([key, value]) => ({
        key,
        value: value?.toString() ?? '',
      }));

    try {
      await update({
        userId: data.user_id,
        date: data.Date,
        user_id_legal_description_other_zone:
          data.user_id_legal_description_other_zone,
        updates,
      });
      enqueueSnackbar('Soil Sample updated successfully', {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      enqueueSnackbar('Failed to update soil sample', { variant: 'error' });
    }
  };

  // lat 44.67265987859172
  // lon -98.38723082601702

  const type = form.watch('soil_sample_type');
  const {
    fieldOptions,
    zoneOptions,
    isFetching: isFetchingOptions,
  } = useSoilSampleZoneFieldOptions(user?.id ?? '', useYearFilter() ?? '');

  // manually set other data based on soil-sample-type
  const field = form.watch('field');
  useEffect(() => {
    if (field) {
      const fieldDetails = JSON.parse(field);
      form.setValue('geometry', JSON.stringify(fieldDetails.geometry));
      form.setValue('legal_description', fieldDetails.legal_description);
      form.setValue('legals_json', JSON.stringify(fieldDetails.legals_json));
      form.setValue('range', fieldDetails.range);
      form.setValue('section', fieldDetails.section);
      form.setValue('township', fieldDetails.township);
    }
  }, [field]);

  const zone = form.watch('zone');
  useEffect(() => {
    if (zone) {
      const zoneDetails = JSON.parse(zone);
      form.setValue('geometry', zoneDetails.geometry);
      form.setValue('legal_description', zoneDetails.legal_description);
      form.setValue('range', zoneDetails.range);
      form.setValue('section', zoneDetails.section);
      form.setValue('township', zoneDetails.township);
      form.setValue('other_zone', zoneDetails.other_zone);
      form.setValue('geometry', JSON.stringify(zoneDetails.geometry));
    }
  }, [zone]);

  const gridLat = useDebounce(form.watch('lat'));
  const gridLon = useDebounce(form.watch('lon'));
  const { data: blmData } = useBLMData(gridLat, gridLon);
  useEffect(() => {
    if (type === 'point' && blmData) {
      form.setValue('legal_description', blmData?.legal_description || '');
      form.setValue('range', blmData?.range || '');
      form.setValue('section', blmData?.section || '');
      form.setValue('township', blmData?.township || '');
    }
  }, [blmData, type]);

  return (
    <div>
      <p className="mb-2">Edit Soil Sample</p>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="rounded-lg bg-base-900 p-4"
        >
          <div className="grid grid-cols-6 gap-4">
            <ComboDatePicker
              name="Date"
              label="Date of Sample"
              defaultValue={new Date().toISOString().split('T')[0]}
            />
            <Select
              name="soil_sample_type"
              label="Soil Sample Type"
              options={[
                {
                  label: 'Zone',
                  value: 'zone',
                },
                {
                  label: 'Grid',
                  value: 'point',
                },
                {
                  label: 'Field',
                  value: 'field',
                },
              ]}
            />
            {type === 'zone' && (
              <AwaitSelect
                name="zone"
                label="Zone"
                options={[{ label: 'Select Zone', value: '' }, ...zoneOptions]}
                isLoading={isFetchingOptions}
              />
            )}
            {type === 'field' && (
              <AwaitSelect
                name="field"
                label="Field"
                options={[
                  { label: 'Select Field', value: '' },
                  ...fieldOptions,
                ]}
                isLoading={isFetchingOptions}
              />
            )}
            {type === 'point' && (
              <>
                <TextInput name="lat" label="Latitude" />
                <TextInput name="lon" label="Longitude" />
              </>
            )}

            <div className="col-span-6 grid grid-cols-6 gap-4">
              <TextInput name="other_zone" label="Zone Number" />
              <TextInput name="section" label="Section" />
              <TextInput name="township" label="Township" />
              <TextInput name="range" label="Range" />
              <TextInput name="legal_description" label="Legal Description" />
              <TextInput name="geometry" label="Geometry" disabled />
            </div>

            <div className="col-span-6 grid grid-cols-8 gap-4">
              <TextInput name="Zn_ppm" label="Zinc ppm" />
              <TextInput name="S1_lb" label="S1 pound" />
              <TextInput name="S2_lb" label="S2 pound" />
              <TextInput name="Salt1" label="Salt 1" />
              <TextInput name="Salt2" label="Salt 2" />
              <TextInput name="pH" label="pH Level" />
              <TextInput name="P_ppm" label="Phosphorus ppm" />
              <TextInput name="N1_lb" label="N1 pound" />
              <TextInput name="N2_lb" label="N2 pound" />
              <TextInput name="Na_ppm" label="Sodium pound" />
              <TextInput name="OM" label="Organic Matter" />
              <TextInput name="Cu_ppm" label="Copper ppm" />
              <TextInput name="K_ppm" label="Potassium ppm" />
              <TextInput name="Mg_ppm" label="Magnesium ppm" />
              <TextInput name="Ca_ppm" label="Calcium ppm" />
              <TextInput name="CEC_meq" label="CEC meq" />
              <TextInput name="BS_Na_pct" label="BS Na %" />
              <TextInput name="BS_Mg_pct" label="BS Mg %" />
              <TextInput name="BS_K_pct" label="BS K %" />
              <TextInput name="BS_Ca_pct" label="BS Ca %" />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            <Button
              type="submit"
              color="primary"
              size="sm"
              className="!w-fit"
              loading={isPending}
              disabled={isPending || !form.formState.isValid}
            >
              Save
            </Button>
            <Button color="primary" size="sm" className="!w-fit">
              Move to List
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
