import {
  DeckGL,
  FlyToInterpolator,
  GeoJsonLayer,
  IconLayer,
  TextLayer,
} from 'deck.gl';
import { useEffect, useMemo, useState } from 'react';
import Map from 'react-map-gl';
import { DEFAULT_CENTER, MAP_STYLE } from '../../../constants';
import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useBasis } from '../../../hooks/basis/use-basis';
import { useFieldMachines } from '../../../hooks/field/use-field-machines';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useFieldRevenue } from '../../../hooks/field/use-field-revenue';
import {
  getCenter,
  getCropColors,
  getDefaultSymbols,
  groupBy,
  parseUnit8ArrayFromCssColorHex,
} from '../../../utils';
import MapLegend from './MapLegend';
import { addJitter, createSVGIcon, getTooltip, svgToDataURL } from './helpers';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import { useUserRealTimeSettings } from '../../../hooks/user/use-user-real-time-settings';

// @TODO fix the colors/bg-opacity config of tailwindcss
function MapBlock() {
  const user = useUser();
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();

  const { data: userSettings } = useUserRealTimeSettings(
    user?.id || '',
    year || ''
  );

  const { data: polygons, isFetching } = useFieldPolygonsByYear(
    user?.id ?? '',
    year || ''
  );
  const { data: revenues } = useFieldRevenue(user?.id ?? '', year || '');
  const { data: machines } = useFieldMachines(user?.id ?? '');
  const { data: defaultAllBasis } = useBasis({
    userId: user?.id ?? '',
    year: year || '',
    symbols: userSettings.map((setting) => setting.contract),
  });
  const allBasisLocations = useMemo(
    () => groupBy(defaultAllBasis, 'crop'),
    [defaultAllBasis]
  );

  const [currentFilter, setCurrentFilter] = useState<string>('All');
  const [layers, setLayers] = useState<Array<any>>([]);

  const handleOnChange = (value: string) => {
    setCurrentFilter(value);
  };

  const cropNames = useMemo(
    () => Array.from(new Set(polygons?.map((field) => field.crop2))),
    [polygons]
  );

  const [viewState, setViewState] = useState({
    longitude: DEFAULT_CENTER.lon,
    latitude: DEFAULT_CENTER.lat,
    zoom: 12,
    pitch: 30,
    bearing: 0,
  });

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  useEffect(() => {
    if (!user) return;
    setViewState((prev) => ({
      ...prev,
      longitude: isNaN(Number(user.lon))
        ? DEFAULT_CENTER.lon
        : Number(user.lon),
      latitude: isNaN(Number(user.lat)) ? DEFAULT_CENTER.lat : Number(user.lat),
    }));
  }, [user]);

  // Get center of all features
  const features = useMemo(() => {
    return polygons
      ?.filter((p: any) => currentFilter === 'All' || p.crop2 === currentFilter)
      ?.map((field) => {
        const geoJSON = JSON.parse(field.geojson);
        return geoJSON;
      });
  }, [currentFilter, polygons]);
  useEffect(() => {
    const center = getCenter(features);
    if (center) {
      setViewState((prev) => ({
        ...prev,
        zoom: 12,
        longitude: center.lon,
        latitude: center.lat,
        transitionDuration: 250,
        transitionInterpolator: new FlyToInterpolator(),
      }));
    }
  }, [features, isFetching]);

  useEffect(() => {
    const layers: any = [];
    polygons
      ?.filter((p: any) => currentFilter === 'All' || p.crop2 === currentFilter)
      ?.forEach((polygon, index: Number) => {
        const geoJsonData = JSON.parse(polygon.geojson);
        const user_id_field_index = `${polygon.user_id}-${polygon.field_index}`;
        const items = revenues?.filter(
          (d) => d.user_id_field_index === user_id_field_index
        );
        const item = items?.[0] ?? [];
        const data = {
          ...geoJsonData.features[0],
          id: index,
          incomeView,
          crop: polygon.crop2,
          fieldName: polygon.field,
          field_index: polygon.field_index,
          value: incomeView === 'revenue' ? item.revenue : item.net_income,
        };

        geoJsonData.features[0] = data;

        const fillColor = parseUnit8ArrayFromCssColorHex(
          getCropColors(polygon.crop2, user?.network_partner)
        );

        layers.push(
          new GeoJsonLayer({
            id: `geojson-${user_id_field_index}`,
            data: geoJsonData,
            stroked: true,
            filled: true,
            wireframe: true,
            getElevation: 1,
            getFillColor: fillColor,
            getLineColor: [255, 255, 255, 255],
            getLineWidth: 2,
            lineWidthUnits: 'pixels',
            pickable: true,
            getTextSize: 12,
          })
        );

        const textData = [
          {
            crop: item.user_id_crop,
            field: item.field,
            position: [parseFloat(item?.lon) || 0, parseFloat(item?.lat) || 0],
            text: incomeView === 'revenue' ? item.revenue : item.net_income,
          },
        ];

        layers.push(
          new TextLayer({
            id: `text-layer-${user_id_field_index}`,
            data: textData,
            getText: (d) =>
              Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(
                d.text
              ),
            getPosition: (d) => d.position,
            getPixelOffset: [0, 0],
            getSize: 200,
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'bottom',
            getColor: [255, 255, 255, 255],
            pickable: false,
            fontFamily: 'RobotoCondensed',
            fontWeight: 'bold',
            sizeUnits: 'meters',
          })
        );
      });

    // basis layer
    const basisData: any[] = [];
    Object.keys(allBasisLocations).map((key) =>
      basisData.push(...allBasisLocations[key])
    );

    // apply jitter
    const jitteredData = basisData.map((item) => ({
      ...item,
      coordinates: [addJitter(item.lon, 0.01), addJitter(item.lat, 0.01)],
    }));

    const filteredIconData =
      currentFilter === 'All'
        ? jitteredData
        : jitteredData.filter((basis) => basis.commodity === currentFilter);

    layers.push(
      new IconLayer({
        id: 'IconLayer-basis',
        data: filteredIconData,
        getIcon: (d) => ({
          url: svgToDataURL(createSVGIcon(d)),
          width: 44,
          height: 44,
        }),
        getPosition: (d) => d.coordinates,
        getSize: 44,
        pickable: true,
      })
    );

    // machines
    layers.push(
      new IconLayer({
        id: 'IconLayer-machine',
        data: machines,
        getIcon: (d) => ({
          url: svgToDataURL(createSVGIcon(d)),
          width: 44,
          height: 44,
        }),
        getPosition: (d) => [parseFloat(d.lon), parseFloat(d.lat)],
        getSize: 44,
        pickable: true,
      })
    );

    setLayers(layers);
  }, [
    polygons,
    incomeView,
    currentFilter,
    revenues,
    machines,
    allBasisLocations,
    isFetching,
  ]);

  return (
    <div className="space-y-8">
      <div className="card relative overflow-hidden">
        <div className="text-light absolute text-center text-xl-bold bg-[#1b22278a] w-full py-4 px-6.5 rounded-tr-xl rounded-tl-xl z-10">
          {currentFilter} Fields Selected
        </div>
        {isFetching && (
          <span className="absolute w-full h-full bg-white/30 z-10 flex items-center justify-center">
            <SpinningIcon />
          </span>
        )}
        <div style={{ width: '100%', height: '650px' }}>
          <DeckGL
            layers={layers}
            viewState={viewState}
            controller={true}
            onViewStateChange={handleViewStateChange}
            getTooltip={getTooltip}
          >
            <Map
              reuseMaps
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              mapStyle={MAP_STYLE}
            />
          </DeckGL>
        </div>
      </div>

      {/* Map legend */}
      <MapLegend filters={cropNames} onChange={handleOnChange} />
    </div>
  );
}

export default MapBlock;
