import { Field, Label } from '@headlessui/react';
import { useFormContext } from 'react-hook-form';

interface NativeCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  name: string;
}
export default function NativeCheckbox(props: NativeCheckboxProps) {
  const { label, name, ...rest } = props;
  const formContext = useFormContext();

  return (
    <Field className="flex items-center space-x-4">
      <input
        className="rounded-md size-5"
        type="checkbox"
        {...formContext?.register(name, {})}
        {...rest}
      />
      <Label className="flex-1">{label}</Label>
    </Field>
  );
}
