export const calculateTotalUnits = ({
  ratePerAcre,
  numberOfAcres,
  conversionFactor,
}: {
  ratePerAcre: number;
  numberOfAcres: number;
  conversionFactor: number;
}) => {
  return ratePerAcre * conversionFactor * numberOfAcres;
};

export const calculateCostPerAcre = ({
  pricePerUnit,
  ratePerAcre,
  conversionFactor,
}: {
  pricePerUnit: number;
  ratePerAcre: number;
  conversionFactor: number;
}) => {
  return ratePerAcre * conversionFactor * pricePerUnit;
};

export const calculateTotalCost = ({
  costPerAcre,
  numberOfAcres,
}: {
  costPerAcre: number;
  numberOfAcres: number;
}) => {
  return costPerAcre * numberOfAcres;
};

export function calculateCostPerAcreByCost({
  totalCost,
  numberOfAcres,
}: {
  totalCost: number;
  numberOfAcres: number;
}) {
  return numberOfAcres === 0 ? 0 : totalCost / numberOfAcres;
}
