import { Outlet, createBrowserRouter } from 'react-router-dom';
import AdminLayout from '../components/layouts/admin/AdminLayout';
import DashboardLayout from '../components/layouts/dashboard/DashboardLayout';
import MainLayout from '../components/layouts/main/MainLayout';
import OnboardingRoute from '../components/routes/OnboardingRoute';
import ProtectedRoute from '../components/routes/ProtectedRoute';
import SuperRoute from '../components/routes/SuperRoute';
import UnauthRoute from '../components/routes/UnauthRoute';
import ErrorPage from '../pages/error';
import CompleteRegister from '../pages/main/complete-register';
import dataInputRoutes from './app/data-inputs';
import financialsRoutes from './app/financials';
import historicalInsightsRoutes from './app/historical-insights';
import mapRoutes from './app/map';
import marketsRoutes from './app/markets';
import prescriptionsRoutes from './app/prescriptions';
import riskManagementRoutes from './app/risk-management';
import weatherRoutes from './app/weather';
import dashboardRoutes from './dashboard';
import mainRoutes from './main';
import authRoutes from './main/auth';
import healthRoutes from './app/health';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
        children: [...mainRoutes],
      },

      // Dashboard use for admin act as a user
      {
        path: '/user/:userId/app',
        element: (
          <SuperRoute>
            <AdminLayout />
          </SuperRoute>
        ),
        errorElement: <ErrorPage />,
        children: [
          ...dashboardRoutes,
          ...mapRoutes,
          ...riskManagementRoutes,
          ...weatherRoutes,
          ...prescriptionsRoutes,
          ...historicalInsightsRoutes,
          ...marketsRoutes,
          ...dataInputRoutes,
          ...financialsRoutes,
          ...healthRoutes,
        ],
      },

      // Dashboard used for normal authenticated users
      {
        path: '/app',
        element: (
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
        children: [
          ...dashboardRoutes,
          ...mapRoutes,
          ...riskManagementRoutes,
          ...weatherRoutes,
          ...prescriptionsRoutes,
          ...historicalInsightsRoutes,
          ...marketsRoutes,
          ...dataInputRoutes,
          ...financialsRoutes,
          ...healthRoutes,
        ],
      },

      // Unauthorized/unauthentication routes
      {
        element: (
          <UnauthRoute>
            <div className="text-base-100">
              <Outlet />
            </div>
          </UnauthRoute>
        ),
        children: [...authRoutes],
      },

      // Public
      {
        element: (
          <div className="text-base-100 p-4">
            <Outlet />
          </div>
        ),
      },

      // Special routes
      {
        element: (
          <OnboardingRoute>
            <div className="text-base-100 p-4">
              <Outlet />
            </div>
          </OnboardingRoute>
        ),
        children: [
          {
            path: 'complete-register',
            element: <CompleteRegister />,
          },
        ],
      },
    ],
  },
]);

export default router;
