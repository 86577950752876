import { useEffect, useState } from 'react';
import { PARQUET_BUCKET } from '../../constants';
import { getLatestKeys } from '../../utils';

export const useFieldsS3Keys = ({
  userId,
  year,
}: {
  userId: string;
  year: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [s3Keys, setS3Keys] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!userId || !year) return;
      const prefix = `${userId}_${year}_revenue`;
      const s3Keys = await getLatestKeys(PARQUET_BUCKET, prefix);
      setS3Keys(s3Keys);
      setLoading(false);
    };

    fetchData();
  }, [userId, year]);

  return {
    loading,
    s3Keys,
  };
};
