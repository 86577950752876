import { Field, Label } from '@headlessui/react';
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { get } from 'lodash';
import {
  DateRangePicker as AriaDateRangePicker,
  Button,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DateSegment,
  DateValue,
  DateRange,
  Dialog,
  Group,
  Heading,
  Popover,
  RangeCalendar,
  Text,
} from 'react-aria-components';
import { useFormContext } from 'react-hook-form';

interface DateRangePickerProps {
  name: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (value: any) => void;
  value?: string;
  required?: boolean;
  defaultValue?: string;
}

export default function DateRangePicker({
  className,
  label,
  required,
  name,
  onChange: onChangeProp,
}: DateRangePickerProps) {
  const formContext = useFormContext();
  const error = get(formContext?.formState.errors, name);

  if (!formContext) {
    // In case you dont get the values by handleSubmit of react-hook-form
    // you forgot to wrap the form with <FormProvider> in the parent component
  }

  const { onChange } =
    formContext?.register(name, {
      required: required ? `${label} is required` : false,
    }) || {};

  const handleOnChange = (value: DateRange) => {
    onChangeProp?.(value);
    onChange?.({ target: { value: value, name } });
  };

  return (
    <Field className={clsx('space-y-1 w-full', className)}>
      {label && (
        <Label>
          {label} {required && <span className="text-secondary">*</span>}
        </Label>
      )}
      <AriaDateRangePicker
        onChange={handleOnChange}
        className={
          'w-full px-4 py-2 bg-base-1000 rounded border border-base-1000 inputStyle h-fit'
        }
        style={{
          boxShadow:
            '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
        }}
      >
        <Group className="flex justify-between w-full">
          <DateInput slot="start" className="flex">
            {(segment) => <DateSegment segment={segment} />}
          </DateInput>
          <span>-</span>
          <DateInput slot="end" className="flex">
            {(segment) => <DateSegment segment={segment} />}
          </DateInput>
          <Button>
            <CalendarDaysIcon className="size-5" />
          </Button>
        </Group>
        <Popover>
          <Dialog className="dialogStyle bg-block-fill p-4 rounded border border-base-1000 shadow-lg">
            <RangeCalendar>
              <header className="flex justify-between px-3 mb-4">
                <Button slot="previous">
                  <ChevronLeftIcon className="size-6" />
                </Button>
                <Heading className="text-secondary text-lg-bold" />
                <Button slot="next">
                  <ChevronRightIcon className="size-6" />
                </Button>
              </header>
              <CalendarGrid>
                <CalendarGridHeader>
                  {(day) => <CalendarHeaderCell />}
                </CalendarGridHeader>
                <CalendarGridBody>
                  {(date) => (
                    <CalendarCell
                      date={date}
                      className={clsx(
                        'size-10 rounded-full flex items-center justify-center',
                        'hover:bg-base-900 hover:text-base-000',
                        'data-[selected]:bg-primary data-[selected]:text-base-000',
                        'data-[outside-month]:text-base-800 data-[outside-month]:hover:bg-transparent'
                      )}
                    />
                  )}
                </CalendarGridBody>
              </CalendarGrid>
              <Text slot="errorMessage" />
            </RangeCalendar>
          </Dialog>
        </Popover>
      </AriaDateRangePicker>
      {error && <p className="text-red">{`${error?.message}`}</p>}
    </Field>
  );
}
