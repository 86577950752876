import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserAttributes, signIn } from '../../auth';
import {
  useSetIsAuthenticated,
  useSetCognitoUser,
} from '../../contexts/auth-context';
import { getNextLoginFlowRoute } from '../../utils';
import { enqueueSnackbar } from 'notistack';

export const useSignin = () => {
  const setIsAuthenticated = useSetIsAuthenticated();
  const setCognitoUser = useSetCognitoUser();
  const navigate = useNavigate();
  const [isPending, setPending] = useState(false);

  const handleSignin = async (email: string, password: string) => {
    try {
      setPending(true);
      const res = await signIn(email, password);

      if (res?.isSignedIn) {
        const userAttrs = await fetchUserAttributes();
        const role = userAttrs?.['custom:role'];
        const network_partner = userAttrs?.['custom:network_partner'];
        setCognitoUser?.({
          role,
          network_partner,
        });
        setIsAuthenticated?.(true);
        navigate('/', { replace: true });
      }

      if (res?.nextStep.signInStep) {
        const nextRoute = getNextLoginFlowRoute(res?.nextStep?.signInStep);
        navigate(nextRoute, { replace: true });
      }
    } catch (error: any) {
      console.error('Failed to sign in', error);
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setPending(false);
    }
  };

  return { signIn: handleSignin, isPending };
};
