import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import { useCreateNote, useGetNotes } from '../../../../hooks/note/use-note';
import { FieldPrescriptionRes } from '../../../../types';
import { convertToFloat } from '../../../../utils';
import Checkbox from '../../../commons/Checkbox';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

interface YieldGoalListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionRes[];
  onSelect: (zone: string) => void;
  loading?: boolean;
  setIsEditing: (value: boolean) => void;
  crop: string;
}

export default function YieldGoalList({
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
  crop,
}: YieldGoalListProps) {
  const user = useUser();
  const year = useYearFilter();
  const mainUser = useMainUser();

  const { mutate: createNote } = useCreateNote();

  const { data: notes, isFetching: isFetchingNotes } = useGetNotes({
    userId: user?.id || '',
    year: year || '',
    type: 'yield_goal',
    crop: crop,
  });

  const enhancedData = useMemo(() => {
    return data.map((item) => {
      return {
        ...item,
        yield_goal: convertToFloat(
          item?.user_adjusted_yield || item.zone_yield
        ),
        note: notes?.find((note) => note.agrivar_zone === item.agrivar_zone)
          ?.note,
      };
    });
  }, [data, notes]);

  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Select Zone',
          key: 'zone',
          type: 'element',
          element: (item: FieldPrescriptionRes) => (
            <Checkbox
              name="zone"
              label={`Zone ${item.agrivar_zone1}`}
              checked={item.agrivar_zone === selectedZone.agrivar_zone}
              onChange={(checked: boolean) => {
                checked && onSelect(item.agrivar_zone);
              }}
              rounded
            />
          ),
        },
        { name: 'Acres', key: 'area', unit: 'acres' },
        {
          name: 'Yield Goal',
          key: 'yield_goal',
          type: 'number',
          subTotal: (data) => {
            const allAcres = data?.reduce(
              (acc, curr) => acc + Number(curr['area'] ?? 0),
              0
            );
            const avg = data?.reduce(
              (acc, curr) =>
                acc +
                (Number(curr['yield_goal'] ?? 0) * Number(curr['area'] ?? 0)) /
                  allAcres,
              0
            );
            return avg;
          },
        },
        {
          name: 'Probability of Exceeding Yield Goal',
          key: 'yield_probability',
          type: 'number',
          numberStyle: 'percent',
        },
        {
          name: 'Average Yield',
          key: 'average_yield',
          type: 'number',
        },
        {
          name: 'Yield Risk',
          key: 'sigma',
          type: 'number',
        },
      ],
    };
  }, [onSelect, selectedZone.agrivar_zone]);

  const rowActions: RowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionRes) => {},
    note: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: 'yield_goal',
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: [item.agrivar_zone],
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note', { variant: 'error' });
            },
          }
        );
      },
      onSaveAll: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: 'yield_goal',
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: data.map((zone) => zone.agrivar_zone),
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note', { variant: 'error' });
            },
          }
        );
      },
      title: 'Add Zone Note',
    },
  };

  return (
    <TableLayout title={'Zone Yield Goals'}>
      <Table
        configs={tableConfigs}
        data={enhancedData}
        loading={loading || isFetchingNotes}
        rowActions={rowActions}
        subTotalLabel="Average"
      />
    </TableLayout>
  );
}
