import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { enqueueSnackbar } from 'notistack';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  applicationTimeOptions,
  ConversionFactors,
  prescriptionLocationOptions,
} from '../../../../constants';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useGetChemicalProducts } from '../../../../hooks/chemical/use-chemical';
import {
  useCreatePrescriptionProduct,
  useUpdatePrescriptionProduct,
} from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { AddChemicalPayload } from '../../../../types/prescriptions';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select from '../../../commons/Select';
import ComboSelectInput from '../../../commons/inputs/ComboSelectInput';
import TextInput from '../../../commons/inputs/TextInput';
import {
  calculateCostPerAcre,
  calculateCostPerAcreByCost,
  calculateTotalCost,
  calculateTotalUnits,
} from './helpers';

type ChemicalFormProps = {
  title?: string | ReactNode;
  crop: string;
  selectedZone: FieldPrescriptionWithProducts;
  edit?: boolean;
  setIsEditing?: (value: boolean) => void;
  selectedProduct?: PrescriptionProductRes;
  zones: FieldPrescriptionWithProducts[];
  onFlip?: () => void;
};

export default function ChemicalForm({
  selectedZone,
  crop,
  zones,
  title,
  setIsEditing,
  selectedProduct,
  edit,
  onFlip,
}: ChemicalFormProps) {
  const form = useForm({
    defaultValues: edit
      ? {
          ...selectedProduct,
        }
      : {},
  });
  const user = useUser();
  const year = useYearFilter();

  const { data: products, isFetching: isFetchingChemicalProducts } =
    useGetChemicalProducts(user?.network_partner ?? '');
  const productOptions = useMemo(() => {
    return products?.map((product) => ({
      label: product.itemdescription,
      value: product.itemdescription,
    }));
  }, [products]);

  const { mutateAsync: create, isPending: isCreating } =
    useCreatePrescriptionProduct();

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdatePrescriptionProduct();

  const handleCreate = async ({
    total_cost,
    total_units,
    cost_per_acre,
    applyAllZones,
    rate_per_acre,
    ...values
  }: any) => {
    if (!year || !user) return;
    const appliedZones = form.watch('applyAllZones')
      ? zones.map((zone) => {
          return {
            zone: zone.agrivar_zone,
            acres: zone.area,
            total_cost: '0',
            total_units: '0',
            cost_per_acre: cost_per_acre.toString(),
            rate_per_acre: rate_per_acre.toString(),
          };
        })
      : [
          {
            zone: selectedZone.agrivar_zone,
            acres: selectedZone.area,
            total_cost: total_cost.toString(),
            total_units: total_units.toString(),
            cost_per_acre: cost_per_acre.toString(),
            rate_per_acre: rate_per_acre.toString(),
          },
        ];
    const payload: AddChemicalPayload = {
      ...values,
      zone: appliedZones,
      crop: crop,
      year: year,
      userId: user.id,
      input_type: 'chemicals',
      field_index: selectedZone.field_index,
      field: selectedZone.field,
    };

    try {
      await create(payload);
      setIsEditing?.(false);
      enqueueSnackbar('Chemical added successfully', { variant: 'success' });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Failed to add chemical', { variant: 'error' });
      console.error(error);
    }
  };

  const handleUpdate = async (values: any) => {
    if (!selectedProduct) return;
    try {
      await update({
        ...selectedProduct,
        ...Object.fromEntries(
          Object.entries(values).map(([key, value]) => [key, String(value)])
        ),
        input_type: 'chemicals',
        area: selectedZone.area,
        user_id: `${user?.id}_${year}`,
        user_id_agrivar_zone: `${user?.id}_${year}-${selectedZone.agrivar_zone}`,
        crop: crop,
        field: selectedZone.field,
        field_index: selectedZone.field_index,
      });
      setIsEditing?.(false);
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      });
    }
  };

  const product = form.watch('product');
  const discountPercentage = form.watch('discount_percentage');
  useEffect(() => {
    if (product) {
      const productData = products?.find(
        (item) => item.itemdescription === product
      );
      form.setValue('brand', productData?.brand);
      form.setValue('price_per_unit', productData?.price);
      form.setValue('unit_type', productData?.reportinguom);
    }
  }, [products, product]);

  const area = Number(selectedZone.area);
  const ratePerAcre = form.watch('rate_per_acre');
  const conversionFactor = form.watch('conversion_factor')?.split('.')[0];
  const pricePerUnit = form.watch('price_per_unit') ?? 0;

  const [originalTotalCost, setOriginalTotalCost] = useState<
    number | undefined
  >();
  const [originalCostPerAcre, setOriginalCostPerAcre] = useState<
    number | undefined
  >();
  // set total units
  useEffect(() => {
    const totalUnits = calculateTotalUnits({
      ratePerAcre: Number(ratePerAcre),
      conversionFactor: Number(conversionFactor),
      numberOfAcres: area,
    });
    form.setValue('total_units', totalUnits.toString());
  }, [ratePerAcre, area, conversionFactor]);

  useEffect(() => {
    const costPerAcre = calculateCostPerAcre({
      ratePerAcre,
      conversionFactor,
      pricePerUnit: Number(pricePerUnit) * (1 - discountPercentage / 100),
    });
    const totalCost = calculateTotalCost({
      costPerAcre,
      numberOfAcres: area,
    });

    form.setValue('cost_per_acre', costPerAcre.toString());
    form.setValue('total_cost', totalCost.toString());
  }, [
    conversionFactor,
    pricePerUnit,
    ratePerAcre,
    selectedZone.area,
    discountPercentage,
    area,
  ]);

  // if user changes discount percentage after munaually changing total cost
  // then recalculate total cost
  useEffect(() => {
    if (originalCostPerAcre && originalTotalCost) {
      // set discounted values for input fields
      form.setValue(
        'cost_per_acre',
        (originalCostPerAcre * (1 - discountPercentage / 100)).toString()
      );
      form.setValue(
        'total_cost',
        (originalTotalCost * (1 - discountPercentage / 100)).toString()
      );
    }
  }, [discountPercentage, originalCostPerAcre, originalTotalCost]);

  return (
    <div className="space-y-8">
      <CardHeader
        title={title ?? 'Chemicals (lbs. Per Acre)'}
        tooltip={'tooltip'}
      />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(edit ? handleUpdate : handleCreate)}
          className="space-y-4"
        >
          <ComboSelectInput
            label="Select Product"
            name="product"
            options={productOptions}
            required
          />
          <TextInput name="brand" label="Brand Name" required />
          <TextInput
            name="price_per_unit"
            label="Price Per Unit"
            type="number"
            required
          />
          <Select
            name="location"
            label="Select Location"
            options={user?.network_partner === 'New Vision' ? prescriptionLocationOptions : []}
            required={user?.network_partner === 'New Vision'}
          />
          <TextInput
            name="rate_per_acre"
            label="Rate Per Acre"
            type="number"
            required
          />
          <Select
            name="conversion_factor"
            label="Conversion Factor"
            options={ConversionFactors.map((item) => ({
              label: item.applieddescription,
              value: `${item['conversion factor']}.${item.applieddescription}`,
            }))}
          />
          <TextInput
            name="discount_percentage"
            label="Discount Percentage"
            type="number"
            defaultValue={0}
          />
          <TextInput
            name="total_units"
            label="Total Units"
            type="number"
            required
          />
          <TextInput
            name="cost_per_acre"
            label="Cost Per Acre"
            type="number"
            required
            onChange={(e) => {
              const value = Number(e.target.value);
              const totalCost = calculateTotalCost({
                costPerAcre: value,
                numberOfAcres: area,
              });
              form.setValue('total_cost', totalCost.toString());
              setOriginalCostPerAcre(value / (1 - discountPercentage / 100));
              setOriginalTotalCost(totalCost / (1 - discountPercentage / 100));
            }}
          />
          <TextInput
            name="total_cost"
            label="Total Cost"
            type="number"
            required
            onChange={(e) => {
              const value = Number(e.target.value);
              const costPerAcre = calculateCostPerAcreByCost({
                totalCost: value,
                numberOfAcres: area,
              });
              form.setValue('cost_per_acre', costPerAcre.toString());
              setOriginalTotalCost(value / (1 - discountPercentage / 100));
              setOriginalCostPerAcre(
                costPerAcre / (1 - discountPercentage / 100)
              );
            }}
          />
          <Select
            name="application_time"
            label="Select Application Period"
            options={applicationTimeOptions}
            required
          />
          {!edit && (
            <div className="flex space-x-4">
              <Button disabled={isUpdating} onClick={() => onFlip?.()}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                startIcon={<PlusCircleIcon className="size-6" />}
                shadow
                disabled={isCreating || !form.formState.isValid}
              >
                Add To Cheminal
              </Button>
            </div>
          )}

          {edit && (
            <div className="flex space-x-4">
              <Button
                disabled={isUpdating}
                onClick={() => setIsEditing?.(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isUpdating || !form.formState.isValid}
                loading={isUpdating}
              >
                Update
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
