import { Coordinate } from 'mapbox-gl';
import NewVisionLogo from '../src/assets/icons/new-vision-logo-wide.svg';
import AgriVarLogo from '../src/assets/icons/logo.png';
import HeartlandLogo from '../src/assets/icons/heartland-logo.svg';
import CFELogo from '../src/assets/icons/cfe-logo.png';

export const AWS_FILES_BUCKET = 'agrivar.userinputs.files';
export const REACT_APP_AWS_BUCKET = 'agrivar.userinputs';

export enum BaseSymbol {
  Corn = 'ZCZ',
  Soybeans = 'ZSX',
  Wheat = 'KEZ',
}

export const SupportedCrops = ['Corn', 'Soybeans', 'Wheat'];

export const SupportedCropsOptions = SupportedCrops.map((crop) => ({
  label: crop,
  value: crop,
}));

// for set user default settings
export const defaultsSettings = {
  tradingDays: 1,
  vaRSettings: [10, 50],
};

export const CropChartBackgroundColors: {
  [key: string]: {
    startColor: string;
    stopColor: string;
  };
} = {
  Corn: {
    startColor: 'rgba(255, 183, 27, 0.6)',
    stopColor: 'rgba(255, 183, 27, 0)',
  },
  Soybeans: {
    startColor: 'rgba(174, 154, 125, 0.6)',
    stopColor: 'rgba(174, 154, 125, 0)',
  },
  Wheat: {
    startColor: 'rgba(98, 162, 235, 0.6)',
    stopColor: 'rgba(98, 162, 235, 0)',
  },
  Default: {
    startColor: 'rgba(255, 183, 27, 0.42)',
    stopColor: 'rgba(255, 183, 27, 0)',
  },
};

// chart configs
export const ForecastChartConfig = {
  width: 750,
  height: 290,
  backgroundColor: {
    startColor: 'rgba(255, 183, 27, 0.42)',
    stopColor: 'rgba(255, 183, 27, 0)',
  },
  borderColor: '#FFB71B',
};

export const DefaultForecastChartColors = {
  backgroundColor: CropChartBackgroundColors.Default,
  borderColor: '#ffb71b',
};

export const USPhoneRegex =
  /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;

export const USStateRegex =
  /^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/g;

export const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const SignUpSections = {
  Details: 'details',
  Prompts: 'prompts',
  UserTermsConditions: 'userTermsConditions',
  CMETermsConditions: 'cmeTermsConditions',
  SMSTermsConditions: 'smsTermsConditions',
  DataSharingPermissions: 'dataSharingPermissions',
};

export const CodeToCrop = {
  ZC: 'Corn',
  ZS: 'Soybeans',
  KE: 'HRW Wheat',
  ZW: 'SRW Wheat',
};

export const CodeToMonth = {
  F: 'Jan',
  G: 'Feb',
  H: 'Mar',
  J: 'Apr',
  K: 'May',
  M: 'Jun',
  N: 'Jul',
  Q: 'Aug',
  U: 'Sep',
  V: 'Oct',
  X: 'Nov',
  Z: 'Dec',
} as { [key: string]: string };

export const CodeToYear = {
  2: '22',
  3: '23',
  4: '24',
  5: '25',
  6: '26',
  7: '27',
};

// Month selector options
export const monthOptions = [
  { value: 'january', label: 'January' },
  { value: 'february', label: 'February' },
  { value: 'march', label: 'March' },
  { value: 'april', label: 'April' },
  { value: 'may', label: 'May' },
  { value: 'june', label: 'June' },
  { value: 'july', label: 'July' },
  { value: 'august', label: 'August' },
  { value: 'september', label: 'September' },
  { value: 'october', label: 'October' },
  { value: 'november', label: 'November' },
  { value: 'december', label: 'December' },
];

export const AttributeOptions = [
  { key: 'average_seeding_rate', name: 'Avg. Seeding Rate' },
  // { key: 'seeding_rate', name: 'Seeding Rate' },
  { key: 'predicted_yield', name: 'Predicted Yield' },
  { key: 'average_yield', name: 'Avg. Yield' },
  { key: 'CEC_meq', name: 'CEC (meq)' },
  { key: 'slope', name: 'Slope' },
  { key: 'sand', name: 'Sand' },
  { key: 'silt', name: 'Silt' },
  { key: 'clay', name: 'Clay' },
  { key: 'pH', name: 'PH' },
  { key: 'OM', name: 'OM' },
  { key: 'sigma', name: 'Risk' },
  { key: 'N1_lb', name: 'N (lbs)' },
  { key: 'P_ppm', name: 'P (ppm)' },
  { key: 'K_ppm', name: 'K (ppm)' },
  { key: 'Na_ppm', name: 'Na (ppm)' },
  { key: 'Mg_ppm', name: 'Mg (ppm)' },
  { key: 'Fe', name: 'Fe' },
  { key: 'B', name: 'B' },
  { key: 'Mn', name: 'Mn' },
  { key: 'BpH', name: 'BpH' },
  { key: 'Zn_ppm', name: 'Zn (ppm)' },
  { key: 'Ca_ppm', name: 'Ca (ppm)' },
];

export const MAP_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11';

export const prescriptionsBucket = 'agrivar.prescriptions';

export const mapPageBucket = 'agrivar.latest.yields3';
export const historicalPageBucket = 'agrivar.historical.insights';

export const UnitType = ['BAG', 'UNIT'];

export const UnitTypeOptions = UnitType.map((unit) => ({
  label: unit,
  value: unit,
}));

export const ThemeColors = {
  primary: '#80bc00',
  secondary: '#ffb71b',
  tertiary: '#ae9a7d',
  base: {
    0: '#f9fafb',
    100: '#f4f6f7',
    200: '#e4e8ec',
    300: '#d5dce2',
    400: '#ccd5dc',
    500: '#a3b4c2',
    600: '#93a5b4',
    700: '#8498a9',
    800: '#5c7284',
    900: '#455663',
    1000: '#323e48',
    1100: '#171d21',
  },
  red: '#f42727',
  blue: '#62a2eb',
  blockFill: '#1b2227',
  lightGray: '#F5F5F5',
};

export const PolygonStyles = [
  // default themes provided by MB Draw
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': [
        'case',
        ['has', 'user_crop_color'],
        ['get', 'user_crop_color'],
        '#3bb2d0',
      ],
      'fill-outline-color': [
        'case',
        ['has', 'user_crop_color'],
        ['get', 'user_crop_color'],
        '#3bb2d0',
      ],
      'fill-opacity': 0.3,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#3bb2d0',
      'fill-outline-color': [
        'case',
        ['has', 'user_crop_color'],
        ['get', 'user_crop_color'],
        '#3bb2d0',
      ],
      'fill-opacity': 0.7,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': [
        'case',
        ['has', 'user_crop_color'],
        ['get', 'user_crop_color'],
        '#3bb2d0',
      ],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#3bb2d0',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#3bb2d0',
      'fill-outline-color': '#3bb2d0',
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#3bb2d0',
    },
  },
  {
    id: 'gl-draw-polygon-symbol-inactive',
    type: 'symbol',
  },
  // end default themes provided by MB Draw
];

export const CropOptions = [
  { label: 'Corn for Grain', value: 'Corn for Grain' },
  { label: 'Corn for Silage', value: 'Corn for Silage' },
  { label: 'Soybeans GMO', value: 'Soybeans GMO' },
  { label: 'Soybeans Non-GMO', value: 'Soybeans Non-GMO' },
  { label: 'Spring Wheat', value: 'Spring Wheat' },
  { label: 'Winter Wheat', value: 'Winter Wheat' },
  { label: 'Sunflowers', value: 'Sunflowers' },
  { label: 'Sorghum', value: 'Sorghum' },
  { label: 'Other', value: 'Other' },
];

export const CropColors: Record<string, string> = {
  Corn: '#40ff00',
  Soybeans: '#e5ff00',
  'Hard Red Winter Wheat': '#cc00ff',
  Wheat: '#e49b0f',
  Sunflowers: '#f8f690',
  Sorghum: '#c88b4e',
  Other: '#0a0000',
};

export const CropToCode: { [key: string]: string } = {
  Corn: 'ZC',
  Soybeans: 'ZS',
  'HRW Wheat': 'KE',
  Wheat: 'KE',
  'SRW Wheat': 'ZW',
};

export const MonthToCode: { [key: string]: string } = {
  january: 'F',
  february: 'G',
  march: 'H',
  april: 'J',
  may: 'K',
  june: 'M',
  july: 'N',
  august: 'Q',
  september: 'U',
  october: 'V',
  november: 'X',
  december: 'Z',
};

export const yearOptions = [
  // { value: '2022', label: '2022' },
  // { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
];

export const contractTypes = [
  'futures',
  'options',
  'spreads',
  'basis',
  'fundemental',
];

export const cornWheatMonthOptions = [
  { value: 'march', label: 'March' },
  { value: 'may', label: 'May' },
  { value: 'july', label: 'July' },
  { value: 'september', label: 'September' },
  { value: 'december', label: 'December' },
];

export const soybeanMonthOptions = [
  { value: 'january', label: 'January' },
  { value: 'march', label: 'March' },
  { value: 'may', label: 'May' },
  { value: 'july', label: 'July' },
  { value: 'august', label: 'August' },
  { value: 'september', label: 'September' },
  { value: 'november', label: 'November' },
];

export const cropToMonthOptions = {
  Corn: cornWheatMonthOptions,
  Soybeans: soybeanMonthOptions,
  'HRW Wheat': cornWheatMonthOptions,
  'SRW Wheat': cornWheatMonthOptions,
};

export const DefaultGetPositionParams = {
  geometryType: 'esriGeometryPoint',
  inSR: 4326,
  outSR: 4326,
  spatialRel: 'esriSpatialRelIntersects',
  distance: 1,
  units: 'esriSRUnit_Meter',
  outFields: 'TWNSHPNO,TWNSHPDIR,RANGENO,RANGEDIR,FRSTDIVNO',
  returnGeometry: 'false',
  returnTrueCurves: 'false',
  returnIdsOnly: 'false',
  returnCountOnly: 'false',
  returnZ: 'false',
  returnM: 'false',
  returnDistinctValues: 'false',
  returnExtentOnly: 'false',
  featureEncoding: 'esriDefault',
  f: 'pjson',
};

export const DefaultGeoJSONValue = {
  type: 'FeatureCollection',
  features: [
    {
      id: '0',
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [] as Coordinate[],
      },
    },
  ],
};

export const tabTables = {
  0: ['HLCO', 'Bid_Ask'],
  1: ['HLCO', 'Bid_Ask', 'Hedges_PROD'],
  2: ['HLCO', 'Bid_Ask'],
  3: ['Basis', 'Basis2'],
  4: [],
};

export const productTypes = ['futures', 'options', 'spreads', 'basis'];

export const PARQUET_BUCKET = 'agrivar.latest.yields3';
export const HISTORICAL_BUCKET = 'agrivar.historical.insights2';
export const WEATHER_BUCKET = 'agrivar.weather';
export const RADAR_POSTFIX = 'BaseReflectivity';
export const RADAR_FORECAST_POSTFIX = 'ForecastReflectivity';
export const HAIL_POSTFIX = 'HailIndex';
export const PRECIP_RATE_POSTFIX = 'PrecipRate';
export const PRECIP_24HR_POSTFIX = 'Precip24Hr';

export const weatherTypesToBuckets = {
  radar: RADAR_POSTFIX,
  radarForecast: RADAR_FORECAST_POSTFIX,
  hail: HAIL_POSTFIX,
  precipitationRate: PRECIP_RATE_POSTFIX,
  '24hrPrecipitation': PRECIP_24HR_POSTFIX,
};

export const relationshipToSchemaField = {
  pH: 'pH',
};

export const colorsSelectionToSchemaField = () => {
  return {
    x: {
      r: 'x_r',
      g: 'x_g',
      b: 'x_b',
    },
    yield: {
      r: 'yield_r',
      g: 'yield_g',
      b: 'yield_b',
    },
    yieldPerformance: {
      r: 'yield_performance_r',
      g: 'yield_performance_g',
      b: 'yield_performance_b',
    },
  };
};

export const mapParamsToTitles = {
  net_income: 'Net Income',
  revenue: 'Revenue',
  yield: 'Yield',
  sigma: 'Risk',
  yield_performance: 'Yield Performance',
  yield_probability: 'Yield Probability',
  yield_error: 'Yield Error',
  ndvi_s2: 'Satellite',
  ndvi_pct: 'Satellite Deviation',
  ndvi_deviation: 'Satellite Deviation',
  hiResolutionSatellite: 'Revenue',
};

export const TextractType = {
  EXPENSE: 'expense',
  INSURANCE: 'insurance',
};

export const DEFAULT_CENTER = {
  lon: -98.48155,
  lat: 44.640713,
};

export const SoilSampleFormKeys = [
  'Date',
  'legal_description',
  'geometry',
  'lat',
  'lon',
  'other_zone',
  'range',
  'section',
  'soil_sample_type',
  'township',
  'BS_Ca_pct',
  'BS_K_pct',
  'BS_Mg_pct',
  'BS_Na_pct',
  'Ca_ppm',
  'CEC_meq',
  'Cu_ppm',
  'K_ppm',
  'Mg_ppm',
  'N1_lb',
  'N2_lb',
  'Na_ppm',
  'OM',
  'pH',
  'P_ppm',
  'S1_lb',
  'S2_lb',
  'Salt1',
  'Salt2',
  'Zn_ppm',
];

export const NETWORK_PARTNER = {
  Agrivar: 'Agrivar',
  'new-vision': 'New Vision',
  heartland: 'Heartland',
  cfe: 'CFE',
};

export const NETWORK_PARTNER_LOGO = {
  Agrivar: AgriVarLogo,
  'New Vision': NewVisionLogo,
  Heartland: HeartlandLogo,
  CFE: CFELogo,
};

export const mapTooltipStyles = {
  backgroundColor: '#000000bb',
  borderRadius: '10px',
  color: '#ffffff99',
};

export const tabToUnit = {
  revenue: '$',
  net_income: '$',
  yield: 'bu/ac',
  yield_performance: 'bu/ac',
  yield_probability: '%',
  yield_error: 'bu/ac',
  sigma: 'bu/ac',
  ndvi_s2: 'NDVI',
  ndvi_pct: '%',
  ndvi_deviation: '%',
  hiResolutionSatellite: '$',
};

export const cropColors: { [key: string]: string } = {
  Soybeans: 'rgba(174, 154, 125, 1)',
  Corn: 'rgba(255, 183, 27, 1)',
  Other: 'rgba(244, 39, 39, 1)',
  Wheat: 'rgba(98,162,235,1)',
};

export const gradientCropColors: { [key: string]: string } = {
  Soybeans: 'rgba(224, 199, 162, 1)',
  Corn: 'rgba(255, 203, 89, 1)',
  Other: 'rgba(255, 102, 102, 1)',
  Wheat: 'rgba(147, 195, 250, 1)',
};

export const applicationTimeOptions = [
  {
    label: 'Pre-Plant',
    value: 'Pre-Plant',
  },
  {
    label: 'Plant',
    value: 'Plant',
  },
  {
    label: 'Post Plant',
    value: 'Post Plant',
  },
  {
    label: 'Chemigation',
    value: 'Chemigation',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const prescriptionLocationOptions = [
  {
    label: 'Brewster Main Office',
    value: 'Brewster Main Office 38438 210th St Brewster MN 56119',
  },
  {
    label: 'Brewster Agronomy Plant',
    value: 'Brewster Agronomy Plant 20565 Wass Ave Brewster MN 56119',
  },
  {
    label: 'Brewster Grain Elevator',
    value: 'Brewster Grain Elevator 931 1st Ave Brewster MN 56119',
  },
  {
    label: 'Worthington',
    value: 'Worthington 1301 County Road 5 Worthington MN 56187',
  },
  {
    label: 'Miloma',
    value: 'Miloma 35419 MN 60 Heron Lake MN 56137',
  },
  {
    label: 'Dundee',
    value: 'Dundee 200 N Main St Dundee MN 56131',
  },
  {
    label: 'Heron Lake',
    value: 'Heron Lake 101 9th St NW Heron Lake MN 56137',
  },
  {
    label: 'Reading',
    value: 'Reading 19948 McCall Ave Reading MN 56165',
  },
  {
    label: 'Wilmont',
    value: 'Wilmont 102 N. 4th St Wilmont MN 56185',
  },
  {
    label: 'Windom',
    value: 'Windom 125 16th St Windom MN 56101',
  },
  {
    label: 'Adrian',
    value: 'Adrian 100 East 1st St Adrian MN 56110',
  },
  {
    label: 'Jeffers',
    value: 'Jeffers 412 E Peavey St Jeffers MN 56145',
  },
  {
    label: 'Ellsworth',
    value: 'Ellsworth 106 S Broadway St Ellsworth MN 56129',
  },
  {
    label: 'Magnolia',
    value: 'Magnolia 1856 County Hwy 4 Magnolia MN 56158',
  },
  {
    label: 'Mountain Lake',
    value: 'Mountain Lake 37308 County Rd 8 Mountain Lake MN 56159',
  },
  {
    label: 'Beaver Creek',
    value: 'Beaver Creek 201 E 1st Ave Beaver Creek MN 56116',
  },
  {
    label: 'Hills',
    value: 'Hills 521 41st St Hills MN 56138',
  },
  {
    label: 'Mankato',
    value: 'Mankato 3325 3rd Avenue Mankato Minnesota 56001',
  },
]

export const stateOptions = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const weatherTabs = {
  radar: 'Radar',
  radarForecast: 'Radar Forecast',
  hail: 'Hail',
  precipitationRate: 'Precipitation Rate',
  '24hrPrecipitation': 'Total Precipitation per 24 Hours',
};

export const basisContractMonths = {
  Corn: [
    { label: 'March', value: 'march' },
    { label: 'May', value: 'may' },
    { label: 'July', value: 'july' },
    { label: 'December', value: 'december' },
  ],
  Soybeans: [
    { label: 'January', value: 'january' },
    { label: 'March', value: 'march' },
    { label: 'May', value: 'may' },
    { label: 'July', value: 'july' },
    { label: 'November', value: 'november' },
  ],
  'HRW Wheat': [
    { label: 'March', value: 'march' },
    { label: 'May', value: 'may' },
    { label: 'July', value: 'july' },
    { label: 'September', value: 'september' },
    { label: 'December', value: 'december' },
  ],
  'SRW Wheat': [
    { label: 'March', value: 'march' },
    { label: 'May', value: 'may' },
    { label: 'July', value: 'july' },
    { label: 'September', value: 'september' },
    { label: 'December', value: 'december' },
  ],
};

export const intervalTitles = {
  '': '',
  open: 'On Market Open',
  close: 'On Market Close',
  '1': 'Every Hour',
  '2': 'Every 2 Hours',
  '4': 'Every 4 Hours',
};

export const futuresContracts = {
  Corn: {
    '2024': [
      {
        label: "Dec '24",
        value: 'ZCZ4',
        startDate: '2024-10-24',
        endDate: '2024-12-10',
      },
      {
        label: "Mar '25",
        value: 'ZCH5',
        startDate: '2024-12-11',
        endDate: '2025-03-10',
      },
      {
        label: "Jul '25",
        value: 'ZCN5',
        startDate: '2025-03-11',
        endDate: '2025-07-10',
      },
      {
        label: "Sep '25",
        value: 'ZCU5',
        startDate: '2025-07-11',
        endDate: '2025-09-10',
      },
      {
        label: "Dec '25",
        value: 'ZCZ5',
        startDate: '2025-09-11',
        endDate: '2025-12-10',
      },
    ],
    '2025': [
      {
        label: "Dec '25",
        value: 'ZCZ5',
        startDate: '2024-10-24',
        endDate: '2025-12-10',
      },
      {
        label: "Mar '26",
        value: 'ZCH6',
        startDate: '2025-12-11',
        endDate: '2026-03-10',
      },
      {
        label: "Jul '26",
        value: 'ZCN6',
        startDate: '2026-03-11',
        endDate: '2026-07-10',
      },
      {
        label: "Sep '26",
        value: 'ZCU6',
        startDate: '2026-07-11',
        endDate: '2026-09-10',
      },
      {
        label: "Dec '26",
        value: 'ZCZ6',
        startDate: '2026-09-11',
        endDate: '2026-12-10',
      },
    ],
  },
  Soybeans: {
    '2024': [
      {
        label: "Nov '24",
        value: 'ZSZ4',
        startDate: '2024-10-24',
        endDate: '2024-11-10',
      },
      {
        label: "Jan '25",
        value: 'ZSF5',
        startDate: '2024-11-11',
        endDate: '2025-01-10',
      },
      {
        label: "Mar '25",
        value: 'ZSH5',
        startDate: '2025-01-11',
        endDate: '2025-03-10',
      },
      {
        label: "May '25",
        value: 'ZSK5',
        startDate: '2025-03-11',
        endDate: '2025-05-10',
      },
      {
        label: "Jul '25",
        value: 'ZSN5',
        startDate: '2025-05-11',
        endDate: '2025-07-10',
      },
      {
        label: "Aug '25",
        value: 'ZSQ5',
        startDate: '2025-07-11',
        endDate: '2025-08-10',
      },
      {
        label: "Sep '25",
        value: 'ZSU5',
        startDate: '2025-08-11',
        endDate: '2025-09-10',
      },
      {
        label: "Nov '25",
        value: 'ZSX5',
        startDate: '2025-09-11',
        endDate: '2025-11-10',
      },
    ],
    '2025': [
      {
        label: "Nov '25",
        value: 'ZSZ5',
        startDate: '2024-10-24',
        endDate: '2025-11-10',
      },
      {
        label: "Jan '26",
        value: 'ZSF6',
        startDate: '2025-11-11',
        endDate: '2026-01-10',
      },
      {
        label: "Mar '26",
        value: 'ZSH6',
        startDate: '2026-01-11',
        endDate: '2026-03-10',
      },
      {
        label: "Jul '26",
        value: 'ZSN6',
        startDate: '2026-03-11',
        endDate: '2026-07-10',
      },
      {
        label: "Aug '26",
        value: 'ZSQ6',
        startDate: '2026-07-11',
        endDate: '2026-08-10',
      },
      {
        label: "Sep '26",
        value: 'ZSU6',
        startDate: '2026-08-11',
        endDate: '2026-09-10',
      },
      {
        label: "Nov '26",
        value: 'ZSZ6',
        startDate: '2026-09-11',
        endDate: '2026-11-10',
      },
    ],
  },
  Wheat: {
    '2024': [
      {
        label: "Dec '24",
        value: 'KEZ4',
        startDate: '2024-10-24',
        endDate: '2024-12-10',
      },
      {
        label: "Mar '25",
        value: 'KEH5',
        startDate: '2024-12-11',
        endDate: '2025-03-10',
      },
      {
        label: "May '25",
        value: 'KEK5',
        startDate: '2025-03-11',
        endDate: '2025-05-10',
      },
      {
        label: "Jul '25",
        value: 'KEN5',
        startDate: '2025-05-11',
        endDate: '2025-07-10',
      },
      {
        label: "Sep '25",
        value: 'KEU5',
        startDate: '2025-07-11',
        endDate: '2025-09-10',
      },
      {
        label: "Dec '25",
        value: 'KEZ5',
        startDate: '2025-09-11',
        endDate: '2025-12-10',
      },
    ],
    '2025': [
      {
        label: "Jul '25",
        value: 'KEN5',
        startDate: '2024-10-24',
        endDate: '2025-07-10',
      },
      {
        label: "Sep '25",
        value: 'KEU5',
        startDate: '2025-07-11',
        endDate: '2025-09-10',
      },
      {
        label: "Dec '25",
        value: 'KEZ5',
        startDate: '2025-09-11',
        endDate: '2025-12-10',
      },
      {
        label: "Mar '26",
        value: 'KEH6',
        startDate: '2025-12-11',
        endDate: '2026-03-10',
      },
      {
        label: "Jul '26",
        value: 'KEN6',
        startDate: '2026-03-11',
        endDate: '2026-07-10',
      },
      {
        label: "Sep '26",
        value: 'KEU6',
        startDate: '2026-07-11',
        endDate: '2026-09-10',
      },
      {
        label: "Dec '26",
        value: 'KEZ6',
        startDate: '2026-09-11',
        endDate: '2026-12-10',
      },
    ],
  },
};

export const monthList = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const historicalInsightsTableDataInfo = {
  variety:
    'Shows the yield and yield performance of the different seed varieties planted',
  flow: 'Flow represents water flow. High flow values represent areas with higher amounts of drainage',
  seeding_rate:
    'Shows the yield and yield performance of different ranges of seeding rates',
  OM: 'Shows the yield and yield performance across different levels of organic matter in the soil',
  pH: 'Shows the yield and yield performance across different PH levels in the soil',
  sand: 'Shows the yield and yield performance across different levels of sand in the soil',
  silt: 'Shows the yield and yield performance across different levels of silt in the soil',
  CEC_meq:
    'Shows the yield and yield performance across different levels of cation exchange capacity (CEC) in the soil',
  elev: 'Shows the yield and yield performance across different elevation levels',
  slope: 'Shows the yield and yield performance across different slope factors',
  clay: 'Shows the yield and yield performance of different levels of clay in the soil',
  field_name: 'Shows the yield and yield performance of each field',
  agrivar_zone:
    'Shows the yield and yield performance across each AgriVaR zone',
  seed_yield: 'Seed yield is the number of seeds produced from a seed planted',
};

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const ConversionFactors = [
  {
    priceuom: 'LBS',
    applieduom: '50BG',
    'conversion factor': '50',
    pricedescription: 'Pounds',
    applieddescription: '50# Bag',
  },
  {
    priceuom: 'ACRE',
    applieduom: 'ACRE',
    'conversion factor': '1',
    pricedescription: 'Acre',
    applieddescription: 'ACRE',
  },
  {
    priceuom: 'UNIT',
    applieduom: 'BAG',
    'conversion factor': '1',
    pricedescription: 'Unit',
    applieddescription: 'BAG',
  },
  {
    priceuom: 'LBS',
    applieduom: 'BU32',
    'conversion factor': '32',
    pricedescription: 'Pounds',
    applieddescription: 'Bushel 32',
  },
  {
    priceuom: 'LBS',
    applieduom: 'BU56',
    'conversion factor': '56',
    pricedescription: 'Pounds',
    applieddescription: 'Bushel 56',
  },
  {
    priceuom: 'LBS',
    applieduom: 'BU60',
    'conversion factor': '60',
    pricedescription: 'Pounds',
    applieddescription: 'Bushel 60',
  },
  {
    priceuom: 'UNIT',
    applieduom: 'BX40',
    'conversion factor': '40',
    pricedescription: 'Unit',
    applieddescription: '40 UNIT BX',
  },
  {
    priceuom: 'UNIT',
    applieduom: 'BX45',
    'conversion factor': '45',
    pricedescription: 'Unit',
    applieddescription: '45 UNIT BX',
  },
  {
    priceuom: 'UNIT',
    applieduom: 'BX50',
    'conversion factor': '50',
    pricedescription: 'Unit',
    applieddescription: '50 UNIT BX',
  },
  {
    priceuom: 'LBS',
    applieduom: 'DROZ',
    'conversion factor': '0.0625',
    pricedescription: 'Pounds',
    applieddescription: 'Dry Ounces',
  },
  {
    priceuom: 'EACH',
    applieduom: 'EACH',
    'conversion factor': '1',
    pricedescription: 'Each',
    applieddescription: 'Each',
  },
  {
    priceuom: 'GAL',
    applieduom: 'GAL',
    'conversion factor': '1',
    pricedescription: 'Gallons',
    applieddescription: 'Gallons',
  },
  {
    priceuom: 'LBS',
    applieduom: 'LBS',
    'conversion factor': '1',
    pricedescription: 'Pounds',
    applieddescription: 'Pounds',
  },
  {
    priceuom: 'GAL',
    applieduom: 'LOZ',
    'conversion factor': '0.0078125',
    pricedescription: 'Gallons',
    applieddescription: 'Liquid Oz',
  },
  {
    priceuom: 'GAL',
    applieduom: 'PINT',
    'conversion factor': '0.125',
    pricedescription: 'Gallons',
    applieddescription: 'Pints',
  },
  {
    priceuom: 'GAL',
    applieduom: 'QRT',
    'conversion factor': '0.25',
    pricedescription: 'Gallons',
    applieddescription: 'Quarts',
  },
  {
    priceuom: 'GAL',
    applieduom: 'TON1',
    'conversion factor': '180.186',
    pricedescription: 'Gallons',
    applieddescription: 'ATS TONS',
  },
  {
    priceuom: 'GAL',
    applieduom: 'TON2',
    'conversion factor': '180.342651',
    pricedescription: 'Gallons',
    applieddescription: 'GAL6-24-6',
  },
  {
    priceuom: 'GAL',
    applieduom: 'TON3',
    'conversion factor': '188.67925',
    pricedescription: 'Gallons',
    applieddescription: 'GALXPLOSIO',
  },
  {
    priceuom: 'GAL',
    applieduom: 'TON4',
    'conversion factor': '170.9401709',
    pricedescription: 'Gallons',
    applieddescription: 'GAL3-18-18',
  },
  {
    priceuom: 'LBS',
    applieduom: 'TONS',
    'conversion factor': '2000',
    pricedescription: 'Pounds',
    applieddescription: 'Tons',
  },
  {
    priceuom: 'UNIT',
    applieduom: 'UNIT',
    'conversion factor': '1',
    pricedescription: 'Unit',
    applieddescription: 'UNIT',
  },
];
