import { useEffect, useState } from 'react';
import { filterWxData, getWxDataCenter } from '../../../utils';
import { useMapContext } from '../../../contexts/map-context';

export const useCenter = (wData: any) => {
  const [center, setCenter] = useState<{
    lat: number;
    lon: number;
  }>();
  const {
    selectedField: field,
    selectedZone: zone,
    selectedArea,
    selectedChart,
  } = useMapContext();

  useEffect(() => {
    if (wData) {
      const dataChunks = wData
        .map((data: any) => {
          const filter = { key: selectedArea, role: 1 };
          let wxData = filterWxData(data, filter);
          wxData = filterWxData(wxData, '', field);
          wxData = filterWxData(wxData, '', '', zone);
          return wxData;
        })
        .filter((data: any) => Object.keys(data)?.length);
      if (dataChunks?.length) {
        const result = getWxDataCenter(dataChunks);
        if (result) {
          setCenter(result);
        }
        return;
      }
    }
    if (wData?.length) {
      const result = getWxDataCenter(wData);
      if (result) {
        setCenter(result);
      }
    }
  }, [wData, field, zone, selectedArea, selectedChart]);

  return {
    center,
    setCenter,
  };
};
