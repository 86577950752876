import apiClient from './api-client';

export const getInvoicesApi = ({
  userId,
  partner,
}: {
  userId: string;
  partner: string;
}) => {
  return apiClient.get(`/invoices?user_id=${userId}&partner=${partner}`);
};


export const deleteInvoiceApi = (uniqueId: string, index: string) => {
  return apiClient.delete(`/invoices?uniqueid=${uniqueId}&index=${index}`);
}

export const updateInvoiceDetailsApi = (payload: any) => {
  return apiClient.post(`/invoices`, {...payload, func: 'update_invoice_details'});
}