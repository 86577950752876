import clsx from 'clsx';
import {
  CSSProperties,
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { theme } from 'twin.macro';
import NumberDisplay from '../NumberDisplay';
import { convertToFloat } from '../../../utils';
import { useUser } from '../../../contexts/auth-context';
import { NETWORK_PARTNER } from '../../../constants';

const MAX = 70000;
const MIN = 0;

interface SliderInputProps extends InputHTMLAttributes<HTMLInputElement> {
  marks?: {
    name: string;
    value: number;
    color: string;
  }[];
  progressBg?: string;
  thumbBg?: string;
  max?: number;
  min?: number;
  trackStyle?: CSSProperties;
  color?: 'secondary' | 'dark';
  defaultValue?: number;
  value?: number;
}

const getProgressBg = (network?: string) => {
  switch (network) {
    case NETWORK_PARTNER.heartland:
      return `linear-gradient(90deg, #FA2525 0%, #8B0202 100%)`;
    default:
      return `linear-gradient(150.02deg, #FFB71B 32.51%, #FF9900 52.03%)`;
  }
};

export default function SliderInput(props: SliderInputProps) {
  const user = useUser();
  const {
    marks = [],
    progressBg = getProgressBg(user?.network_partner),
    max = MAX,
    min = MIN,
    step = 1,
    trackStyle,
    className,
    color = 'secondary',
    onChange,
    value: valueProps,
    ...rest
  } = props;
  const [value, setValue] = useState(props.defaultValue || 0);

  useEffect(() => {
    if (valueProps !== undefined) {
      setValue(valueProps);
    }
  }, [valueProps]);

  const getMarkX = (value: number) => {
    const percentage = ((value - min) / (max - min)) * 100;
    if (percentage <= 0) return `0px`;
    if (percentage >= 100) return `calc(100% - 2px)`;
    return `${percentage}%`;
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(convertToFloat(e.target.value));
    if (props.onChange) props.onChange(e);
  };

  const getProgressWidth = useCallback(
    (value: number) => {
      return ((value - min) / (max - min)) * 100;
    },
    [min, max]
  );

  const progressWidth = useMemo(
    () => getProgressWidth(value),
    [value, getProgressWidth]
  );

  return (
    <div className="relative w-full px-1 h-fit">
      <input
        className={clsx(
          'absolute top-[50%] -translate-y-[50%] left-0 z-[10] w-full appearance-none bg-transparent focus:outline-none',
          'agrivar-slider',
          color
        )}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleOnChange}
        {...rest}
      />
      {/* Track */}
      <div
        className={clsx(
          'relative h-2 w-full bg-base-400 rounded-full overflow-hidden shadow-md shadow-black/25'
        )}
        style={trackStyle}
      >
        {/* Progress */}
        <div
          className={clsx(
            'absolute top-0 left-0 h-full bg-secondary rounded-full min-w-2'
          )}
          style={{
            width: `${progressWidth}%`,
            background: progressBg,
          }}
        ></div>

        {/* Marks */}
        {marks.map((mark) => (
          <span
            key={mark.name}
            className={clsx('absolute top-0 h-full w-0.5', mark.color)}
            style={{ left: getMarkX(mark.value) }}
          ></span>
        ))}
      </div>
    </div>
  );
}

export function Legend({
  label,
  value,
  color,
  className,
}: {
  label: string;
  value?: number;
  color: string;
  className?: string;
}) {
  return (
    <span className="flex items-center space-x-2 text-xs-regular">
      <span
        className={clsx('size-2 rounded-full flex-shrink-0', color)}
        style={{ backgroundColor: color }}
      ></span>
      <span className={clsx(className)}>
        {label}
        {value !== undefined && (
          <span>
            : <NumberDisplay value={value} />
          </span>
        )}
      </span>
    </span>
  );
}
