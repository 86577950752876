import { ReactNode } from 'react';

interface DisclosureContentLayoutProps {
  left: string | ReactNode;
  right: string | ReactNode;
}

export default function DisclosureContentLayout(
  props: DisclosureContentLayoutProps
) {
  const { left, right } = props;

  return (
    <div className="grid grid-cols-12 gap-4 p-4">
      <div className="col-span-12 xl:col-span-3 card !rounded-lg px-4 py-5">
        {left}
      </div>
      <div className="col-span-12 xl:col-span-9 card !rounded-lg !overflow-hidden h-fit">
        {right}
      </div>
    </div>
  );
}
