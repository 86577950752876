import { signOut } from 'aws-amplify/auth';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import AgrivarLogo from '../../../../assets/icons/powered-by-agrivar-logo.svg';
import {
  useSetControlledUser,
  useSetIsAuthenticated,
  useMainUser,
} from '../../../../contexts/auth-context';
import { NavItemType } from '../../../../types';
import Button from '../../../commons/Button';

const adminItems: NavItemType[] = [
  {
    href: '/',
    title: 'Main menu',
  },
];

export default function AdminHeader() {
  const setIsAuthenticated = useSetIsAuthenticated();
  const setControlledUser = useSetControlledUser();

  const user = useMainUser();
  const isAdmin = user?.is_admin;
  const isAdvisor = user?.is_advisor_client;
  const isAllowed = isAdmin || isAdvisor;

  const handleSignOut = async () => {
    setIsAuthenticated?.(false);
    setControlledUser?.(undefined);
    await signOut();
  };

  return (
    <header
      className={clsx(
        'flex flex-col space-y-8 p-4 w-full absolute top-0 left-0 z-50',
        'shadow-lg'
      )}
    >
      <div className={clsx('flex items-center justify-between')}>
        <Link to="/">
          <img src={AgrivarLogo} alt="agriva" />
        </Link>
        <div className="flex items-center space-x-4">
          <nav className="flex space-x-4">
            {isAllowed &&
              adminItems.map((item) => (
                <NavLink
                  key={item.title}
                  to={item.href}
                  className="whitespace-nowrap"
                >
                  <span className="text-md-bold text-base-900 hover:text-primary">
                    {item.title}
                  </span>
                </NavLink>
              ))}
          </nav>

          <Button color="primary" onClick={handleSignOut}>
            Sign Out
          </Button>
        </div>
      </div>
    </header>
  );
}
