import Weather from '../../components/weather';

const weatherRoutes = [
  {
    path: 'weather',
    element: <Weather />,
  },
];

export default weatherRoutes;
