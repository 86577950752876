import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { format, parseISO } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DateCalendar from '../../../components/commons/calendar/DateCalendar';
import SliderInput from '../../../components/commons/inputs/SliderInput';
import { useUser } from '../../../contexts/auth-context';
import { formatDate } from '../../../utils';
import { useMapContext } from '../../../contexts/map-context';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUserCropYears } from '../../../hooks/map/use-map';
import { useParams } from 'react-router-dom';

type MonthLabel = { month: string; index: number };

export default function TimelineSlider({
  value,
  cachedDate,
  onChange,
  min,
  max,
  dateSet,
}: {
  value: number;
  cachedDate: string;
  onChange: (value: string) => void;
  min: number;
  max: number;
  dateSet: string[];
}) {
  const user = useUser();
  const {
    selectedDate: date,
    setSelectedDate: setDate,
    selectedArea,
    setChangingDate,
  } = useMapContext();

  const monthSteps = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  // Parse the dates into Date objects
  const parsedDates = dateSet.map((date) => parseISO(date));

  // Create a map of month labels and their corresponding positions
  const monthLabels: MonthLabel[] = monthSteps
    .map((month) => {
      // Find the first date that matches the month
      const firstDateInMonth = parsedDates.find(
        (date) => format(date, 'MMM').toUpperCase() === month
      );
      if (firstDateInMonth) {
        const index = parsedDates?.indexOf(firstDateInMonth);
        return { month, index };
      }
      return null;
    })
    .filter((label) => label !== null) as MonthLabel[];

  // Function to evenly distribute labels
  const getDistributedLabels = (labels: MonthLabel[], totalLabels: number) => {
    if (labels.length <= totalLabels) return labels;

    const step = Math.ceil(labels.length / totalLabels);
    return labels.filter((_, index) => index % step === 0);
  };

  // Get the distributed labels
  const distributedLabels = getDistributedLabels(monthLabels, 10);

  // Ensure value is within bounds
  const [safeValue, setSafeValue] = useState(
    value ? value : dateSet.length - 1
  );

  useEffect(() => {
    if (cachedDate) {
      const index = dateSet.indexOf(cachedDate);
      if (index !== -1) {
        setSafeValue(index);
        setDate(cachedDate);
      }
      return;
    }
    setSafeValue(dateSet.length - 1);
    setDate(dateSet[dateSet.length - 1]);
  }, [dateSet]);
  const filterYear = useYearFilter();
  const { type: typeParam = '' } = useParams();

  // const years = useUserCropYears('45_2024', typeParam);
  // console.log('type param', typeParam);

  const defaultYear = 2024;
  const [year, setYear] = React.useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full">
      <div className=" flex px-4 gap-4 text-center mb-8 border-2 border-[orange] max-w-max ml-auto mr-auto items-center p-2 rounded-xl">
        <Popover className="z-[100] flex">
          <PopoverButton
            className="items-center flex "
            onClick={() => setIsOpen(!isOpen)}
          >
            <CalendarIcon className="w-6 h-6 cursor-pointer" fill={'#A3B4C2'} />
            {dateSet.length > 0 && <p>{formatDate(dateSet[safeValue])}</p>}
          </PopoverButton>
          <PopoverPanel className="absolute mt-[30px]">
            <DateCalendar
              availableYears={[2023, 2024]}
              defaultYear={defaultYear}
              onChange={(date) => {
                const isoString = date.toISOString(); // "2024-06-09T05:00:00.000Z"
                const formattedDate = isoString.split('T')[0]; // "2024-06-09
                // find the index of the date in the dateSet
                const index = dateSet?.indexOf(formattedDate);
                if (index === -1) {
                  enqueueSnackbar('Selected Date is not part of dataset', {
                    variant: 'error',
                    autoHideDuration: 10000,
                  });
                  return;
                }
                setSafeValue(index);
                onChange(index.toString());
                setChangingDate(true);
                setTimeout(() => {
                  setChangingDate(false);
                }, 3500);
              }}
            />
          </PopoverPanel>
        </Popover>
      </div>
      <div className="relative flex justify-between mb-1.5">
        {dateSet &&
          dateSet.length > 0 &&
          distributedLabels &&
          distributedLabels.length > 0 &&
          distributedLabels.map(({ month, index }) => (
            <span
              key={month}
              className="text-xs-regular text-base-000 uppercase flex flex-col items-center group-data-[theme=Heartland]/root:text-base-900"
              style={{
                marginLeft: 6,
                top: '-25px',
                position: 'absolute',
                left: `${(index / dateSet.length) * 100}%`,
                transform: 'translateX(-50%)',
              }}
            >
              <p className="mb-1">{month}</p>
              <span className="w-[1px] h-1 bg-base-000 rounded-full"></span>
            </span>
          ))}
      </div>
      <SliderInput
        value={safeValue}
        onChange={(e) => {
          setSafeValue(parseInt(e.target.value));
          setDate(dateSet[parseInt(e.target.value)]);
          onChange(e.target.value);
          setChangingDate(true);
          setTimeout(() => {
            setChangingDate(false);
          }, 3500);
        }}
        min={min}
        max={max}
      />
    </div>
  );
}
