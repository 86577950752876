import type { MapboxOverlayProps } from '@deck.gl/mapbox';
import { MapboxOverlay } from '@deck.gl/mapbox';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, useControl, Popup, LngLat, MapMouseEvent, MapRef } from 'react-map-gl';
import { CropColors, MAP_STYLE, PolygonStyles } from '../../../constants';
import { useControlledUser } from '../../../contexts/auth-context';
import {
  useCreateStudy,
  useHistoricalInsights,
  useStudyData,
  useUpdateStudy,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useViewBounds } from '../map/useViewBounds';
import { useLayers } from './useLayers';
import { getTooltip } from './helpers';
import DeckGL from '@deck.gl/react';
import { useWData } from './useWData';
import { useS3Keys } from './useS3Keys';
import {
  EditableGeoJsonLayer,
  DrawPolygonMode,
  FeatureCollection,
  DrawSquareMode,
  DrawRectangleMode,
  GeoJsonEditMode,
  ModifyMode,
  DrawCircleFromCenterMode,
  ViewMode,
  ElevationMode,
  DrawLineStringMode,
  DrawPolygonByDraggingMode,
  TransformMode,
  Feature,
} from '@deck.gl-community/editable-layers';
import * as turf from '@turf/turf';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useCenter } from './useCenter';
import Legend from './Legend';
import { useAllFieldPolygon } from '../../../hooks/field-polygon/use-field-polygon';
import { enhanceFieldItem } from '../../../components/data-inputs/field-info-boundary/helpers';
import { getCropColors } from '../../../utils';

function DeckGLOverlay(props: MapboxOverlayProps) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export default function MapSection() {
  const user = useControlledUser();

  const {
    viewState,
    setViewState,
    year,
    crop,
    relationship,
    map,
    newCenter,
    selectedFeatureIndexes,
    setSelectedFeatureIndexes,
    setSelectionGridIndicies,
    selectedStudyIndex,
    selectedDrawMode,
    deck,
    creatingStudy,
    studyDetails,
    setStudyDetails,
    createStudy,
    setCreateStudy,
    setCreatingStudy,
    trigger,
    setTrigger,
    rgb,
    myFeatureCollection,
    setMyFeatureCollection,
    saveEditingStudy,
    setSaveEditingStudy,
    editMode,
    setEditMode,
    setModalMode,
    xbin,
    tableDataContext,
    centerTrigger,
  } = useHistoricalInsights();


  // add an event listener for the delet key to delete selected feature indexes from
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        let newFeatures = myFeatureCollection.features.filter(
          (feature, index) => {
            return !selectedFeatureIndexes.includes(index);
          }
        );
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
        setSelectedFeatureIndexes([]);
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        // remove the last feature
        let newFeatures = myFeatureCollection.features.slice(0, -1);
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFeatureIndexes, myFeatureCollection.features]);

  const yearFilter = useYearFilter();

  const { s3Keys, loading: s3KeysLoading } = useS3Keys({
    userId: user?.id || '',
    crop,
    year,
    yearFilter: yearFilter ?? '',
    relationship,
  });

  const { wData, loading: wDataLoading } = useWData({
    userId: user?.id || '',
    crop,
    year,
    yearFilter: yearFilter ?? '',
    s3Keys,
    relationship,
  });


  const { mutate: updateStudyMutation } = useUpdateStudy();
  const { mutate: createStudyMutation } = useCreateStudy();

  const { center, setCenter } = useCenter(wData);
  const { viewBounds } = useViewBounds(wData);



  const { layers, setLayers, hoverInfo } = useLayers({
    wData,
    relationship,
    featureCollection: myFeatureCollection,
    xbin,
  });


  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  useEffect(() => {
    if (center) {
      const newCenter: any = {
        longitude: center.lon,
        latitude: center.lat,
      };
      if (selectedStudyIndex !== -1) {
        newCenter['zoom'] = 12;
      } 
      setViewState((prev: any) => ({
        ...prev,
        ...newCenter
      }));
    }
  }, [center, selectedStudyIndex, centerTrigger]);

  useEffect(() => {
    if (newCenter.length > 1) {
      setCenter({ lon: newCenter[0], lat: newCenter[1] });
    }
  }, [newCenter]);

  const mapRef = useRef<MapRef>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  const { data: allFields, isFetching } = useAllFieldPolygon(
    user?.id ?? '',
    year || '2024'
  );

  const fpList = useMemo(() => {
    return allFields?.map((field) => enhanceFieldItem(field));
  }, [allFields, isFetching]);

  const allFeatures = useMemo(() => {
    return fpList?.map((field) => {
      const geoJSON = JSON.parse(field.geojson);
      return {
        ...geoJSON,
        features: geoJSON.features.map((feat: any, index: number) => {
          return {
            ...feat,
            id: field.user_id_field_index,
            properties: {
              ...feat.properties,
              field,
              crop_color: getCropColors(field.crop2, user?.network_partner),
            },
          };
        }),
      };
    });
  }, [fpList]);

  // Render features
  useEffect(() => {
    if (mapRef.current && allFeatures?.length > 0) {
      if (drawRef.current) {
        mapRef.current?.removeControl(drawRef.current);
      }
      drawRef.current = new MapboxDraw({
        defaultMode: 'simple_select',
        displayControlsDefault: false,
        styles: PolygonStyles,
        userProperties: true,
      });
      // check
      mapRef.current?.addControl(drawRef.current);

      const validFeatures = allFeatures.filter(
        (feat: any) => !!feat?.features?.[0]?.geometry?.coordinates?.length
      );
      validFeatures.forEach((feat) => drawRef.current?.add(feat));
    }
  }, [allFeatures]);

  // Create the draw control once and store it in a ref
  const drawControl = useRef<MapboxDraw | null>(null);

  const drawModes = [
    ViewMode,
    DrawPolygonMode,
    DrawSquareMode,
    DrawRectangleMode,
    DrawCircleFromCenterMode,
    DrawPolygonByDraggingMode,
    ModifyMode,
    TransformMode,
  ];
  const layer = useMemo(() => {
    return new EditableGeoJsonLayer({
      id: 'geojson-layer',
      mode: drawModes[selectedDrawMode],
      selectedFeatureIndexes: selectedFeatureIndexes,
      data: myFeatureCollection,
      modeConfig: { enableSpapping: true },
      onEdit: ({ updatedData, editType }: any) => {
        console.log('onEdit', updatedData, editType);
        setMyFeatureCollection(updatedData);
        if (editType === 'updateTentativeFeature') {
          setModalMode('drawingShape');
        } else if (editType === 'addFeature') {
          setModalMode('drawingCompleted');
        }
      },
      onClick: (info) => {
        console.log('onClick', info);
        if (
          selectedDrawMode !== 0 &&
          selectedDrawMode !== 6 &&
          selectedDrawMode !== 7
        ) {
          return;
        }
        if (selectedFeatureIndexes.includes(info.index)) {
          setSelectedFeatureIndexes(
            selectedFeatureIndexes.filter((index) => index !== info.index)
          );
          return;
        }
        setSelectedFeatureIndexes([...selectedFeatureIndexes, info.index]);
      },
    });
  }, [selectedDrawMode, selectedFeatureIndexes, myFeatureCollection]);



  const calculateTotalArea = () => {
    try {
      let totalLons = 0;
      for (let i = 0; i < layers.length; i++) {
        const lons = layers[i].props.data.lons;
        totalLons += lons.length;
      }
      return totalLons * 0.00247105;
    } catch (e) {
      console.log('Error calculating total area:', e);
      return 0;
    }
  };


  const calculateAverages = () => {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    let avgLat = 0;
    let avgLon = 0;
    let num = 0;
    let x = 0;
    let yieldVar = 0;
    let yieldPerformance = 0;

    wData?.map((data, index) => {
      data.lon.forEach((lon: number, lonIndex: number) => {
        const lat = data.lat[lonIndex];
        // ensure point is in a feature
        if (
          !myFeatureCollection.features.some((feature: any) =>
            turf.booleanPointInPolygon(
              [lon, lat],
              turf.polygon(feature.geometry.coordinates)
            )
          )
        ) {
          return;
        }
        let bin = data['x_value'][lonIndex]; // data[relationship][lonIndex];
        if (typeof bin !== 'object') {
          // if bin is not a number -> return
          if (typeof bin === 'bigint') {
            bin = [Number(bin)];
          } else {
            bin = [bin];
          }
        }
        console.log('bin', bin, x, typeof bin, typeof x);
        x += bin.reduce((a: number, b: number) => a + b, 0) / bin.length;
        min = Math.min(min, x);
        max = Math.max(max, x);
        avgLat += data.lat[lonIndex];
        avgLon += lon;
        num += 1;
        yieldVar += parseFloat(data.yield[lonIndex]);
        yieldPerformance += parseFloat(data.yield_performance[lonIndex]);
      });
    });
    if (min === Number.MAX_SAFE_INTEGER) {
      min = 0;
    }
    return {
      xbin: `${min.toFixed(2)} - ${max.toFixed(2)}`,
      avgLat: (avgLat / num).toFixed(2),
      avgLon: (avgLon / num).toFixed(2),
      x: (x / num).toFixed(2),
      yieldVar: (yieldVar / num).toFixed(2),
      yield_performance: (yieldPerformance / num).toFixed(2),
    };
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    async function createStudyFunc() {
      if (createStudy) {
        if (!studyDetails.name) {
          alert('Please enter a study name');
          setCreateStudy(false);
          return;
        }
        // loop through studyDetails keys and if any valules are "NaN" set them to 0
        for (const key in studyDetails) {
          if (studyDetails[key] === 'NaN') {
            studyDetails[key] = 0;
          }
        }
        createStudyMutation(
          { ...studyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study added');
              queryClient.invalidateQueries([
                'historical-insights/createStudy',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error creating study:', error);
              alert(`Failed to create study. Please try again. ${error}`);
            },
          }
        );
        setCreateStudy(false);
      }
    }
    createStudyFunc();
  }, [createStudy]);

  console.log('my feature collection', myFeatureCollection);

  // write a use effect for saveEditingStudy
  useEffect(() => {
    async function saveEditingStudyFunc() {
      if (saveEditingStudy != null && editMode === 'save') {
        const area = calculateTotalArea();
        const avgs = calculateAverages();
        const polygons = myFeatureCollection.features.map((feature: any) => {
          return feature?.geometry.coordinates;
        });

        const saveStudyDetails = {
          ...saveEditingStudy,
          area,
          ...avgs,
          polygons,
          userId: user?.id || '',
        };

        updateStudyMutation(
          { ...saveStudyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study saved');
              queryClient.invalidateQueries([
                'historical-insights/study-data',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error saving study:', error);
              alert(`Failed to save study. Please try again. ${error}`);
            },
          }
        );
        setSaveEditingStudy(null);
        setEditMode('');
      }
    }
    saveEditingStudyFunc();
  }, [saveEditingStudy, editMode]);

  useEffect(() => {
    if (creatingStudy) {
      const area = calculateTotalArea();
      const avgs = calculateAverages();
      const polygons = myFeatureCollection.features.map((feature: any) => {
        return feature?.geometry.coordinates;
      });

      setStudyDetails({
        ...studyDetails,
        area,
        ...avgs,
        userId: user?.id || '',
        crop,
        year,
        relationship,
        polygons,
      });
      // setCreatingStudy(false)
    }
  }, [creatingStudy]);

  const studies: any = useStudyData(user?.id ?? '', year);

  const [mouseMovePopup, setMouseMovePopup] = useState<LngLat>();

  // Handle mouse move
  const handleMouseMove = (event: MapMouseEvent) => {
    setMouseMovePopup(event.lngLat);
  };


  console.log('center', center);
  useEffect(() => {
    if (!studies.data) return;
    let allFeatures: any = [];
    for (let i = 0; i < studies.data.length; i++) {
      const study = studies.data[i];
      if (selectedStudyIndex === study.user_id_type_crop_year + study.x_index) {
        const polygons = study.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        allFeatures = allFeatures.concat(features);
      }
    }
    setMyFeatureCollection({
      type: 'FeatureCollection',
      features: allFeatures,
    });
    setTrigger(trigger + 1);
  }, [selectedStudyIndex]);

  useEffect(() => {
    console.log('ue feature collection', myFeatureCollection);
    if (myFeatureCollection.features.length < 1) {
      return;
    }
    setTimeout(() => {
      let index = myFeatureCollection.features.length - 1;
      if (index < 0) {
        return;
      }
      const updatedIndexes = [...selectedFeatureIndexes, index];
      // filter duplicates
      const updatedIndexesSet = new Set(updatedIndexes);
      setSelectedFeatureIndexes(Array.from(updatedIndexesSet));
    }, 10);
  }, [myFeatureCollection.features]);

  return (
    <div className="card rounded-3xl relative h-[500px] w-full overflow-hidden">
      <DeckGL
        layers={[layers, layer]}
        viewState={viewState}
        controller={true}
        onViewStateChange={handleViewStateChange}
        getTooltip={(info: any) => getTooltip(info, relationship, tableDataContext)}
      >
        <Map
          reuseMaps
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle={MAP_STYLE}
          ref={mapRef}
        />
        {/* <Legend /> */}
      </DeckGL>
    </div>
  );
}
