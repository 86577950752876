import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import {
  CropToCode,
  MonthToCode,
  ThemeColors,
  cropToMonthOptions,
  monthList,
} from '../../../constants';
import { monthMapToString } from '../../../constants/dateConfigs';
import { useUser } from '../../../contexts/auth-context';
import { useMarketContext } from '../../../contexts/market-context';
import {
  useCreateAlert,
  useUpdateAlert,
} from '../../../hooks/market/use-alerts';
import Button from '../../commons/Button';
import TextInput from '../../commons/inputs/TextInput';
import Select from '../../commons/Select';
import { set } from 'lodash';
import {
  useCompanyLocationOptions,
  useLocationOptions,
} from '../../../hooks/basis/use-basis';

interface EditAlertProps {
  alert: AlertRecord;
  isUpdatingEditAlert: boolean;
  setIsUpdatingEditAlert: React.Dispatch<React.SetStateAction<boolean>>;
  alertNotificationType: 'Price Trigger' | 'Market Update';
}

interface Alert {
  id: string;
  userId: string;
  crop: string;
  condition: string;
  price: string;
  change: string;
  status: string;
  type: string;
  date: string;
  dateRange: string;
  priceRange: string;
  alertVia: string;
  strikePrice?: string;
  optionType?: string;
  basisLocation?: string;
  basisValue?: string;
  month1?: string;
  month2?: string;
  year1?: string;
  year2?: string;
  interval?: string;
}

interface AlertRecord {
  userId: string;
  crop: string;
  price: string;
  status: string;
  dateRange: string;
  priceRange: string;
  alertVia?: string;
  date: string;
  type: string;
  strikePrice?: string;
  optionType?: string;
  basisLocation?: string;
  basisCompany?: string;
  basisValue?: string;
  month1?: string;
  month2?: string;
  year1?: string;
  year2?: string;
  commodity?: string;
  'user_id-type-crop-basis_location'?: string;
  basis_location?: string;
  basis_company?: string;
  alertPrice?: string;
  basis?: string;
  id?: string;
  sk?: string;
  interval?: string;
}

const typeOptions = [
  { value: 'cash', label: 'Cash' },
  { value: 'futures', label: 'Futures' },
  { value: 'options', label: 'Options' },
  { value: 'basis', label: 'Basis' },
  { value: 'spread', label: 'Spread' },
];

const basisLocations = [
  { value: 'location1', label: 'Location 1' },
  { value: 'location2', label: 'Location 2' },
  { value: 'location3', label: 'Location 3' },
];

const basisCompanies = [
  { value: 'company1', label: 'Company 1' },
  { value: 'company2', label: 'Company 2' },
  { value: 'company3', label: 'Company 3' },
];

const commodityOptions = [
  { value: 'Corn', label: 'Corn' },
  { value: 'HRW Wheat', label: 'HRW Wheat' },
  { value: 'SRW Wheat', label: 'SRW Wheat' },
  { value: 'Soybeans', label: 'Soybeans' },
];

const yearOptions = [
  // { value: '2022', label: '2022' },
  // { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
];

const EditAlert: React.FC<EditAlertProps> = ({
  alert,
  isUpdatingEditAlert,
  setIsUpdatingEditAlert,
  alertNotificationType,
}) => {
  const methods = useForm();
  const { register, handleSubmit, control, watch } = methods;

  const [alerts, setAlerts] = useState<Alert[]>([]);
  const user = useUser();
  const queryClient = useQueryClient();
  const commodity = useRef<string>('Corn');

  const { currentTab, trigger, setTrigger, setUpdatingAlertIndex } =
    useMarketContext();
  const contractType = useRef<string>('basis');
  const { mutate: createAlert } = useCreateAlert(user?.id || '');

  const contractTypeValue = watch('type', alert.type);
  const interval = watch('interval', alert?.interval);

  const { mutate: updateAlert } = useUpdateAlert(user?.id || '');

  const monthNum =
    monthList[parseInt(alert?.sk?.split('-')[1] ?? '01', 10) - 1];
  const year = alert?.sk?.split('-')[0];
  const underlying =
    CropToCode[alert.commodity as keyof typeof CropToCode] +
    MonthToCode[monthNum] +
    year?.slice(3);

  const companyLocationOptions = useCompanyLocationOptions(
    user?.id!,
    year ?? '2024',
    underlying
  );
  const [selectedBasisLocationCompnay, setSelectedBasisLocationCompany] =
    useState<string>('');

  useEffect(() => {
    const newBasisCompanyLocation = companyLocationOptions.find(
      (option: any) =>
        option.value === alert.basis_company + ' & ' + alert.basis_location
    );
    setSelectedBasisLocationCompany(newBasisCompanyLocation?.value);
  }, [companyLocationOptions]);

  const onSubmit = (data: any) => {
    if (data.price?.includes('$') || data.price?.length === 0) {
      window.alert(
        'Invalid price format. Please enter a valid price without the "$" symbol.'
      );
      return;
    }

    const date = new Date(Date.parse(data.month + ' 1, 2020')); // Using any non-leap year
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');

    const newAlert: any = {
      pk: alert['id'],
      sk: alert['sk'],
      price: data?.price,
      basis: data?.basisValue,
      alertVia: data.alertVia,
      priceRange: data?.timing,
      dateRange: data?.triggerPoint,
    };
    if (data.interval) {
      newAlert.interval = data.interval;
    }
    if (data.basisLocation) {
      newAlert.basisLocation = data.basisLocation.split(' & ')[1];
      newAlert.basisCompany = data.basisLocation.split(' & ')[0];
    }

    // update alert
    updateAlert(newAlert, {
      onSuccess: () => {
        // Invalidate and refetch alerts
        queryClient.invalidateQueries([
          'market/alerts',
          user?.id ?? '',
        ] as InvalidateQueryFilters);
        window.alert('Alert updated');
        setIsUpdatingEditAlert(false);
        setUpdatingAlertIndex(-1);
      },
      onError: (error: any) => {
        console.error('Error creating alert:', error);
        window.alert('Failed to update alert.');
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="card p-6 space-y-4"
      >
        <div className="flex items-center space-x-2">
          <CalendarDaysIcon className="h-6 w-6 mr-2" />
          <h2 className="text-xl text-base-bold">
            Edit Alert: {alert.commodity} {alert.date}{' '}
            {alert.type === 'Options' &&
              alert.optionType + ' ' + alert.strikePrice}{' '}
            {alert.type === 'Basis' &&
              alert.basis_company + ' & ' + alert.basis_location}{' '}
            {alert.type}
          </h2>
        </div>

        <div className="flex flex-col gap-4">
          {contractTypeValue === 'Basis' &&
            !alert?.id?.includes('Market Update') && (
              <div className="gap-x-2 flex">
                <div className="w-full">
                  <label htmlFor="basisLocation" className="block mb-2">
                    Company & Location
                  </label>
                  <Select
                    name="basisLocation"
                    value={selectedBasisLocationCompnay}
                    onChange={(e) => {
                      if (e.target.value) {
                        setSelectedBasisLocationCompany(e.target.value);
                      }
                    }}
                    options={companyLocationOptions}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="basisValue" className="block mb-2">
                    Basis Value
                  </label>
                  <TextInput
                    name="basisValue"
                    defaultValue={alert.basis}
                    placeholder="Enter basis value"
                  />
                </div>
              </div>
            )}
          {alertNotificationType === 'Price Trigger' ? (
            <div>
              <div className="grid grid-cols-3 w-full flex gap-x-2">
                <div>
                  <label htmlFor="timing" className="block mb-2">
                    Timing
                  </label>
                  <Select
                    name="timing"
                    options={[
                      { value: 'below', label: 'Below' },
                      { value: 'above', label: 'Above' },
                      { value: 'at', label: 'At' },
                    ]}
                  />
                </div>
                <div>
                  <label htmlFor="triggerPoint" className="block mb-2">
                    Trigger Point
                  </label>
                  <Select
                    name="triggerPoint"
                    options={[{ value: 'last', label: 'Last' }]}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex gap-x-2">
              <div className="w-full">
                <label htmlFor="timing" className="block mb-2">
                  Timing
                </label>
                <Select
                  name="interval"
                  options={[
                    { value: 'open', label: 'On Market Open' },
                    { value: 'close', label: 'On Market Close' },
                    { value: '1', label: 'Every Hour' },
                    { value: '2', label: 'Every 2 Hours' },
                    { value: '4', label: 'Every 4 Hours' },
                  ]}
                />
              </div>
            </div>
          )}
          <div className="grid grid-cols-3 w-full flex gap-x-2">
            {contractTypeValue != 'Basis' &&
              !alert?.id?.includes('Market Update') && (
                <div className="w-full">
                  <label htmlFor="price" className="block mb-2">
                    {contractTypeValue === 'Options'
                      ? 'Option Premium'
                      : 'Price'}
                  </label>
                  <TextInput
                    name="price"
                    defaultValue={alert.alertPrice}
                    placeholder="Enter price"
                  />
                </div>
              )}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <span>Notify via:</span>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="text"
              {...register('alertVia', { required: true })}
              defaultChecked={alert.alertVia === 'text'}
              className="custom-radio"
            />
            <span>Text</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="email"
              defaultChecked={alert.alertVia === 'email'}
              {...register('alertVia', { required: true })}
              className="custom-radio"
            />
            <span>Email</span>
          </label>
        </div>

        <div className="flex space-x-4">
          <Button
            onClick={() => {
              setIsUpdatingEditAlert(false);
              setUpdatingAlertIndex(-1);
            }}
            type="button"
            className={`bg-[${ThemeColors.lightGray}]`}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </div>
      </form>

      {alerts.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg text-base-semibold">Current Alerts</h3>
          <ul>
            {alerts.map((alert) => (
              <li key={alert.id} className="flex justify-between p-2 border-b">
                <span>
                  {alert.crop} - {alert.condition} - {alert.price}
                </span>
                <span>{alert.status}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </FormProvider>
  );
};

export default EditAlert;
