import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createAlertApi,
  deleteAlertApi,
  getAlertsApi,
  updateAlertApi,
} from '../../api/market-api';

export const useAlerts = (userId: string) => {
  const res = useQuery({
    queryKey: ['market/alerts', userId],
    queryFn: () => getAlertsApi(userId),
    enabled: !!userId,
  });

  return { data: res.data };
};

export const useCreateAlert = (userId: string) => {
  const mutation = useMutation({
    mutationKey: ['market/alerts', userId],
    mutationFn: (payload: any) => createAlertApi(payload),
  });

  return mutation;
};

export const useDeleteAlert = (userId: string) => {
  const mutation = useMutation({
    mutationKey: ['market/alerts', userId],
    mutationFn: (params: { id: string; date: string }) =>
      deleteAlertApi(params.id, params.date),
  });
  return mutation;
};

export const useUpdateAlert = (userId: string) => {
  const mutation = useMutation({
    mutationKey: ['market/alerts', userId],
    mutationFn: (payload: any) => updateAlertApi(payload),
  });

  return mutation;
};
