import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getInboundGrainsApi, updateInboundGrainApi } from '../../api/risk-api';
import {
  InboundGrainItem,
  UpdateInboundGrainPayload,
} from '../../types/inbound-grain';
import { enqueueSnackbar } from 'notistack';

export const useGetInboundGrains = ({
  userId,
  partner,
}: {
  userId: string;
  partner: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['inbound-grain', userId, partner],
    queryFn: () => getInboundGrainsApi({ userId, partner }),
    enabled: !!userId && !!partner,
  });

  return { data: data?.data as InboundGrainItem[], ...rest };
};

export const useUpdateInboundGrain = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: UpdateInboundGrainPayload) =>
      updateInboundGrainApi(payload),
    mutationKey: ['UPDATE', 'inbound-grain'],
    onSuccess: () => {
      enqueueSnackbar('Inbound grain updated successfully', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update inbound grain', { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['inbound-grain'] });
    },
  });
};
