import { useEffect, useState } from 'react';
import { WEATHER_BUCKET, weatherTypesToBuckets } from '../../constants';
import { getLatestKeys } from '../../utils';

export const useS3Keys = ({
  state,
  date,
  type,
}: {
  state: string;
  date: string;
  type: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [s3Keys, setS3Keys] = useState<string[]>([]);

 
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!type || !date) return;
      const bucket = WEATHER_BUCKET;
      let prefix = ''
      if (type !== '24hrPrecipitation') {
        prefix =
          weatherTypesToBuckets[type as keyof typeof weatherTypesToBuckets] +
          '/' +
          state +
          '_' +
          date;
      } else {
        prefix =
          weatherTypesToBuckets[type as keyof typeof weatherTypesToBuckets] +
          '/' +
          state +
          '_' +
          date.split('-')[0];
      }
      const newS3Keys = await getLatestKeys(bucket, prefix);
      // check if the keys are the same
      if (JSON.stringify(newS3Keys) === JSON.stringify(s3Keys)) {
        setLoading(false);
        return;
      }
      setS3Keys(newS3Keys);
      setLoading(false);
    };

    fetchData();
  }, [type, state, date]);

  return {
    loading,
    s3Keys,
    state,
    date,
    type
  };
};
