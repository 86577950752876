import { useMemo } from 'react';
import { SupportedCrops } from '../../constants';
import { useYearFilter } from '../../contexts/app-filter-context';
import { useUser } from '../../contexts/auth-context';
import {
  getAreaAndYieldByCrop,
  useFarmYield,
} from '../../hooks/farm/use-farm-yield';
import CardHeader from '../commons/CardHeader';
import CropIcon from '../commons/CropIcon';
import Fluctuation from '../commons/Fluctuation';
import NumberDisplay from '../commons/NumberDisplay';
import { useWebSocketDataV2 } from '../../hooks/websocket/use-websocket-data';
import { useWebsocketContext } from '../../contexts/websocket-context';

function ExpectedAreaAndYieldCard() {
  const year = useYearFilter();
  const user = useUser();

  const { data: res } = useFarmYield(user?.id || '', year || '');

  const msgYield: any = useWebSocketDataV2('Farm_Yield');

  const farmYieldData: any = useMemo(() => {
    if (!res) return [];
    const updatedRes = res.map((farmYield) => {
      if (msgYield && msgYield.data && msgYield.data.crop === farmYield.crop) {
        return {
          ...farmYield,
          area: msgYield.data.area || farmYield.area,
          yield: msgYield.data.yield || farmYield.yield,
          yield_chg: msgYield.data.yield_chg || farmYield.yield_chg,
          yield_chg14: msgYield.data.yield_chg14 || farmYield.yield_chg14,
          yield_per_acre_chg: msgYield.data.yield_chg || farmYield.yield_chg,
        };
      }
      return farmYield;
    });
    return getAreaAndYieldByCrop(updatedRes);
  }, [msgYield.data, res]);

  return (
    <div className="card p-6 space-y-8 rounded-3xl h-full">
      <CardHeader
        title="Expected Area & Yield"
        subtitle="Last 14 Days"
        tooltip="demo"
        link="expected-area-and-yield"
      ></CardHeader>

      <div className="">
        <div className="w-full flex flex-col justify-between space-y-8">
          {/* Table */}
          {SupportedCrops.map((crop) => (
            <div
              key={crop}
              className="flex justify-between items-center w-full space-x-4"
            >
              <div className="w-8 self-center">
                <CropIcon cropName={crop} />
              </div>
              <div className="flex-1 flex flex-col space-y-1">
                <div className="flex justify-between">
                  <NumberDisplay
                    value={farmYieldData[crop]?.totalArea || 0}
                    unit="acre"
                    numberStyle="unit"
                    unitDisplay="long"
                  />
                  <p className="text-dimmed">Area Allocated</p>
                </div>

                <div className="flex justify-between">
                  <span className="flex space-x-3">
                    <NumberDisplay
                      value={farmYieldData[crop]?.totalYield || 0}
                    />
                    <Fluctuation
                      value={farmYieldData[crop]?.yieldChange14 || 0}
                      context="14 days"
                    />
                  </span>
                  <p className="text-dimmed">Expected Yield</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExpectedAreaAndYieldCard;
