import { Field, Label } from '@headlessui/react';
import { groupBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FileDownloadIcon from '../../../assets/icons/FileDownloadIcon';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { useDeleteInvoice, useGetInvoices } from '../../../hooks/invoice/use-invoice';
import Button from '../../commons/Button';
import Checkbox from '../../commons/Checkbox';
import DateRangePicker from '../../commons/inputs/DateRangePicker';
import IconButton from '../../commons/inputs/IconButton';
import Modal from '../../commons/modal';
import Table, { RowActions, TableConfigs } from '../../commons/table/Table';
import TableLayout from '../commons/TableLayout';
import InvoiceExpenseList from './InvoiceExpenseList';
import moment from 'moment';
import TableDownloadButton from '../../commons/table/TableDownloadButton';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import EditInvoiceDetails from './EditInvoiceDetails';

export default function InvoiceList() {
  const user = useControlledUser();
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [viewInvoice, setViewInvoice] = useState<any>();
  const [editInvoice, setEditInvoice] = useState<any>();

  const { data, isFetching } = useGetInvoices({
    userId: user?.id ?? '',
    partner: user?.network_partner ?? 'New Vision', // @TODO: Change to user's network partner
  });




  const { mutate: deleteInvoice } = useDeleteInvoice();

  const configs: TableConfigs = {
    cols: [
      { key: 'invoiceno', name: 'Invoice No.' },
      {
        key: 'invoicedate',
        name: 'Invoice Date',
      },
      {
        key: 'partner',
        name: 'Partner',
      },
      {
        key: 'itemlocdesc',
        name: 'Location',
      },
      { key: 'categorytypedesc', name: 'Category Type' },
      {
        key: 'total_items',
        name: 'No. of Invoice Items',
        subTotal: true,
        type: 'number',
      },
      {
        key: 'totalprice',
        name: 'Total Cost',
        subTotal: true,
        type: 'currency',
      },
    ],
  };
  const handleCheck = (data: any) => {
    setCheckedItems(data);
  };
  const queryClient = useQueryClient();

  const rowActions: RowActions = {
    edit: {
      onSelect: (item) => {

        setEditInvoice(item);
      },
    },
    delete: (item) => {
      deleteInvoice({uniqueid: item.uniqueid, index: item.index}, {
        onSuccess: () => {
          alert('Invoice deleted');
          queryClient.invalidateQueries([
            ['invoices', user?.id, user?.network_partner]
          ] as InvalidateQueryFilters);
        },
        onError: (error) => {
          alert('Error deleting invoice:');
        }
      });
    },
  };

  const groupByInvoice = useMemo(() => groupBy(data, 'invoiceno'), [data]);
  const tableData = useMemo(() => {
    return Object.keys(groupByInvoice).map((key, index) => {
      const items = groupByInvoice[key];
      return {
        index: items[0].index,
        uniqueid: items[0].uniqueid,
        invoiceno: key,
        invoicedate: items[0].invoicedate,
        partner: items[0].partner,
        itemlocdesc: items[0].itemlocdesc,
        categorytypedesc: items[0].categorytypedesc,
        total_items: items.length,
        totalprice: items.reduce(
          (acc, item) =>
            acc +
            (isNaN(Number(item.totalprice)) ? 0 : Number(item.totalprice)),
          0
        ),
        expenses: items.map((item, i) => ({ ...item, index: i + 1 })),
      };
    });
  }, [groupByInvoice]);

  const extraActions = (item: any) => (
    <div className="flex space-x-1">
      <Button color="primary" size="sm" onClick={() => setViewInvoice(item)}>
        View Expenses
      </Button>
      <TableDownloadButton data={[item]} />
    </div>
  );

  // Handle filter
  const filterForm = useForm();
  const filterInputs = (
    <>
      <DateRangePicker name={'invoicedate'} label="Invoice Date" />
    </>
  );
  const filterValues = filterForm.watch();
  const handleFilter = () => {
    return (
      tableData?.filter((item) => {
        if (!filterValues?.invoicedate) return true;
        return (
          moment(item.invoicedate).isSameOrAfter(
            moment(filterValues?.invoicedate?.start?.toString())
          ) &&
          moment(item.invoicedate).isSameOrBefore(
            moment(filterValues?.invoicedate?.end?.toString())
          )
        );
      }) ?? []
    );
  };

  return (
    <>
      <div className="card">
        <TableLayout title="Invoices" tooltip="demo">
          <Table  
            configs={configs}
            data={tableData}
            tableActions={
              <Field className="flex space-x-2 items-center">
                <Label>Select All</Label>
                <Checkbox
                  name="selectAll"
                  onChange={(checked) =>
                    checked ? handleCheck(data) : handleCheck([])
                  }
                />
              </Field>
            }
            indexKey="invoiceno"
            checkedRows={checkedItems?.map((f) => f?.invoiceno)}
            onRowCheck={(item: any, checked) => {
              if (checked) {
                handleCheck([...checkedItems, item]);
              } else {
                handleCheck(
                  checkedItems?.filter((f) => f.invoiceno !== item.invoiceno)
                );
              }
            }}
            rowActions={rowActions}
            extraActions={extraActions}
            pagination={{ size: 15 }}
            loading={isFetching}
            download
            filter={{
              element: filterInputs,
              handler: handleFilter,
              form: filterForm,
            }}
          />
        </TableLayout>
      </div>

      <Modal
        onClose={{
          handler: () => {
            setViewInvoice(undefined);
          },
          btnText: 'Close',
        }}
        title="View Invoice Expenses"
        open={!!viewInvoice}
      >


        <InvoiceExpenseList data={viewInvoice?.expenses} />
      </Modal>
      <Modal
        onClose={{
          handler: () => {
            setEditInvoice(undefined);
          },
          btnText: 'Close',
        }}
        title="Edit Invoice Details"
        open={!!editInvoice}
      >
        <EditInvoiceDetails invoice={editInvoice} />
      </Modal>
    </>
  );
}
