import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useYearFilter } from '../../../../../contexts/app-filter-context';
import { useUser } from '../../../../../contexts/auth-context';
import IncomeViewSwitch from './IncomeViewSwitch';
import UserDropdown from './UserDropdown';
import MarketSessionButton from '../../../../dashboard/market/MarketSessionTime';

export default function UserBar() {
  const { pathname } = useLocation();
  const year = useYearFilter();
  const user = useUser();
  const displayFilter = pathname.includes('dashboard');
  const marketFilter = pathname.includes('markets');

  return (
    <div
      className={clsx(
        'flex flex-col justify-center space-y-4 items-center space-x-4',
        'lg:flex-row lg:justify-between lg:space-y-0'
      )}
    >
      <UserDropdown />
      <h2 className="text-4xl text-base-bold text-center group-data-[theme=Heartland]/root:text-primary">
        {`${user?.first_name} ${user?.last_name}'s Farm ${year}`}
      </h2>
      <div className="flex space-x-8 items-center">
        {displayFilter && <IncomeViewSwitch />}
        {marketFilter && <MarketSessionButton />}
      </div>
    </div>
  );
}
