import { Field, Label } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import Button from '../../commons/Button';
import Checkbox from '../../commons/Checkbox';
import Table, { RowActions, TableConfigs } from '../../commons/table/Table';

export default function InvoiceExpenseList({ data }: { data: any[] }) {
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const tableConfigs: TableConfigs = {
    cols: [
      { key: 'index', name: 'Invoice Item' },
      { key: 'itemdesc', name: 'Description' },
      { key: 'totalprice', name: 'Amount', subTotal: true, type: 'currency' },
    ],
  };

  const handleCheck = (data: any) => {
    setCheckedItems(data);
  };

  const rowActions: RowActions = {
    edit: {
      onSelect: (item) => {
        console.log('Edit', item);
      },
    },
    delete: (item) => {
      console.log('Delete', item);
    },
  };



  return (
    <Table
      configs={tableConfigs}
      data={data}
      tableActions={
        <Field className="flex space-x-2 items-center">
          <Label>Select All</Label>
          <Checkbox
            name="selectAll"
            onChange={(checked) =>
              checked ? handleCheck(data) : handleCheck([])
            }
          />
        </Field>
      }
      indexKey="index"
      checkedRows={checkedItems?.map((f) => f?.index)}
      onRowCheck={(item: any, checked) => {
        if (checked) {
          handleCheck([...checkedItems, item]);
        } else {
          handleCheck(checkedItems?.filter((f) => f.index !== item.index));
        }
      }}
      rowActions={rowActions}
      pagination={{ size: 8 }}
    />
  );
}
