import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { useState } from 'react';

interface SwitchInputProps {
  onChange?: (enabled: boolean) => void;
  enabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  checkedColor?: string;
  defaultColor?: string;
}

export default function SwitchInput({
  onChange,
  enabled: enabledProp,
  size = 'md',
  checkedColor = 'bg-base-900',
  defaultColor = 'bg-base-300',
}: SwitchInputProps) {
  const [enabled, setEnabled] = useState(true);
  const trackSize = {
    sm: 'h-6 w-11',
    md: 'h-6 w-11',
    lg: 'h-11 w-20',
  };

  const checkedClasses = {
    sm: 'size-5 -translate-x-0.5 group-data-[checked]:translate-x-[18px] -translate-y-0.5',
    md: 'size-5 -translate-x-0.5 group-data-[checked]:translate-x-[18px] -translate-y-0.5',
    lg: 'size-9 group-data-[checked]:translate-x-9',
  };

  return (
    <Switch
      checked={onChange ? enabledProp : enabled}
      onChange={onChange || setEnabled}
      className={clsx(
        `group relative flex cursor-pointer rounded-full p-1 ${defaultColor}`,
        'focus:outline-none data-[focus]:outline-1 data-[focus]:outline-base-000',
        'transition-colors duration-200 ease-in-out',
        `data-[checked]:${checkedColor}`,
        trackSize[size]
      )}
    >
      <span
        aria-hidden="true"
        className={clsx(
          'pointer-events-none inline-block rounded-full bg-base-000 ring-0 shadow-lg ',
          'transition duration-200 ease-in-out ',
          checkedClasses[size]
        )}
      />
    </Switch>
  );
}
