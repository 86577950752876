import { IconProps } from '../../types';

export default function PrecipipatitionRateIcon({
  fill = '#5C7284',
}: IconProps) {
  return (
    <svg
      width="15"
      height="22"
      viewBox="0 0 15 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71875 1.0062C10.7344 7.47495 15 9.20932 15 13.8031C15 17.8343 11.625 21.0687 7.5 21.0687C3.32812 21.0687 0 17.8343 0 13.8031C0 9.20932 4.21875 7.47495 6.23438 1.0062C6.60938 -0.259428 8.39062 -0.165678 8.71875 1.0062ZM3.75 10.9437C3.75 11.5999 4.21875 12.0687 4.875 12.0687C5.48438 12.0687 6 11.5999 6 10.9437C6 10.3343 5.48438 9.86557 4.875 9.86557C4.21875 9.86557 3.75 10.3343 3.75 10.9437ZM5.76562 16.3812L10.2656 11.8812C10.5469 11.5999 10.5469 11.0843 10.2656 10.8031C9.98438 10.5218 9.46875 10.5218 9.1875 10.8031L4.6875 15.2562C4.40625 15.5843 4.40625 16.0531 4.6875 16.3343C4.82812 16.4749 5.01562 16.5687 5.25 16.5687C5.4375 16.5687 5.625 16.5218 5.76562 16.3812ZM10.125 17.3187C10.7344 17.3187 11.25 16.8499 11.25 16.1937C11.25 15.6312 10.7344 15.1156 10.125 15.1156C9.46875 15.1156 9 15.5843 9 16.1937C9 16.8031 9.46875 17.3187 10.125 17.3187Z"
        fill={fill}
      />
    </svg>
  );
}
