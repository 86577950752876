import {
  BookmarkIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import Button from '../../../components/commons/Button';
import Disclosure from '../../../components/commons/Disclosure';
import TextInput from '../../../components/commons/inputs/TextInput';
import Select from '../../../components/commons/Select';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import { mapRelationshipOptions } from '../../../constants/selectCommonConfigs';
import { createStudyTableConfig } from '../../../constants/tableConfigs';
import { useSetYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser } from '../../../contexts/auth-context';
import {
  useCreateNote,
  useDeleteStudy,
  useHistoricalInsights,
  useStudyData,
  useStudyNote,
} from '../../../hooks/historical-insights/use-historical-insights';
import { capitalizeFirst } from '../../../utils';
import DeprecatedTable from '../../../components/commons/table/DeprecatedTable';
import { ElevationMode } from '@deck.gl-community/editable-layers';

export default function StudyList() {
  const {
    creatingStudy,
    setCreatingStudy,
    relationship,
    studyDetails,
    setStudyDetails,
    setCreateStudy,
    year,
    setStudies,
    setSelectedStudyIndex,
    selectedStudyIndex,
    myFeatureCollection,
    setMyFeatureCollection,
    setEditMode,
    setSaveEditingStudy,
    setRgb,
    setRelationship,
    editMode,
    setYear,
    setCrop,
    setModalMode,
    setSelectedDrawMode,
  } = useHistoricalInsights();
  const user = useControlledUser();
  const studies = useStudyData(user?.id ?? '', year);


  const [studyRelationshipFilter, setStudyRelationshipFilter] = useState('');
  const setYearFilter = useSetYearFilter();
  const updateMapSelections = (study: any) => {
    setYearFilter?.(study.year);
    setYear(study.year);
    setRelationship(study.relationship);
    setCrop(study.crop);
  };
  const queryClient = useQueryClient();
  const [trigger, setTrigger] = useState(0);
  const [studyNotePk, setStudyNotePk] = useState<string | null>(null);
  const [studyNodeXIndex, setStudyNodeXIndex] = useState<string | null>(null);

  const { mutate: createNote } = useCreateNote();
  const { mutate: deleteStudy } = useDeleteStudy();
  const studyNoteData = useStudyNote(studyNotePk ?? '', studyNodeXIndex ?? '');

  const studyNote = useMemo(() => {
    return studyNoteData.data?.note ?? '';
  }, [studyNoteData]);

  const rowActions = {
    edit: {
      onSelect: (item: any) => {
        updateMapSelections(item);
        if (editMode == 'edit') {
          setEditMode('');
          setMyFeatureCollection({
            type: 'FeatureCollection',
            features: [],
          });
          return;
        }
        setEditMode('edit');

        // remove all polygons and redraw study polyogns on map
        const polygons = item.polygons;
        console.log('polygons', polygons);
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: features,
        });
        setSaveEditingStudy(item);
      },
    },
    delete: (item: any) => {
      deleteStudy(item, {
        onSuccess: () => {
          setMyFeatureCollection({
            type: 'FeatureCollection',
            features: [],
          });
          setSelectedStudyIndex(-1);
          alert('Study Deleted');
          queryClient.invalidateQueries({
            queryKey: ['historical-insights/study-data'],
          });
        },
        onError: (error: unknown, _variables: any, _context: unknown) => {
          alert(`Failed to delete study. Please try again. ${error}`);
        },
      });
    },
    note: {
      onSelect: (item: any) => {
        //
        setTrigger((prev) => prev + 1);
        setStudyNotePk(item.user_id_type_crop_year);
        setStudyNodeXIndex(item.x_index);
      },
      onSave: (item: any, value: string) => {
        createNote(
          {
            user_id_type_crop_year: item.user_id_type_crop_year,
            xIndex: item.x_index,
            note: value,
          },
          {
            onSuccess: () => {
              alert('Note Saved');
              queryClient.invalidateQueries({
                queryKey: ['historical-insights/createNode'],
              });
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              alert(`Failed to saven note. Please try again. ${error}`);
            },
          }
        );
      },
      title: 'Add Note',
    },
  };

  useEffect(() => {
    if (!studies.data) {
      return;
    }
    setStudies(studies.data);
  }, [studies.data]);

  console.log('studies', studies);

  const formattedTableData = useMemo(() => {
    return studies.data
      ?.map((data: any) => {
        return {
          ...data,
          x_value: parseFloat(data.x_value).toFixed(2),
          area: parseFloat(data.area).toFixed(2),
          lon: parseFloat(data.lon).toFixed(2),
          lat: parseFloat(data.lat).toFixed(2),
          yield: parseFloat(data.yield).toFixed(2),
          yield_performance: parseFloat(data.yield_performance).toFixed(2),
        };
      })
      .filter((data: any) => {
        if (studyRelationshipFilter === 'all') {
          return true;
        } else if (studyRelationshipFilter !== '') {
          return data.relationship === studyRelationshipFilter;
        }
        return true;
      });
  }, [studies.data, studyRelationshipFilter]);

  const studyTableConfig = (): TableConfigs => {
    return {
      cols: [
        {
          key: 'name',
          name: 'Name',
        },
        {
          key: 'year',
          name: 'Year',
        },
        {
          key: 'crop',
          name: 'Crop',
        },
        {
          key: 'relationship',
          name: 'Type',
        },
        {
          key: 'area',
          name: 'Acres',
        },
        {
          key: 'lon',
          name: 'lon',
        },
        {
          key: 'lat',
          name: 'lat',
        },
        {
          key: 'x_value',
          name: 'Value',
        },
        {
          key: 'x_bin',
          name: `Value Range`,
        },
        {
          key: 'yield',
          name: 'Yield',
        },
        {
          key: 'yieldPerformance',
          name: 'Yield Performance',
        },
        {
          key: '',
          name: 'View Study',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                if (
                  selectedStudyIndex ===
                  item.user_id_type_crop_year + item.x_index
                ) {
                  setSelectedStudyIndex(-1);
                  return;
                }
                setSelectedStudyIndex(
                  item.user_id_type_crop_year + item.x_index
                );
              }}
              style={{
                backgroundColor:
                  selectedStudyIndex ===
                  item.user_id_type_crop_year + item.x_index
                    ? 'gray'
                    : user?.network_partner === 'Heartland' ? '#99002a' : '#80bc00',
                    color: 'white',
              }}
              className={`w-full cursor-pointer h-8 justify-center items-center flex rounded-md`}
            >
              {selectedStudyIndex ===
              item.user_id_type_crop_year + item.x_index ? (
                <p>Hide</p>
              ) : (
                <p>View</p>
              )}
            </div>
          ),
        },
      ],
    };
  };

  return (
    <Disclosure title="Custom Studies">
      {creatingStudy ? (
        <div className="card cursor-pointer justify-center items-center  flex  flex-col px-6 w-full">
          <p className="text-xl ">Save {capitalizeFirst(relationship)} Study</p>
          <TextInput
            name="studyName"
            placeholder="Study Name"
            className="mt-4"
            onChange={(e) =>
              setStudyDetails({ ...studyDetails, name: e.target.value })
            }
          />
          <div
            style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
            className="w-full rounded-xl mt-4"
          >
            <DeprecatedTable
              configs={createStudyTableConfig(relationship)}
              data={[studyDetails]}
            />
          </div>

          <div className="flex gap-4 mt-4">
            <div
              onClick={() => {
                setCreateStudy(true)
              }}
              className="cursor-pointer bg-primary flex gap-2 rounded-xl px-4 py-2"
            >
              <BookmarkIcon color={'white'} className="w-6 h-6" />
              <p>Save</p>
            </div>
            <div
              onClick={() => setCreatingStudy(false)}
              className="flex gap-2 card px-4 py-2"
            >
              <XCircleIcon color={'darkGray'} className="w-6 h-6" />
              <p>Cancel</p>
            </div>
          </div>
        </div>
      ) : (
        studies &&
        studies.data && (
          <div className="">
            <div className="flex items-center gap-4 p-2 group-data-[theme=Heartland]/root:bg-tan">
              <Select
                options={[
                  { label: 'All', value: 'all' },
                  ...mapRelationshipOptions,
                ]}
                name="tab"
                className="max-w-[160px]"
                onChange={(e) => {
                  if (e.target.value === 'all') {
                    setStudyRelationshipFilter(e.target.value);
                    return;
                  }
                  setRelationship(e.target.value);
                  setRgb([0, 0, 0]);
                  setStudyRelationshipFilter(e.target.value);
                }}
              />

              {creatingStudy ? (
                <Button
                  onClick={() => {
                    setCreatingStudy(false);
                  }}
                  startIcon={<XCircleIcon className="size-6" />}
                >
                  <p>Remove Study Filter</p>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (myFeatureCollection.features.length === 0) {
                      alert('Please draw a polygon to create a study');
                      return;
                    }
                    setCreatingStudy(true);
                    setSelectedDrawMode(0);
                    setModalMode('');
                  }}
                  startIcon={<PlusCircleIcon className="size-6" />}
                  color="primary"
                  className="!w-fit tertiaryBtn"
                >
                  Create Study From Selection
                </Button>
              )}
            </div>
            <DeprecatedTable
              configs={studyTableConfig()}
              data={formattedTableData ?? []}
              rowActions={rowActions}
              initNote={studyNote}
              pagination={{ size: 5 }}
              noteTrigger={trigger}
            />
          </div>
        )
      )}
    </Disclosure>
  );
}
