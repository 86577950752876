import { useState } from 'react';
import { Legend } from '../../../commons/inputs/SliderInput';
import InplaceEditNumber from './InplaceEditNumber';
import Knob from './Knob';

export default function KnobContainer({
  id, // unique id for the knob
  name, // name for the knob/input
  value,
  marks,
  min = 0,
  max,
  onChange,
  onUpdate,
  loading, // need this to knob to refresh when the value is updated
  isEditing,
  setIsEditing,
}: {
  id: string;
  name: string;
  value: number;
  marks: {
    name: string;
    value: number;
    color: string;
  }[];
  min: number;
  max: number;
  onChange?: (value: number) => void;
  onUpdate: (value: number) => void;
  loading: boolean;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
}) {
  const [state, setState] = useState(() => (isNaN(value) ? 0 : value));

  const handleOnChange = (value: number) => {
    setState(value);
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-4 mt-6">
      <Knob
        id={`knob-${id}-${name}`}
        key={`knob-${id}-${name}`}
        min={min}
        max={max}
        value={state}
        marks={marks}
        onChange={handleOnChange}
        onUpdate={onUpdate}
        loading={loading}
      />
      <div className="text-center flex flex-col justify-center items-center space-y-2">
        <InplaceEditNumber
          name={name}
          defaultValue={state}
          className="text-secondary display-md-bold"
          onSave={onUpdate}
          onChange={handleOnChange}
          loading={loading}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          min={0}
          max={max}
        />
        <div>
          {marks.map((mark) => (
            <Legend
              key={mark.name}
              label={mark.name}
              value={mark.value}
              color={mark.color}
              className="text-xs-regular"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
