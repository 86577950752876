import { useEffect, useMemo } from 'react';
import { SupportedCrops } from '../../../constants';
import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import {
  getTotalNetIncome,
  getTotalNetIncomeByCrop,
  getTotalRevenue,
  getTotalRevenueByCrop,
  useFarmRevenues,
} from '../../../hooks/farm/use-farm-revenues';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import CardHeader from '../../commons/CardHeader';
import CropIcon from '../../commons/CropIcon';
import Currency from '../../commons/Currency';
import Fluctuation from '../../commons/Fluctuation';
import ExpectedRevenue14ChangeChart from './ExpectedRevenueChart';

function ExpectedRevenueCard() {
  const user = useUser();
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();

  const { data } = useFarmRevenues(user?.id || '', year || '');

  const { setTables, setProductTypes, setRoots } = useWebsocketContext();
  useEffect(() => {
    setProductTypes(['basis', 'futures', 'options']);
    setTables(['Farm_Revenue2_PROD', 'Farm_Yield2_PROD', 'Bid_Ask']);
    setRoots(['ZC', 'KE', 'ZS', 'ZW']);
  }, [setTables]);

  const msgRevenue: any = useWebSocketDataV2('Farm_Revenue');

  const farmRevenueData = useMemo(() => {
    if (!data) return [];
    return data.map((revenue) => {
      if (
        msgRevenue &&
        msgRevenue?.data?.user_id_crop === revenue?.user_id_crop
      ) {
        return {
          ...revenue,
          revenue: msgRevenue.data.revenue || revenue.revenue,
          revenue_chg: msgRevenue.data.revenue_chg || revenue.revenue_chg,
          revenue_per_acre:
            msgRevenue.data.revenue_per_acre || revenue.revenue_per_acre,
          revenue_per_acre_chg:
            msgRevenue.data.revenue_per_acre_chg ||
            revenue.revenue_per_acre_chg,
        };
      }
      return revenue;
    });
  }, [msgRevenue, data]);

  const totalRevenue = useMemo(
    () => getTotalRevenue(farmRevenueData),
    [farmRevenueData]
  );
  const totalRevenueByCrop = useMemo(
    () => getTotalRevenueByCrop(farmRevenueData),
    [farmRevenueData]
  );
  const totalNetIncome = useMemo(
    () => getTotalNetIncome(farmRevenueData),
    [farmRevenueData]
  );
  const totalNetIncomeByCrop = useMemo(
    () => getTotalNetIncomeByCrop(farmRevenueData),
    [farmRevenueData]
  );

  const incomeToView = useMemo(
    () => (incomeView === 'revenue' ? totalRevenue : totalNetIncome),
    [incomeView, totalRevenue, totalNetIncome]
  );
  const incomeByCropToView = useMemo(
    () =>
      incomeView === 'revenue' ? totalRevenueByCrop : totalNetIncomeByCrop,
    [incomeView, totalRevenueByCrop, totalNetIncomeByCrop]
  );

  return (
    <div className="card p-6 space-y-8 h-full">
      <CardHeader
        title={`Expected ${incomeView === 'revenue' ? 'Revenue' : 'Net Income'}`}
        subtitle="Prev. Close"
        link="expected-revenue"
      >
        <div className="flex space-x-4 items-center">
          <Currency
            value={incomeToView.total}
            className="text-xl-bold text-red-dark"
          />
          <Fluctuation value={incomeToView.change} currency="USD" />
        </div>
      </CardHeader>

      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 md:col-span-6 w-full flex flex-col justify-between space-y-8">
          {/* Table */}
          {SupportedCrops.map((crop) => (
            <div key={crop} className="flex items-center w-full space-x-4">
              <div className="w-8 self-center">
                <CropIcon cropName={crop} />
              </div>
              <div className="flex-1 flex justify-between">
                <div className="space-y-1">
                  <Currency
                    value={incomeByCropToView?.[crop]?.total ?? 0}
                    className="text-md-bold"
                  />
                  <Fluctuation
                    value={incomeByCropToView?.[crop]?.change ?? 0}
                    currency="USD"
                    context="Prev. Close"
                  />
                </div>
                <div className="space-y-1">
                  <Currency
                    value={incomeByCropToView?.[crop]?.totalPerAcre ?? 0}
                  />
                  <Fluctuation
                    value={incomeByCropToView?.[crop]?.changePerAcre ?? 0}
                    currency="USD"
                    context="Per Acre"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Chart */}
        <div className="card p-6 card-elevated col-span-12 md:col-span-6">
          <ExpectedRevenue14ChangeChart width={240} height={150} />
          <p className="text-center">Last 14 Days</p>
        </div>
      </div>
    </div>
  );
}

export default ExpectedRevenueCard;
