import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { getCropColors } from '../../../utils';
import Checkbox from '../../commons/Checkbox';
import { useUser } from '../../../contexts/auth-context';

function MapLegend({
  filters,
  onChange,
}: {
  filters: string[];
  onChange: (value: string) => void;
}) {
  const [value, setValue] = useState<string>('All');
  const user = useUser();

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <div className="card p-6">
      <span className="flex items-center space-x-2">
        <h5 className="display-sm-bold text-dimmed">Map Legend</h5>
        <InformationCircleIcon className="fill-yellow size-4 " />
      </span>

      <div
        className={clsx(
          'mt-6 flex flex-col space-y-4 items-center',
          'md:flex-row md:justify-start md:space-y-0 md:space-x-6'
        )}
      >
        <span>
          <Checkbox
            name="all"
            label="All Fields"
            labelClassname="display-xs-bold text-dimmed"
            checked={value === 'All'}
            onChange={() => setValue('All')}
            checkboxClassname="dark"
          />
        </span>

        {filters?.map((name) => (
          <span
            key={name}
            className={clsx('border-2 px-3 py-2 rounded-xl')}
            style={{
              backgroundColor: getCropColors(name, user?.network_partner),
            }}
          >
            <Checkbox
              name={name}
              label={`${name} Fields`}
              labelClassname="display-xs-bold text-light"
              checked={value === name}
              onChange={() => setValue(name)}
            />
          </span>
        ))}
      </div>
    </div>
  );
}

export default MapLegend;
