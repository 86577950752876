import { IconProps } from '../../types';

export default function ClockIcon({ fill = '#5C7284' }: IconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24.6152C5.34375 24.6152 0 19.2715 0 12.6152C0 6.00586 5.34375 0.615234 12 0.615234C18.6094 0.615234 24 6.00586 24 12.6152C24 19.2715 18.6094 24.6152 12 24.6152ZM10.875 12.6152C10.875 12.9902 11.0625 13.3652 11.3438 13.5527L15.8438 16.5527C16.3594 16.9277 17.0625 16.7871 17.3906 16.2715C17.7656 15.7559 17.625 15.0527 17.1094 14.6777L13.125 12.0527V6.24023C13.125 5.63086 12.6094 5.11523 11.9531 5.11523C11.3438 5.11523 10.8281 5.63086 10.8281 6.24023L10.875 12.6152Z"
        fill={fill}
      />
    </svg>
  );
}
