import { useVARChartData } from '../../../hooks/farm/use-farm-revenues';
import CardHeader from '../../commons/CardHeader';
import Currency from '../../commons/Currency';
import Fluctuation from '../../commons/Fluctuation';
import VAR14ChangeChart from './VAR14ChangeChart';
import VARChart from './VARChart';

function ValueAtRiskCard() {
  const {
    nRVAR,
    hVARToShow,
    vAR,
    varToShow,
    riskReductionHedges,
    riskReductionInsurance,
    priceRisk,
    chartColors,
  } = useVARChartData();

  return (
    <div className="card p-6 space-y-8">
      <CardHeader
        title="Value-at-Risk"
        subtitle="Last 14 Days"
        tooltip="demo"
        link="value-at-risk"
      >
        <div className="flex space-x-4 items-center text-dimmed">
          <Currency
            value={varToShow?.total || 0}
            className="text-xl-bold text-red-dark"
          />
          <Fluctuation value={varToShow?.change || 0} currency="USD" /> Change
        </div>
      </CardHeader>

      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 md:col-span-6 w-full flex flex-col items-center space-y-4">
          <p className="display-sm-regular">Potential Daily Loss to Revenue</p>
          <VARChart
            nRVAR={nRVAR}
            hVAR={hVARToShow}
            vAR={vAR.total}
            colors={chartColors}
          />
          <div className="text-center">
            <p className="text-green text-xs-regular">
              Risk Reduction <Currency value={riskReductionHedges} /> hedges
            </p>
            <p className="text-green text-xs-regular">
              Risk Reduction <Currency value={riskReductionInsurance} />{' '}
              Insurance
            </p>
            <p className="text-red text-lg-bold">
              <Currency value={priceRisk} /> Price Risk
            </p>
          </div>
        </div>

        {/* Chart */}
        <div className="card p-6 card-elevated col-span-12 md:col-span-6">
          <VAR14ChangeChart />
        </div>
      </div>
    </div>
  );
}

export default ValueAtRiskCard;
