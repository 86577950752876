import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Currency from './Currency';

interface FluctuationProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: number;
  context?: string;
  currency?: string;
  contextClassname?: string;
  unit?: string;
  revert?: boolean;
}

function Fluctuation({
  value,
  context,
  className,
  currency,
  contextClassname,
  unit,
  revert,
  ...rest
}: FluctuationProps) {
  if (typeof value !== 'number') return null;

  const isPositive = Number(value.toFixed(2)) > 0;
  const isNegative = Number(value.toFixed(2)) < 0;
  const isZero = Number(value.toFixed(2)) === 0;

  return (
    <span className={clsx('flex items-center space-x-2', className)}>
      <span
        className={clsx(
          'flex items-center',
          isPositive && !revert && 'text-green',
          isPositive && revert && 'text-red',
          isNegative && !revert && 'text-red',
          isNegative && revert && 'text-green',
          isZero && 'text-inherit'
        )}
      >
        {isPositive ? (
          <ArrowUpIcon className="size-4" />
        ) : isNegative ? (
          <ArrowDownIcon className="size-4" />
        ) : (
          <ArrowDownIcon color="transparent" className="size-4" />
        )}
        {currency ? (
          <Currency value={value} currency={currency} />
        ) : (
          <p className="!leading-none">
            {value.toFixed(2).replace(/^-0.00$/, '0.00')}
            {unit}
          </p>
        )}
      </span>
      {/* unit/time... */}
      <p
        className={clsx(
          'text-xs-regular !leading-none text-dimmed',
          contextClassname
        )}
      >
        {context}
      </p>
    </span>
  );
}

export default Fluctuation;
