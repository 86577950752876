import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-chart-financial';
import {
  CandlestickController,
  CandlestickElement,
} from 'chartjs-chart-financial';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Form, FormProvider, useForm } from 'react-hook-form';
import ReactSlider from 'react-slider';
import { CropToCode } from '../../../constants';
import { spreadsChartOptions } from '../../../constants/chartConfigs';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { useSpreadPrices, useSpreads } from '../../../hooks/market/use-spreads';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { AllFuturesRecord, AllFuturesRes } from '../../../types';
import { convertSymbolToContract, getTime } from '../../../utils';
import Select from '../../commons/Select';
import Table, { TableConfigs } from '../../commons/table/Table';
import { commodityOptions } from './PriceChart';
import TableLayout from '../commons/TableLayout';
import { useControlledUser } from '../../../contexts/auth-context';

ChartJS.register(
  CategoryScale,
  LinearScale,
  CandlestickController,
  CandlestickElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

function SpreadsTable() {
  const spreadTableConfigs: TableConfigs = {
    cols: [
      { key: 'contract', name: 'Contract' },
      { key: 'previous', name: 'Open' },
      { key: 'low', name: 'Low' },
      { key: 'high', name: 'High' },
      { key: 'last', name: 'Last' },
      {
        key: 'change',
        name: 'Change',
        type: 'fluctuation',
      },
      // { key: 'bid', name: 'Bid' },
      // { key: 'ask', name: 'Ask' },
      { key: 'volume', name: 'Volume' },
      { key: 'openInterest', name: 'Open Interest' },
      { key: 'IV', name: 'IV' },
      { key: 'IV_change', name: 'IV Change' },
      { key: 'lastTradeTime', name: 'Last Trade' },
    ],
  };

  const user = useControlledUser();
  const spreadsSymbolRef = useRef<string>('');
  const [sliderValues, setSliderValues] = useState<[number, number]>([0, 0]);
  const [highlightedCells, setHighlightedCells] = useState<
    { rowKey: string; columnKey: string }[]
  >([]);
  const [tableData, setTableData] = useState<AllFuturesRecord[]>([]);

  const methods = useForm();
  const { setRoots } = useWebsocketContext();

  const contracts = methods.watch('contracts');
  const crop = methods.watch('crop', 'Corn');

  const spreads = useSpreads(CropToCode[crop]);
  const spreadHLCO = useSpreadPrices(contracts);

  const msgHLCO: any = useWebSocketDataV2('HLCO');
  const msgBidAsk: any = useWebSocketDataV2('Bid_Ask');

  function getMonthNumber(month: string) {
    const months = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    return months[month as keyof typeof months];
  }

  const spreadSymbols = useMemo(() => {
    return (
      spreads.data?.data
        ?.map((item: AllFuturesRes, index: number) => {
          const start = convertSymbolToContract(item.symbol.split('-')[0]);
          const end = convertSymbolToContract(item.symbol.split('-')[1]);
          if (index === 0) {
            spreadsSymbolRef.current = item.symbol;
          }
          return {
            symbol: item.symbol,
            contractDetailsStart: start,
            contractDetailsEnd: end,
            contractDetails:
              start && end
                ? `${start.month} '${start.year} - ${end.month} '${end.year}`
                : '',
            label:
              start && end
                ? `${start.month} '${start.year} - ${end.month} '${end.year}`
                : '',
            value: item.symbol ?? '',
          };
        })
        .filter((item: any) => !item.label?.includes('null'))
        .sort((a: any, b: any) => {
          // sort by start month year then end mont year
          const startYearDiff =
            a.contractDetailsStart.year - b.contractDetailsStart.year;
          if (startYearDiff !== 0) return startYearDiff;

          const startMonthDiff =
            getMonthNumber(a.contractDetailsStart.month) -
            getMonthNumber(b.contractDetailsStart.month);
          if (startMonthDiff !== 0) return startMonthDiff;

          const endYearDiff =
            a.contractDetailsEnd.year - b.contractDetailsEnd.year;
          if (endYearDiff !== 0) return endYearDiff;

          return (
            getMonthNumber(a.contractDetailsEnd.month) -
            getMonthNumber(b.contractDetailsEnd.month)
          );
        }) ?? []
    );
  }, [spreads?.data?.data, crop, contracts]);

  useEffect(() => {
    let mapData: AllFuturesRecord[] = spreads.data?.data || [];
    const newHighlights: { rowKey: string; columnKey: string }[] = [];

    const updatedData = mapData
      .map((item: AllFuturesRes) => {
        if (item && item.symbol && item.symbol.includes('-')) {
          const symbols = item.symbol.split('-');
          const contractDetailsStart = convertSymbolToContract(symbols[0]);
          const contractDetailsEnd = convertSymbolToContract(symbols[1]);
          if (!contractDetailsStart || !contractDetailsEnd) return null;

          const { month: monthStart, year: yearStart } = contractDetailsStart;
          const contractStart = `${monthStart} '${yearStart}`;
          const { month: monthEnd, year: yearEnd } = contractDetailsEnd;
          const contractEnd = `${monthEnd} '${yearEnd}`;
          const contract = `${contractStart} - ${contractEnd}`;
          let columnUpdated = false;

          let newHLCO = {};
          let newBidAsk = {};
          let isNewMessage = false;

          if (msgHLCO && msgHLCO.data.symbol === item.symbol) {
            newHLCO = msgHLCO.data;
            isNewMessage = true; // Only trigger highlight on HLCO message
            if (
              item.high !== msgHLCO.data.high &&
              msgHLCO.data.high !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'high' });
            }
            if (
              item.low !== msgHLCO.data.low &&
              msgHLCO.data.low !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'low' });
            }
            if (
              item.last !== msgHLCO.data.last &&
              msgHLCO.data.last !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'last' });
            }
            if (
              item.change !== msgHLCO.data.change &&
              msgHLCO.data.change !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'change' });
            }
          }
          if (msgBidAsk && msgBidAsk.data.symbol === item.symbol) {
            newBidAsk = msgBidAsk.data;
            isNewMessage = true; // Trigger highlight on BidAsk only if no HLCO for this symbol
            if (
              item.bid !== msgBidAsk.data.bid &&
              msgBidAsk.data.bid !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'bid' });
            }
            if (
              item.change !== msgBidAsk.data.change &&
              msgBidAsk.data.change !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'change' });
            }
            if (
              item.volume !== msgBidAsk.data.volume &&
              msgBidAsk.data.volume !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'volume' });
            }
            if (
              item.openInterest !== msgBidAsk.data.open_interest &&
              msgBidAsk.data.open_interest !== undefined
            ) {
              columnUpdated = true;
              item.openInterest = msgBidAsk.data.open_interest;
              newHighlights.push({
                rowKey: contract,
                columnKey: 'openInterest',
              });
            }
            if (
              item.last !== msgBidAsk.data.trade_pice &&
              msgBidAsk.data.trade_price !== undefined
            ) {
              columnUpdated = true;
              item.last = msgBidAsk.data.trade_price;
              newHighlights.push({ rowKey: contract, columnKey: 'last' });
            }
            if (
              item.IV !== msgBidAsk.data.implied_volatility &&
              msgBidAsk.data.implied_volatility !== undefined
            ) {
              item.IV = msgBidAsk.data.implied_volatility;
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'IV' });
            }
            if (
              item.IV_change !== msgBidAsk.data.implied_volatility_chg &&
              msgBidAsk.data.implied_volatility_chg !== undefined
            ) {
              item.IV_change = msgBidAsk.data.implied_volatility_chg;
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'IV_change' });
            }
            if (
              item.ask !== msgBidAsk.data.ask &&
              msgBidAsk.data.ask !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'ask' });
            }
          }

          const date = moment(`${monthStart}/20${yearStart}`).toLocaleString();
          return {
            ...item,
            IV: (parseFloat(item?.IV || "0") * 100).toFixed(2) + "%" || "0%",
            contract,
            lastTradeTime: getTime(item.last_trade_time),
            date,
            contractDetailsStart:
              contractDetailsStart.month + " '" + contractDetailsStart.year,
            contractDetailsEnd:
              contractDetailsEnd.month + " '" + contractDetailsEnd.year,
            ...newHLCO,
            ...newBidAsk
          };
        }
        return null;
      })
      .sort((a: any, b: any) => {
        // sort by start month year then end mont year
        const acontractDetailsStart = a.contractDetailsStart.split(' ');
        const acontractYearStart = parseInt(acontractDetailsStart[1].slice(1));
        const acontractMonthStart = acontractDetailsStart[0];
        const acontractDetailsEnd = a.contractDetailsEnd.split(' ');
        const acontractYearEnd = parseInt(acontractDetailsEnd[1].slice(1));
        const acontractMonthEnd = acontractDetailsEnd[0];
        const bcontractDetailsStart = b.contractDetailsStart.split(' ');
        const bcontractYearStart = parseInt(bcontractDetailsStart[1].slice(1));
        const bcontractMonthStart = bcontractDetailsStart[0];
        const bcontractDetailsEnd = b.contractDetailsEnd.split(' ');
        const bcontractYearEnd = parseInt(bcontractDetailsEnd[1].slice(1));
        const bcontractMonthEnd = bcontractDetailsEnd[0];


        const startYearDiff = acontractYearStart - bcontractYearStart;
        if (startYearDiff !== 0) {
          console.log('start year diff', startYearDiff, 'for a:', a, 'b:', b);
          return startYearDiff;
        }

        const startMonthDiff = getMonthNumber(acontractMonthStart) - getMonthNumber(bcontractMonthStart);
        if (startMonthDiff !== 0) {
          console.log('start month diff', startMonthDiff, 'for a:', a, 'b:', b);
          return startMonthDiff;
        }

        const endYearDiff = acontractYearEnd - bcontractYearEnd;
        if (endYearDiff !== 0) {
          console.log('end year diff', endYearDiff, 'for a:', a, 'b:', b);
          return endYearDiff;
        }

        const endMonthDiff = getMonthNumber(acontractMonthEnd) - getMonthNumber(bcontractMonthEnd);
        console.log('end month diff', endMonthDiff, 'for a:', a, 'b:', b);
        return endMonthDiff;
      })
      .filter((item: any) => !item.contract.includes('null'))
      .filter(Boolean) as AllFuturesRecord[];

    setTableData(updatedData);

    if (newHighlights.length > 0) {
      setHighlightedCells((prev) =>
        Array.from(new Set([...prev, ...newHighlights]))
      );
    }
  }, [spreads?.data?.data, msgHLCO.data, msgBidAsk.data]);


  useEffect(() => {
    // Remove highlights after the specified duration
    const timeout = setInterval(() => {
      setHighlightedCells([]);
    }, 500);

    // Cleanup timeout if component unmounts or dependencies change
    return () => clearTimeout(timeout);
  }, []);

  const priceChartData = useMemo(() => {
    if (!spreadHLCO.data || !spreadHLCO.data.price) return [];
    return spreadHLCO.data.price.map((item: any) => {
      if (
        msgHLCO &&
        msgHLCO.data.symbol === item.symbol &&
        msgHLCO.data.trade_date === item.trade_date
      ) {
        return {
          x: new Date(item.trade_date).getTime(),
          o: msgHLCO.data?.open
            ? parseFloat(msgHLCO.data.open)
            : parseFloat(item.open),
          h: msgHLCO.data?.high
            ? parseFloat(msgHLCO.data.high)
            : parseFloat(item.high),
          l: msgHLCO.data?.low
            ? parseFloat(msgHLCO.data.low)
            : parseFloat(item.low),
          c: msgHLCO.data?.close
            ? parseFloat(msgHLCO.data.close)
            : parseFloat(item.close),
        };
      }
      return {
        x: new Date(item.trade_date).getTime(),
        o: parseFloat(item.open),
        h: parseFloat(item.high),
        l: parseFloat(item.low),
        c: parseFloat(item.close),
      };
    });
  }, [
    spreadHLCO.data?.price,
    spreadsSymbolRef.current,
    spreadSymbols,
    contracts,
    msgHLCO,
  ]);

  const minDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.min(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  const maxDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.max(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  useEffect(() => {
    if (minDate && maxDate) {
      setSliderValues([minDate, maxDate]);
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    setRoots([CropToCode[crop as keyof typeof CropToCode]]);
  }, [crop]);

  useEffect(() => {
    if (contracts) {
      const newSymbol = contracts
        .split(' - ')
        .map((item: string) => item.split(' ')[0])
        .join('-');
      spreadsSymbolRef.current = newSymbol;
    }
  }, [crop, contracts]);

  const filteredPriceChartData = useMemo(() => {
    return priceChartData.filter(
      (item: any) => item.x >= sliderValues[0] && item.x <= sliderValues[1]
    );
  }, [priceChartData, sliderValues, spreadsSymbolRef.current]);

  const data: any = {
    datasets: [
      {
        backgroundColors: {
          up: '#80bc00',
          down: '#eab208',
          unchanged: 'rgba(143, 143, 143, 1)',
        },
        borderColors: {
          up: '#80bc00',
          down: '#eab208',
          unchanged: 'rgba(143, 143, 143, 1)',
        },
        label: 'Spread HLCO',
        data: filteredPriceChartData,
        type: 'candlestick',
        borderColor: '#00c0ef',
        backgroundColor: 'rgba(0, 192, 239, 0.2)',
      },
    ],
  };

  const currentContractDetails = useMemo(() => {
    const spreadsContract = spreads?.data?.data?.find((item: any) => {
      return item.symbol === spreadsSymbolRef.current;
    });
    if (typeof spreadsContract?.change === 'string') {
      spreadsContract.change = parseFloat(spreadsContract.change).toFixed(3).toString();
    }
    return {
      bid: spreadsContract?.bid,
      ask: spreadsContract?.ask,
      change: spreadsContract?.change,
      last: spreadsContract?.last,
      symbol: spreadsContract?.symbol,
      last_trade_time: spreadsContract?.last_trade_time,
    };
  }, [spreads?.data?.data]);

  const [updatedContract, setUpdatedContract] = useState<any>(null);

  useEffect(() => {
    if (
      msgBidAsk &&
      msgBidAsk.data?.symbol === currentContractDetails.symbol &&
      (!currentContractDetails.last_trade_time ||
        msgBidAsk.data?.last_trade_time >
          currentContractDetails.last_trade_time)
    ) {
      if (typeof msgBidAsk.data?.change === 'string') {
        msgBidAsk.data.change = parseFloat(msgBidAsk.data?.change).toFixed(3).toString();
      }
      const tmpUpdateContract = {
        ...currentContractDetails,
        bid: msgBidAsk.data?.bid
          ? msgBidAsk.data?.bid
          : currentContractDetails.bid,
        ask: msgBidAsk.data?.ask
          ? msgBidAsk.data?.ask
          : currentContractDetails.ask,
        change: msgBidAsk.data?.change,
        last: msgBidAsk.data?.trade_price,
        last_trade_time: msgBidAsk.data?.last_trade_time,
      };
      setUpdatedContract(tmpUpdateContract);
    } else {
      setUpdatedContract(currentContractDetails);
    }
  }, [msgBidAsk.data, currentContractDetails]);

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <p className="text-lg pb-2 text-darkGray">Contract</p>
          <div className="flex pb-6 items-center justify-between text-lightGray rounded-md">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  options={commodityOptions}
                  name="crop"
                  textClassName="text-xl text-base-bold"
                />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                {spreadSymbols && spreadSymbols.length > 0 && (
                  <Select
                    textClassName="text-xl text-base-bold"
                    options={spreadSymbols ?? [{ label: '', value: '' }]}
                    defaultValue={spreadSymbols[0] ?? { value: '', label: '' }}
                    name="contracts"
                  />
                )}
              </div>
            </div>
            <div className="flex items-center space-x-8">
              <div
                className={`${parseFloat(updatedContract?.change) > 0 ? 'text-primary' : parseFloat(updatedContract?.change) === 0 ? user?.network_partner === 'Heartland' ? 'text-black' : 'text-white' : 'text-red'} flex items-center space-x-2`}
              >
                <span className="text-2xl text-base-bold">
                  ${updatedContract?.last ?? '0'}
                </span>
                <div className="flex items-center space-x-1">
                  {updatedContract?.change &&
                  parseFloat(updatedContract?.change) > 0 ? (
                    <ArrowUpIcon className="w-4 h-4" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4" />
                  )}
                  <span className="text-xl text-base-bold">
                    ${updatedContract?.change ?? '0'} (
                    {(
                      (100 * parseFloat(updatedContract?.change ?? '0')) /
                      parseFloat(updatedContract?.last ?? '1')
                    ).toFixed(2)}
                    %)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
      <div className="card relative overflow-hidden">
        <div className=" flex flex-col mb-4">
          <Chart
            type="candlestick"
            data={data}
            options={spreadsChartOptions(spreadsSymbolRef.current)}
          />
          <div className="w-full mb-4 pl-14 border-0 h-full">
            {priceChartData && priceChartData.length > 0 && (
              <div className="pr-2">
                <ReactSlider
                  className="horizontal-slider2"
                  thumbClassName="example-thumb2"
                  trackClassName="example-track2"
                  min={minDate}
                  max={maxDate}
                  defaultValue={[minDate, maxDate]}
                  value={sliderValues}
                  ariaValuetext={(state) =>
                    `Thumb value ${new Date(state.valueNow).toLocaleDateString()}`
                  }
                  renderThumb={(props, state) => <div {...props}>{}</div>}
                  pearling
                  minDistance={10}
                  onChange={(values) =>
                    setSliderValues(values as [number, number])
                  }
                />
                <div className="w-full h-full text-gray mt-10 flex items-center justify-center">
                  <span>{new Date(sliderValues[0]).toDateString()}</span>&nbsp;
                  - &nbsp;
                  <span>{new Date(sliderValues[1]).toDateString()}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-4 relative overflow-hidden">
        <TableLayout
          title={`All ${crop} Spreads`}
          icon={commodityOptions.find((item: any) => crop === item.value)?.icon}
        >
          <Table
            configs={spreadTableConfigs}
            data={tableData}
            loading={spreads.isLoading}
            highlightCells={highlightedCells}
            indexKey="contract"
            pagination={{
              size: 20,
            }}
          />
        </TableLayout>
      </div>
    </>
  );
}

export default SpreadsTable;
