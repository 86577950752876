import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { theme } from 'twin.macro';
import CaretDownIcon from '../../../../assets/icons/CaretDownIcon';
import { useControlledUser } from '../../../../contexts/auth-context';
import { NavItemType, UserProfileRes } from '../../../../types';
import { fillColors } from '../../../../utils';

interface NavItemProps {
  data: NavItemType;
}

export const getHref = (href: string, controlledUser?: UserProfileRes) => {
  if (controlledUser) {
    return `/user/${controlledUser.id}${href}`;
  }
  return href;
};

function NavItemWithChildren({
  data,
  subItems,
}: {
  data: NavItemType;
  subItems: NavItemType[];
}) {
  const controlledUser = useControlledUser();
  const { title, icon: Icon, href } = data;
  const [hover, setHover] = useState(false);

  return (
    <Menu>
      <NavLink to={getHref(href, controlledUser)}>
        {({ isActive }) => {
          return (
            <MenuButton
              className={clsx(
                'navButton group/navItem flex space-x-2 items-stretch text-md-bold',
                'text-base-900',
                'group-[.active]/navItem:text-base-000',
                { active: isActive }
              )}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {() => (
                <>
                  <div
                    className={clsx(
                      'w-[3px] h-[full] bg-base-000 rounded-xl group-hover/navItem:opacity-100',
                      'group-[.active]:opacity-100',
                      'opacity-0 flex-grow',
                      'group-data-[theme=Heartland]/root:bg-primary'
                    )}
                  ></div>
                  {Icon && (
                    <Icon
                      fill={
                        hover || isActive
                          ? fillColors(controlledUser?.network_partner).active
                          : fillColors(controlledUser?.network_partner)
                              ?.inactive
                      }
                    />
                  )}
                  <span
                    className={clsx(
                      'navText',
                      'hidden',
                      'lg:flex lg:items-center lg:justify-between group-hover/navItem:text-base-000',
                      isActive && 'text-base-000'
                    )}
                  >
                    <p className="mr-2">{title}</p>
                    <CaretDownIcon
                      fill={
                        hover || isActive
                          ? fillColors?.(controlledUser?.network_partner)
                              ?.active
                          : fillColors?.(controlledUser?.network_partner)
                              ?.inactive
                      }
                    />
                  </span>
                </>
              )}
            </MenuButton>
          );
        }}
      </NavLink>

      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom start"
          className={clsx(
            'menuItems origin-top-right rounded-xl p-4 pr-6 pb-0 mt-3',
            'bg-block-fill text-base-500 shadow-xl whitespace-nowrap'
          )}
        >
          {subItems.map(({ icon: Icon, ...item }) => (
            <MenuItem key={item.title}>
              {({ focus }) => (
                <NavLink to={getHref(item.href, controlledUser)} end>
                  {({ isActive }) => (
                    <button
                      className={clsx(
                        'flex w-full items-center gap-2 rounded-lg mb-4',
                        isActive &&
                          'text-base-000 group-data-[theme=Heartland]/root:text-primary',
                        'hover:!text-primary'
                      )}
                    >
                      {Icon && (
                        <Icon
                          fill={
                            focus
                              ? theme`colors.primary`
                              : isActive
                                ? fillColors?.(controlledUser?.network_partner)
                                    ?.active
                                : fillColors?.(controlledUser?.network_partner)
                                    ?.inactive
                          }
                        />
                      )}
                      {item.title}
                    </button>
                  )}
                </NavLink>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
}
function NavItem({
  data: { href, title, icon: Icon, children },
}: NavItemProps) {
  const controlledUser = useControlledUser();
  const [hover, setHover] = useState(false);

  return !children ? (
    <NavLink
      to={getHref(href, controlledUser)}
      className="group/navItem navItem"
    >
      {({ isActive }) => (
        <button
          className={clsx(
            'navButton flex space-x-2 items-stretch text-md-bold',
            'text-base-900',
            'group-[.active]/navItem:text-base-000',
            { active: isActive }
          )}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div
            className={clsx(
              'w-[3px] h-[full] group-hover/navItem:bg-base-000 rounded-xl group-hover/navItem:opacity-100',
              'group-[.active]/navItem:opacity-100',
              'opacity-0',
              'group-data-[theme=Heartland]/root:bg-primary'
            )}
          ></div>
          {Icon && (
            <Icon
              className="transition-all"
              fill={
                hover || isActive
                  ? fillColors?.(controlledUser?.network_partner)?.active
                  : fillColors?.(controlledUser?.network_partner)?.inactive
              }
            />
          )}
          <span
            className={clsx(
              'navText',
              'hidden',
              'lg:block',
              'group-[.active]/navItem:text-base-000 group-hover/navItem:text-base-000'
            )}
          >
            {title}
          </span>
        </button>
      )}
    </NavLink>
  ) : (
    <NavItemWithChildren
      data={{ href, title, icon: Icon }}
      subItems={children}
    />
  );
}

export default NavItem;
