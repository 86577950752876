import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { FertilizerType } from '.';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import { useDeletePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import { useCreateNote, useGetNotes } from '../../../../hooks/note/use-note';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { convertToFloat } from '../../../../utils';
import Checkbox from '../../../commons/Checkbox';
import Select from '../../../commons/Select';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

interface FertilizerListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionWithProducts[];
  onSelect: (zone: any) => void;
  fertilizer: FertilizerType;
  loading: boolean;
  setIsEditing: (value: boolean) => void;
  setSelectedProductByZone: (zone: string, value: any) => void;
  selectedProductByZone: Record<string, PrescriptionProductRes>;
  crop: string;
  inputType: 'fertilizer' | 'micronutrients';
}

export default function FertilizerList({
  fertilizer,
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
  setSelectedProductByZone,
  selectedProductByZone,
  crop,
  inputType,
}: FertilizerListProps) {
  const user = useUser();
  const year = useYearFilter();
  const mainUser = useMainUser();
  const { mutate: deleteFertilizer, isPending: isDeleting } =
    useDeletePrescriptionProduct();
  const { mutate: createNote } = useCreateNote();

  const { data: notes, isFetching: isFetchingNotes } = useGetNotes({
    userId: user?.id || '',
    year: year || '',
    type: inputType,
    crop: crop,
  });

  const enhancedData = useMemo(() => {
    return data.map((item) => {
      let yield_per_pound = 0;
      const recommended_fert = `recommended_${fertilizer.id.toLowerCase()}_rate`;
      const value = parseFloat(item[recommended_fert]);
      let zone_yield = parseFloat(item.user_adjusted_yield ?? item.zone_yield);
      if (!isNaN(value) && value !== 0) {
        yield_per_pound = zone_yield / value;
      }

      return {
        ...item,
        needed_rate: convertToFloat(
          item[`${fertilizer.id.toLowerCase()}_rate`] ||
            item[`recommended_${fertilizer.id.toLowerCase()}_rate`]
        ),
        yield_per_pound,
        note: notes?.find(
          (note) =>
            note.agrivar_zone === item.agrivar_zone &&
            note.category === fertilizer.name.toLowerCase()
        )?.note,
        product: {
          cost_per_acre: convertToFloat(
            selectedProductByZone[item.agrivar_zone]?.cost_per_acre
          ),
          location: selectedProductByZone[item.agrivar_zone]?.location,
        },
      };
    });
  }, [data, fertilizer.id, fertilizer.name, notes, selectedProductByZone]);

  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: 'Select Zone',
        key: 'id',
        type: 'element',
        element: (item: FieldPrescriptionRes) => (
          <Checkbox
            name="id"
            label={`Zone ${item.agrivar_zone1}`}
            checked={selectedZone.agrivar_zone === item.agrivar_zone}
            onChange={(checked: boolean) =>
              checked && onSelect(item.agrivar_zone)
            }
            rounded
          />
        ),
      },
      { name: 'Acres', key: 'area', unit: 'acres' },
      {
        name: `Residual ${fertilizer.id} lbs.`,
        key: `${fertilizer.id}1_lb`,
        type: 'number',
      },
      {
        name: `${fertilizer.id} lbs. Needed`,
        key: `needed_rate`,
        type: 'number',
        subTotal: true,
      },
      {
        name: `Historical Average ${fertilizer.id} lbs. Applied`,
        key: `average_${fertilizer.id.toLowerCase()}_rate`,
        type: 'number',
        emptyValue: 0,
      },
      {
        name: `Yield (Bu) Per Pound of ${fertilizer.id}`,
        key: `yield_per_pound`,
        type: 'number',
        emptyValue: 0,
      },
      {
        name: 'Location',
        key: 'product.location',
      },
      {
        name: 'Cost Per Acre',
        key: 'product.cost_per_acre',
        type: 'currency',
        emptyValue: 0,
      },
      {
        name: 'Fertilizer',
        key: 'product',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const options = item.products
            ?.filter((fert) => {
              return (
                !isEmpty(fert?.[fertilizer.name.toLowerCase()]) ||
                fert.prescription_type === fertilizer.name.toLowerCase()
              );
            })
            ?.map((fert) => ({
              value: fert.product,
              label: `${fert.application_time} ${fert.product}`,
            }));
          if (!options.length) return <></>;
          return (
            <Select
              key={JSON.stringify(options)}
              className="w-52"
              name="product"
              options={options}
              onChange={(e) =>
                setSelectedProductByZone(
                  item.agrivar_zone,
                  item.products?.find(
                    (fert) => fert.product === e.target.value
                  ) as PrescriptionProductRes
                )
              }
            />
          );
        },
      },
    ],
  };

  const rowActions: RowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionWithProducts) => {
      deleteFertilizer({
        uniqueId: selectedProductByZone[item.agrivar_zone].uniqueid,
        userId: user?.id ?? '',
        year: year ?? '',
        zone: item.agrivar_zone,
      });
    },
    note: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: inputType,
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: [item.agrivar_zone],
            category: fertilizer.name.toLowerCase(),
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note', { variant: 'error' });
            },
          }
        );
      },
      onSaveAll: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: inputType,
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: data.map((zone) => zone.agrivar_zone),
            category: fertilizer.name.toLowerCase(),
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added to all zones successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note to all zones', {
                variant: 'error',
              });
            },
          }
        );
      },
      title: 'Add Note',
    },
  };

  return (
    <TableLayout title={`${fertilizer.name}`}>
      <Table
        configs={tableConfigs || {}}
        data={enhancedData}
        loading={loading || isDeleting || isFetchingNotes}
        rowActions={rowActions}
      />
    </TableLayout>
  );
}
