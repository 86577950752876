import { Field, Label } from '@headlessui/react';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import ApprovedIcon from '../../../assets/icons/ApprovedIcon';
import WarningIcon from '../../../assets/icons/WarningIcon';
import Checkbox from '../../../components/commons/Checkbox';
import Select from '../../../components/commons/Select';
import Table, { TableConfigs } from '../../commons/table/Table';
import TableLayout from '../../../components/dashboard/commons/TableLayout';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useDeleteFieldPolygon,
  useDuplicateFieldPolygon,
} from '../../../hooks/field-polygon/use-field-polygon';
import { EnhancedFieldPolygon } from '../../../types';
import { getYearOptions } from '../../../utils';
import EditFIBForm from './EditFIBForm';
import { FeatureData } from './helpers';
import { theme } from 'twin.macro';

interface FIBListProps {
  data: EnhancedFieldPolygon[];
  loading: boolean;
  checkedField?: EnhancedFieldPolygon[];
  onCheck: (fields: EnhancedFieldPolygon[]) => void;
  selectedField?: EnhancedFieldPolygon;
  onSelect: (field?: EnhancedFieldPolygon) => void;
  onDeleteField?: (user_id_field_index: string) => void;
  activeFeatureData?: FeatureData;
  isFetchingFeatureData?: boolean;
  onCancel: () => void;
  afterSubmit?: () => void;
}

export default function FIBList({
  data,
  loading,
  checkedField = [],
  onCheck,
  onSelect,
  selectedField,
  onDeleteField,
  activeFeatureData,
  isFetchingFeatureData,
  onCancel,
  afterSubmit,
}: FIBListProps) {
  const user = useUser();
  const selectedYear = useYearFilter();
  const {
    mutate,
    data: duplicateRes,
    variables,
    isSuccess,
  } = useDuplicateFieldPolygon();
  const {
    mutate: deleteField,
    isSuccess: isDeleteSuccess,
    variables: deleteVars,
  } = useDeleteFieldPolygon();

  // Handle delete field response
  useEffect(() => {
    if (isDeleteSuccess) {
      onDeleteField?.(deleteVars?.user_id_field_index);
    }
  }, [isDeleteSuccess]);

  // Handle duplicate field response
  useEffect(() => {
    if (isSuccess) {
      const res = duplicateRes?.data;
      if (res?.duplicate_success_fields?.length > 0) {
        enqueueSnackbar(
          <ul className="list-disc">
            <p>Duplicated successfully:</p>
            {res?.duplicate_success_fields.map((item: string) => (
              <li className="ml-4">{item}</li>
            ))}
          </ul>,
          { variant: 'success' }
        );
      }

      if (res?.duplicate_error_fields?.length > 0) {
        enqueueSnackbar(
          <ul className="list-disc">
            <p>Fields already existed in {variables?.new_year}: </p>
            {res?.duplicate_error_fields.map((item: string) => (
              <li className="ml-4">{item}</li>
            ))}
          </ul>,
          { variant: 'warning' }
        );
      }
    }
  }, [duplicateRes, isSuccess, variables?.new_year]);

  const [openEditForm, setOpenEditForm] = useState<
    EnhancedFieldPolygon | undefined
  >();
  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        { name: 'Field Name', key: 'field' },
        { name: 'Crop', key: 'crop' },
        { name: 'Year', key: 'crop_year', width: 'w-0' },
        {
          name: 'Bound',
          key: 'hasBound',
          type: 'element',
          width: 'w-0',
          element: (item: EnhancedFieldPolygon) => (
            <span className="flex justify-center">
              {item?.hasBound ? <ApprovedIcon /> : <WarningIcon />}
            </span>
          ),
        },
      ],
    };
  }, []);

  const rowActions = {
    edit: {
      onSelect: (item: EnhancedFieldPolygon) => {
        onSelect(item);
        setOpenEditForm(item);
      },
    },
    delete: (item: EnhancedFieldPolygon) =>
      deleteField({
        user_id_field_index: item.user_id_field_index,
        crop_year: item.crop_year,
      }),
    close: () => console.log('Close'),
  };

  const handleAddFieldToDiffYear = (year: string) => {
    if (user?.id && selectedYear) {
      mutate({
        user_id: user.id,
        new_year: year,
        old_year: selectedYear,
        user_id_field_index: '',
        user_id_field_index_list: checkedField.map(
          (f) => f.user_id_field_index
        ),
      });
    }
  };

  useEffect(() => {
    setOpenEditForm(selectedField);
  }, [selectedField]);

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="card">
      <TableLayout
        title="Field Information & Boundaries"
        tooltip="demo"
        extraActions={
          <div className="p-1">
            <Select
              name="cropYear"
              options={[
                { value: '', label: 'Add Field To Different Crop Year' },
                ...getYearOptions(),
              ]}
              onChange={(e) => handleAddFieldToDiffYear(e.target.value)}
              className="tertiaryBtn"
              iconColor={
                user?.network_partner === 'Heartland'
                  ? theme`colors.base.000`
                  : theme`colors.base.500`
              }
            />
          </div>
        }
      >
        {openEditForm && (
          <EditFIBForm
            key={openEditForm?.field}
            onCancel={handleCancel}
            data={openEditForm}
            activeFeatureData={activeFeatureData}
            isFetchingFeatureData={isFetchingFeatureData}
            afterSubmit={afterSubmit}
          />
        )}

        <Table
          loading={loading}
          title={{ name: 'All Fields', tooltip: 'demo' }}
          pagination={{ size: 10 }}
          configs={tableConfigs}
          data={data}
          rowActions={rowActions}
          tableActions={
            <Field className="flex space-x-2 items-center">
              <Label>Select All</Label>
              <Checkbox
                name="selectAll"
                onChange={(checked) => (checked ? onCheck(data) : onCheck([]))}
              />
            </Field>
          }
          indexKey="user_id_field_index"
          checkedRows={checkedField?.map((f) => f?.user_id_field_index)}
          onRowCheck={(item: EnhancedFieldPolygon, checked) => {
            if (checked) {
              onCheck([...checkedField, item]);
            } else {
              onCheck(
                checkedField?.filter(
                  (f) => f.user_id_field_index !== item.user_id_field_index
                )
              );
            }
          }}
          highlights={[selectedField?.user_id_field_index || '']}
          onRowClick={onSelect}
        />
      </TableLayout>
    </div>
  );
}
