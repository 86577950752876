import React, { SetStateAction, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import {
  CropToCode,
  MonthToCode,
  ThemeColors,
  cropToMonthOptions,
  yearOptions,
} from '../../../constants';
import {
  optionTypeOptions,
  watchTypeOptions,
} from '../../../constants/selectCommonConfigs';
import { useUser } from '../../../contexts/auth-context';
import { useCompanyLocationOptions } from '../../../hooks/basis/use-basis';
import { useStrikePrices } from '../../../hooks/market/use-options';
import { useCreateWatchList } from '../../../hooks/market/use-watch-list';
import { WatchListItemPayload } from '../../../types';
import Button from '../../commons/Button';
import Select from '../../commons/Select';
import { commodityOptions } from './PriceChart';

const AddToWatchList = ({
  setIsCreatingWatchItem,
}: {
  setIsCreatingWatchItem: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const { mutate: createWatchListItem } = useCreateWatchList();
  const queryClient = useQueryClient();
  const user = useUser();
  const crop = watch('crop', 'Corn');
  const type = watch('type', 'cash');
  const month = watch('month', 'december');
  const year = watch('year', '2024');
  const optionType = watch('optionType', 'Call');

  const underlying = useMemo(() => {
    return CropToCode[crop] + MonthToCode[month] + year.slice(3);
  }, [crop, month, year]);

  const strikePrices = useStrikePrices(underlying);
  const companyLocationOptions = useCompanyLocationOptions(
    user?.id!,
    year,
    underlying
  );

  const onSubmit = (data: any) => {
    const date = new Date(Date.parse(data.month + ' 1, 2020')); // Using any non-leap year
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');
    const crop = data.crop.charAt(0).toUpperCase() + data.crop.slice(1);
    const payload: WatchListItemPayload = {
      date: `${data.year}-${monthNumber}`,
      crop,
      type: type,
      userId: user?.id ?? '',
    };

    if (data.strikePrice) {
      payload.strikePrice = data.strikePrice;
    }
    if (data.optionType) {
      payload.optionType = data.optionType;
    }

    if (data.basisLocation) {
      payload.basisLocation = data.basisLocation.split(' & ')[1];
      payload.basisCompany = data.basisLocation.split(' & ')[0];
    }
    if (data.month1) {
      payload.month1 = data.month1;
    }
    if (data.year1) {
      payload.year1 = data.year1;
    }
    if (data.month2) {
      payload.month2 = data.month2;
    }
    if (data.year2) {
      payload.year2 = data.year2;
    }
    if (
      type === 'spread' &&
      data.month1 &&
      data.year1 &&
      data.month2 &&
      data.year2
    ) {
      // compute month number for month1 and month2
      const month1 = new Date(Date.parse(data.month1 + ' 1, 2020')); // Using any non-leap year
      const monthNumber1 = (month1.getMonth() + 1).toString().padStart(2, '0');
      const month2 = new Date(Date.parse(data.month2 + ' 1, 2020')); // Using any non-leap year
      const monthNumber2 = (month2.getMonth() + 1).toString().padStart(2, '0');
      payload.date = `${data.year1}-${monthNumber1} ${data.year2}-${monthNumber2}`;
    }

    console.log('payload:', payload);
    console.log('data:', data);

    createWatchListItem(payload, {
      onSuccess: () => {
        // Invalidate and refetch alerts
        queryClient.invalidateQueries([
          'market/alerts',
          user?.id ?? '',
        ] as InvalidateQueryFilters);
        alert('Item added to watch list');
        setIsCreatingWatchItem(false);
      },
      onError: (error: any) => {
        console.error('Error creating alert:', error);
        alert(
          'Failed to create watch list item. Please enter a valid contract.'
        );
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="card p-6 space-y-4">
        <div className="flex items-center space-x-2">
          <CalendarDaysIcon className="h-6 w-6 mr-2" />
          <h2 className="text-xl text-base-bold">Add To Watch List</h2>
        </div>

        <div className="w-full">
          <label htmlFor="type" className="block mb-2">
            Type
          </label>
          <Select name="type" options={watchTypeOptions} />
        </div>

        {type === 'spread' ? (
          <>
            <div className="grid grid-cols-3 gap-x-2 w-full flex">
              <div className="w-full">
                <label htmlFor="crop" className="block mb-2">
                  Commodity
                </label>
                <Select name="crop" options={commodityOptions} />
              </div>
              <div className="w-full">
                <label htmlFor="month1" className="block mb-2">
                  Month 1
                </label>
                <Select
                  name="month1"
                  defaultValue="december"
                  options={
                    cropToMonthOptions[
                      crop as keyof typeof cropToMonthOptions
                    ] || cropToMonthOptions['Corn']
                  }
                />
              </div>
              <div className="w-full">
                <label htmlFor="year1" className="block mb-2">
                  Year 1
                </label>
                <Select
                  name="year1"
                  defaultValue="2024"
                  options={yearOptions}
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="month2" className="block mb-2">
                  Second Month
                </label>
                <Select
                  name="month2"
                  defaultValue="december"
                  options={
                    cropToMonthOptions[
                      crop as keyof typeof cropToMonthOptions
                    ] || cropToMonthOptions['Corn']
                  }
                />
              </div>
              <div>
                <label htmlFor="year2" className="block mb-2">
                  Second Year
                </label>
                <Select
                  name="year2"
                  defaultValue="2024"
                  options={yearOptions}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label htmlFor="crop" className="block mb-2">
                Commodity
              </label>
              <Select name="crop" options={commodityOptions} />
            </div>
            <div>
              <label htmlFor="month" className="block mb-2">
                Month
              </label>
              <Select
                name="month"
                defaultValue="december"
                options={
                  cropToMonthOptions[crop as keyof typeof cropToMonthOptions] ||
                  cropToMonthOptions['Corn']
                }
              />
            </div>
            <div>
              <label htmlFor="year" className="block mb-2">
                Year
              </label>
              <Select name="year" defaultValue="2024" options={yearOptions} />
            </div>
          </div>
        )}

        {type === 'options' && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="optionType" className="block mb-2">
                  Option Type
                </label>
                <Select name="optionType" options={optionTypeOptions} />
              </div>
              <div className="w-full">
                <label htmlFor="strikePrice" className="block mb-2">
                  Strike Price
                </label>
                {strikePrices &&
                  strikePrices?.data &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ] &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ].length > 0 && (
                    <Select
                      name="strikePrice"
                      options={
                        strikePrices.data?.strikePrices[
                          optionType.slice(0, 1).toUpperCase() +
                            optionType.slice(1, optionType.length)
                        ]!
                      }
                    />
                  )}
              </div>
            </div>
          </>
        )}

        {(type === 'basis' || type === 'cash') && (
          <div className="w-full gap-4">
            <div>
              <label htmlFor="basisLocation" className="block mb-2">
                Company & Location
              </label>
              <Select name="basisLocation" options={companyLocationOptions} />
            </div>
          </div>
        )}

        <div className="flex space-x-4">
          <Button
            type="button"
            onClick={() => setIsCreatingWatchItem(false)}
            className={`bg-[${ThemeColors.lightGray}]`}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Apply
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddToWatchList;
