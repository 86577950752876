import { IconProps } from '../../types';

export default function CaretDownIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.22601L7.5 7.22601L2 2.22601"
        stroke={fill}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
