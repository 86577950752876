import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import AgrivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import StyledCard from '../../../components/main/StyledCard';
import { PasswordRegex } from '../../../constants';
import { useResetPassword } from '../../../hooks/auth/use–reset-password';
import { useCountdown } from '../../../hooks/use-count-down';

const validateSchema = yup.object({
  email: yup.string().email().required().label('Email'),
  confirmationCode: yup.string().required().label('Confirmation Code'),
  newPassword: yup
    .string()
    .min(8, 'Invalid password')
    .matches(PasswordRegex, 'Invalid password')
    .required()
    .label('Password'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword')], 'Password not match')
    .label('Confirm Password'),
});

export default function ResetPasswordPage() {
  const { resetPassword, confirmResetPassword, isLoading } = useResetPassword();
  const [codeSent, setCodeSent] = useState(false);
  const { countdown, startCountdown } = useCountdown();

  const form = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validateSchema),
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
  } = form;

  const handleResetPassword = async ({ email }: { email: string }) => {
    startCountdown();
    const res = await resetPassword(email);
    if (res?.nextStep) {
      if (
        res.nextStep?.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE'
      ) {
        setCodeSent(true);
      }
    }
  };

  const handleConfirmResetPassword = async ({
    confirmationCode,
    newPassword,
    email,
  }: {
    confirmationCode: string;
    newPassword: string;
    email: string;
  }) => {
    await confirmResetPassword({
      username: email,
      confirmationCode,
      newPassword,
    });
  };

  const disabled = !isValid;

  return (
    <FormProvider {...form}>
      <div className="flex flex-col justify-center items-center p-4">
        <div className="max-w-screen-sm w-full">
          <img
            src={AgrivarLogo}
            alt="Agrivar"
            width={'70%'}
            className="mx-auto mb-4"
          />
          <StyledCard title="Reset Password">
            <form
              className="max-w-screen-sm flex flex-col w-full space-y-8"
              onSubmit={handleSubmit(handleConfirmResetPassword)}
            >
              <div className="space-y-5">
                <TextInput
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  className="!bg-base-000 !text-block-fill"
                  required
                />
                {codeSent && (
                  <>
                    <div>
                      <TextInput
                        label="Code"
                        name="confirmationCode"
                        placeholder="Enter Confirm Code"
                        className="!bg-base-000 !text-block-fill"
                        required
                        autoComplete="off"
                      />
                      <button
                        type="button"
                        className="float-end disabled:opacity-50 mt-1"
                        disabled={countdown > 0}
                        onClick={() =>
                          handleResetPassword({ email: form.watch('email') })
                        }
                      >
                        Resend {countdown > 0 && <>({countdown})</>}
                      </button>
                    </div>
                    <TextInput
                      label="New Password"
                      name="newPassword"
                      placeholder="Enter Password"
                      type="password"
                      className="!bg-base-000 !text-block-fill"
                      required
                      autoComplete="new-password"
                    />
                    <TextInput
                      label="Confirm New Password"
                      name="confirmPassword"
                      placeholder="Enter Confirm Password"
                      type="password"
                      className="!bg-base-000 !text-block-fill"
                      required
                      autoComplete="new-password"
                    />
                    {errors.newPassword && (
                      <div className="text-base-100">
                        Password must at least:
                        <ul className="list-disc pl-4">
                          <li>8 characters long</li>
                          <li>One uppercase</li>
                          <li>One lowercase</li>
                          <li>One number</li>
                          <li>One special character (!@#$%^&*)</li>
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="flex flex-col space-y-5">
                {!codeSent && (
                  <Button
                    type="button"
                    disabled={isLoading}
                    color="primary"
                    loading={isLoading}
                    className="!font-normal !rounded-md"
                    size="lg"
                    onClick={() =>
                      handleResetPassword({ email: form.watch('email') })
                    }
                  >
                    Reset Password
                  </Button>
                )}
                {codeSent && (
                  <Button
                    type="submit"
                    disabled={disabled || isLoading}
                    color="primary"
                    loading={isLoading}
                    className="!font-normal !rounded-md"
                    size="lg"
                  >
                    Reset Password
                  </Button>
                )}
                <Link to="/">
                  <Button className="!font-normal !rounded-md" size="lg">
                    Login
                  </Button>
                </Link>
              </div>
            </form>
          </StyledCard>
        </div>
      </div>
    </FormProvider>
  );
}
