import apiClient from './api-client';

export const getTableData = async (
  userId: string,
  crop: string,
  type: string,
  level: string,
  year: string,
  date: string,
  path?: string
) => {
  // validate the input
  if (!userId || !crop || !type || !level || !year || !date) {
    console.error('calling Invalid input');
    return [];
  }
  const response = await apiClient.get(
    `/map?&userId=${userId}_${year}&crop=${crop}&type=${type}&level=${level}&year=${year}&date=${date}&path=${path}`
  );

  return response.data;
};

export const getUserStudies = async (userId: string, year: string) => {
  const response = await apiClient.get(
    `/map/custom-study?userId=${userId}&year=${year}`
  );
  return response.data;
};

export const getLineChartApi = async (
  userId: string,
  year: string,
  crop: string,
  type: string,
  selectedFieldIndex: string,
  tab: string,
  zone: string
) => {
  const response = await apiClient.get(
    `/map/line-chart?userId=${userId}&year=${year}&crop=${crop}&type=${type}&selectedFieldIndex=${selectedFieldIndex}&tab=${tab}&zone=${zone}`
  );
  return response.data;
};

export const createStudyApi = async (payload: any) => {
  const response = await apiClient.post(`/map/custom-study`, payload);
  return response.data;
};

export const getUserCropYearsApi = async (userId: string, type: string) => {
  const response = await apiClient.get(
    `/history-insights/years?bucket=agrivar.latest.yields3&prefix=${userId}_${type}&path=map`
  );
  return response.data;
};

export const updateStudyApi = async (payload: any) => {
  const response = await apiClient.post(`/map/custom-study`, {
    ...payload,
    func: 'update',
  });
  return response.data;
};

export const getStudyNoteApi = async (pk: string, xIndex: string) => {
  const response = await apiClient.get(
    `/map/custom-study?pk=${pk}&xIndex=${xIndex}&type=note`
  );
  return response.data;
};

export const createNoteApi = async (payload: any) => {
  const response = await apiClient.post(`/map/custom-study`, {
    ...payload,
    func: 'create_note',
    pk: payload.user_id_type_crop_year,
    note: payload.note,
  });
  return response.data;
};

export const deleteStudyApi = async (payload: any) => {
  const response = await apiClient.post(`/map/custom-study`, {
    ...payload,
    func: 'delete',
  });
  return response.data;
};
