import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createNoteApi, getNotesApi } from '../../api/note-api';
import { CreateNotePayload, GetNotesParams, NoteItem } from '../../types/note';

export const useGetNotes = (params: GetNotesParams) => {
  const { data, ...rest } = useQuery({
    queryKey: ['notes', ...Object.values(params).map((value) => value)],
    queryFn: () => getNotesApi(params),
  });

  return {
    data: data?.data as NoteItem[],
    ...rest,
  };
};

export const useCreateNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['CREATE', 'note'],
    mutationFn: (payload: CreateNotePayload) => createNoteApi(payload),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
      queryClient.invalidateQueries({
        queryKey: ['field/prescription/history'],
      });
    },
  });
};
