import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface CardHeaderProps {
  title?: string | ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
  tooltip?: string;
  link?: string;
  icon?: React.ReactNode;
  className?: string;
}

function CardHeader({
  title,
  subtitle,
  tooltip,
  children,
  link,
  icon,
  className,
}: CardHeaderProps) {
  return (
    <header
      className={clsx('flex items-center justify-between w-full', className)}
    >
      <div className="flex items-center space-x-1">
        {link ? (
          <Link to={link} className="flex items-center space-x-2">
            {icon}
            {typeof title === 'string' ? (
              <h4 className="display-sm-bold cardHeader">{title}</h4>
            ) : (
              <span className="display-sm-bold cardHeader">{title}</span>
            )}
          </Link>
        ) : (
          <div className="flex items-center space-x-2">
            {icon}
            {typeof title === 'string' ? (
              <h4 className="display-sm-bold cardHeader">{title}</h4>
            ) : (
              <span className="display-sm-bold cardHeader">{title}</span>
            )}
          </div>
        )}
        {tooltip && <InformationCircleIcon className="size-6 fill-yellow" />}
      </div>
      {children}
      {subtitle && <p className="text-md-regular text-dimmed">{subtitle}</p>}
    </header>
  );
}

export default CardHeader;
