import apiClient from './api-client';

export const getBasisAPI = ({
  userId,
  year,
  symbols,
  crop,
}: {
  userId: string;
  year?: string;
  symbols?: string[];
  crop?: string;
}) => {
  const params = new URLSearchParams({
    userId,
    ...(year && { year }),
    ...(crop && { crop }),
    ...(symbols && { symbols: symbols.join(',') }),
  });
  return apiClient.get(`/basis?${params}`);
};
