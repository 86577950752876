import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FileUploadIcon from '../../../assets/icons/FileUploadIcon';
import FormEntryIcon from '../../../assets/icons/FormEntryIcon';
import PhotoUploadIcon from '../../../assets/icons/PhotoUploadIcon';
import { TextractType } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useTextract } from '../../../hooks/textract/use-textract';
import { uploadFile } from '../../../services/awsS3';
import Button from '../../commons/Button';
import CardHeader from '../../commons/CardHeader';
import ComboSelectInput from '../../commons/inputs/ComboSelectInput';
import RadioInput from '../../commons/inputs/RadioInput';
import TextInput from '../../commons/inputs/TextInput';
import { AwaitSelect } from '../../commons/Select';
import { useCreateInsurance } from '../../../hooks/insurance/use-insurance';

const SubmitOptions = [
  {
    value: 'formEntry',
    label: 'Form Entry',
    icon: FormEntryIcon,
  },
  {
    value: 'uploadFile',
    label: 'Upload File',
    icon: FileUploadIcon,
  },
  {
    value: 'uploadPhoto',
    label: 'Upload Photo',
    icon: PhotoUploadIcon,
  },
];

export default function AddCropInsuranceForm() {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });
  const [step, setStep] = useState('first');

  const submitOption = form.watch('submitOption');

  // Field options
  const { data: allFields, isFetching: isFetchingField } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: field.field,
    }));
  }, [allFields]);

  // Upload file
  const [fileUpload, setFileUpload] = useState({
    fileName: '',
    loading: false,
  });
  const [isTextracting, setIsTextracting] = useState(false);
  const uploading = fileUpload?.loading || isTextracting;

  // Textract
  const { mutateAsync: getTextract, data } = useTextract();
  useEffect(() => {
    (async () => {
      try {
        if (fileUpload.fileName && !isTextracting) {
          await getTextract({
            documentName: fileUpload.fileName,
            type: TextractType.INSURANCE,
          });
          setIsTextracting(false);
        }
      } catch (error) {
        console.error('Error getting Textract data:', error);
      }
    })();
  }, [data, fileUpload.fileName, isTextracting]);

  // Reference to the file input element
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const getResponse = (response: any) => {
      const { key: fileName } = response || {};
      setFileUpload({ fileName, loading: false });
      // Reset the file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = e.target.files?.[0];
    if (file) {
      const type = file.type.split('/')[1];
      const timeStamp = Date.now();
      const defaultFileName = `${user?.id}-${nanoid()}-${timeStamp}.${type}`;

      setFileUpload({ ...fileUpload, loading: true });
      uploadFile(file, defaultFileName, type, getResponse);
    }
  };

  // Handle form submission
  const totalPage = 1;
  const [remainPages, setRemainPages] = useState<number[]>(
    Array.from({ length: totalPage }, (_, i) => i + 1)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { mutateAsync: createInsurance, isPending } = useCreateInsurance();

  // Submit one insurance when multiple pages
  // Using the error handler of react-hook-form
  // this only called when the form is not valid
  // so able to submit the insurance even other pages are not filled
  // use this to avail the validation of react-hook-form
  const handleSubmitOne = async (errors: any) => {
    if (!user || !year) return;

    if (errors[`page${currentPage}`]) {
      enqueueSnackbar(
        'Please complete required informations for this insurance',
        {
          variant: 'warning',
        }
      );
      return;
    }

    const currentPageValues = form.getValues()[`page${currentPage}`];
    try {
      await createInsurance({
        userId: user.id,
        year,
        data: [...currentPageValues],
      });
      enqueueSnackbar('Insurance submitted successfully', {
        variant: 'success',
      });
      setRemainPages(remainPages.filter((page) => page !== currentPage));

      // Move to next page
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
      } else if (remainPages.length === 1) {
        setCurrentPage(remainPages[0]);
      }
    } catch (error) {
      enqueueSnackbar('Failed to submit insurance', { variant: 'error' });
    }
  };

  // Submit all insurances and single insurance (if only one page)
  // Using the handleSubmit of react-hook-form
  // This only called when all pages are filled/form is valid
  const handleSubmit = async (values: any) => {
    if (!user || !year) return;

    const payload = remainPages.map((page) => {
      const pageValues = values[`page${page}`];
      return pageValues;
    });

    try {
      await createInsurance({
        userId: user.id,
        year,
        data: payload,
      });
      enqueueSnackbar('All insurances submitted successfully', {
        variant: 'success',
      });
      setRemainPages([]);
    } catch (error) {
      enqueueSnackbar('Failed to submit insurance', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (remainPages.length === 0) {
      form.reset();
      setStep('first');
      setCurrentPage(1);
      setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
    }
  }, [remainPages.length]);

  useEffect(() => {
    setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
  }, [totalPage]);

  const isSubmitted = !remainPages.includes(currentPage);
  // Manual set field legal
  const field = form.watch(`page${currentPage}.fieldName`);
  useEffect(() => {
    if (!field) {
      form.setValue(`page${currentPage}.fieldName`, fieldOptions?.[0]?.value);
    }
  }, [field]);

  useEffect(() => {
    if (field) {
      const fieldData = allFields?.find((f) => f.field === field);
      if (fieldData) {
        form.setValue(
          `page${currentPage}.fieldLegal`,
          fieldData.legal_description
        );
      }
    }
  }, [field]);

  return (
    <div className="space-y-6">
      <CardHeader
        title="Add New Crop Insurance"
        subtitle={
          step === 'details' && totalPage > 1
            ? `Page ${currentPage} / ${totalPage}`
            : ''
        }
      />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(
            (values) => handleSubmit(values),
            (errors) => handleSubmitOne(errors)
          )}
        >
          {/* First Step */}
          <div className="space-y-5" hidden={step !== 'first'}>
            <p>How would you like to submit the insurance</p>
            {SubmitOptions.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name="submitOption"
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800"
              />
            ))}
            {submitOption === 'formEntry' && (
              <Button color="primary" onClick={() => setStep('details')}>
                Next
              </Button>
            )}
            {fileUpload.fileName}
            {(submitOption === 'uploadFile' ||
              submitOption === 'uploadPhoto') && (
              <Button color="primary" loading={uploading} className="relative">
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
                  onChange={handleUploadFile}
                  disabled={uploading}
                />
                Upload
              </Button>
            )}
          </div>

          {/* Details step */}
          <div
            className={clsx({
              hidden: step !== 'details',
            })}
          >
            {Array.from({ length: totalPage }).map((_, index) => (
              <fieldset
                key={`page${index + 1}`}
                className={clsx('space-y-5', {
                  hidden: currentPage !== index + 1,
                })}
              >
                <p className="text-secondary">* Indicates Required Field</p>
                <AwaitSelect
                  name={`page${index + 1}.fieldName`}
                  label="Field Name"
                  required
                  options={fieldOptions || []}
                  isLoading={isFetchingField}
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.fieldLegal`}
                  label="Field Legal"
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.crop`}
                  label="Commodity"
                  options={[]}
                  required
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.cropClass`}
                  label="Intended Use"
                  disabled={isSubmitted}
                  options={[]}
                  required
                />
                <ComboSelectInput
                  name={`page${index + 1}.irrigationPractice`}
                  label="Irrigation Practice"
                  disabled={isSubmitted}
                  options={[]}
                  required
                />
                <ComboSelectInput
                  name={`page${index + 1}.organicPractice`}
                  label="Organic Practice"
                  disabled={isSubmitted}
                  options={[]}
                  required
                />
                <ComboSelectInput
                  name={`page${index + 1}.insurancePlanType`}
                  label="Insurance Type"
                  disabled={isSubmitted}
                  options={[]}
                  required
                />
                <ComboSelectInput
                  name={`page${index + 1}.insuranceOptions`}
                  label="Insurance Options"
                  disabled={isSubmitted}
                  options={[]}
                  required
                />
                <TextInput name="acres" type="number" label="Acres" required />
                <TextInput
                  name={`page${index + 1}.approvedYield`}
                  type="number"
                  label="APH"
                  required
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.basePrice`}
                  type="number"
                  label="Base Price"
                  required
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.percentCoverage`}
                  type="number"
                  label="Coverage Percent"
                  required
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.premium`}
                  type="number"
                  label="Premium per Acre"
                  required
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.sharePercent`}
                  type="number"
                  label="Share Percent"
                  required
                  disabled={isSubmitted}
                />
              </fieldset>
            ))}

            <div className="space-y-5 mt-5">
              {totalPage > 1 && (
                <div className="flex space-x-4">
                  <Button
                    type="button"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  <div className="whitespace-nowrap rounded-lg bg-base-1000 flex justify-center items-center px-4 space-x-1">
                    <p>{currentPage} /</p>
                    <p className="text-secondary">{totalPage}</p>
                  </div>
                  <Button
                    type="button"
                    disabled={currentPage >= totalPage}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}

              <div className="flex space-x-4">
                {totalPage <= 1 && (
                  <Button onClick={() => setStep('details')}>
                    Save Insurance
                  </Button>
                )}
                {totalPage <= 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitted || isPending || !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit Insurance
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={isSubmitted || isPending}
                    // loading={isPending}
                  >
                    {remainPages.includes(currentPage)
                      ? 'Submit This'
                      : 'Submitted'}
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    onClick={form.handleSubmit(handleSubmit)}
                    // disabled={
                    //   !remainPages.length ||
                    //   isPending ||
                    //   !form.formState.isValid
                    // }
                    // loading={isPending}
                  >
                    Submit All
                  </Button>
                )}
              </div>

              {totalPage > 1 && (
                <Button onClick={() => setStep('details')}>
                  Save Insurance
                </Button>
              )}
              <p>
                You may save the crop insurance if you would prefer to enter
                them another time.
              </p>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
