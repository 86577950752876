import { PlusCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useState } from 'react';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import FlipLayout from '../../commons/FlipLayout';
import AddHedgeForm from './AddHedgeForm';
import HedgingTypeForm from './HedgingTypeForm';

export default function HedgingOptions() {
  const [type, setType] = useState<string>();
  return (
    <FlipLayout>
      {({ side, setSide }) => (
        <div>
          {side === 'front' ? (
            <div className="space-y-8">
              <CardHeader title="Add Hedge Options" tooltip={'tooltip'} />
              <div className={clsx('flex justify-center')}>
                <Button
                  color="primary"
                  size="lg"
                  shadow
                  startIcon={<PlusCircleIcon className="size-6" />}
                  onClick={() => {
                    setSide('back');
                    setType(undefined);
                  }}
                >
                  Add Hedge Options
                </Button>
              </div>
            </div>
          ) : (
            <>
              {!type && (
                <HedgingTypeForm
                  title="Select Hedge Type"
                  onChange={(value) => setType(value)}
                />
              )}

              {type && (
                <AddHedgeForm onCancel={() => setType(undefined)} type={type} />
              )}
            </>
          )}
        </div>
      )}
    </FlipLayout>
  );
}
