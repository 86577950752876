import Health from '../../pages/dashboard/health-checks';
import Market from '../../pages/dashboard/market';

const healthRoutes = [
  {
    path: 'health',
    element: <Health />,
  },
];

export default healthRoutes;
