import { useEffect, useState } from 'react';
import { filterWxData, getWxDataCenter } from '../../../utils';
import { useMapContext } from '../../../contexts/map-context';

export const useCenter = (wData: any) => {
  const [center, setCenter] = useState<{
    lat: number;
    lon: number;
  }>();

  useEffect(() => {
    if (wData) {
      const result = getWxDataCenter(wData);
      if (result) {
        setCenter(result);
      }
      return;
    }
  }, [wData]);

  return {
    center,
    setCenter,
  };
};
