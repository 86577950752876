import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';
import Select from '../../commons/Select';
import Table, { TableConfigs } from '../../commons/table/Table';
import { commodityOptions } from './PriceChart';
import {
  CropToCode,
  MonthToCode,
  basisContractMonths,
  cropToMonthOptions,
  monthOptions,
  yearOptions,
} from '../../../constants';
import {
  useBasis,
  useCompanyLocationOptions,
} from '../../../hooks/basis/use-basis';
import { useBasisPrices } from '../../../hooks/market/use-basis';
import { useUser } from '../../../contexts/auth-context';
import { basisChartOptions } from '../../../constants/chartConfigs';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import { capitalizeFirst, formatDate } from '../../../utils';
import TableLayout from '../commons/TableLayout';
import { useYearFilter } from '../../../contexts/app-filter-context';
import CropIcon from '../../commons/CropIcon';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  TimeScale
);

export const basisCommodityOptions = [
  { value: 'Corn', label: 'Corn', icon: <CropIcon cropName="Corn" /> },
  {
    value: 'HRW Wheat',
    label: 'HRW Wheat',
    icon: <CropIcon cropName="Wheat" />,
  },
  {
    value: 'Soybeans',
    label: 'Soybeans',
    icon: <CropIcon cropName="Soybeans" />,
  },
];


function BasisChart() {
  const basisTableConfigs: TableConfigs = {
    cols: [
      {
        key: 'date',
        name: 'Date',
        type: 'element',
        element: (item: any) => {
          return <span>{formatDate(item.date)}</span>;
        },
      },
      {
        key: 'company',
        name: 'Company',
      },
      {
        key: 'city',
        name: 'City',
      },
      {
        key: 'state',
        name: 'State',
      },
      {
        key: 'basis',
        name: 'Basis',
      },
      {
        key: 'basis_chg',
        name: 'Basis Change',
        type: 'fluctuation',
      },
      {
        key: 'facility_type',
        name: 'Facility Type',
      },
    ],
  };

  const user = useUser();

  const methods = useForm();
  const crop = methods.watch('crop', 'Corn');
  const month = methods.watch('month', 'december');
  const year = methods.watch('year', '2024');
  const companyLocation = methods.watch('companyLocation', '');

  const [highlightedRows, setHighlightedRows] = useState<
    { basis_id: string; time: Date }[]
  >([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const basisID = useMemo(() => {
    return (
      user?.id +
      '-' +
      CropToCode[crop] +
      MonthToCode[month] +
      year.slice(-1) +
      '-' +
      companyLocation.split(' & ').join('-')
    );
  }, [user?.id, crop, month, year, companyLocation]);

  const symbol = useMemo(() => {
    return CropToCode[crop] + MonthToCode[month] + year[3];
  }, [crop, month, year]);

  const yearFilter = useYearFilter();

  const basis = useBasis({
    userId: user?.id ?? '',
    year: yearFilter!,
    symbols: [symbol],
  });

  const basisPrices = useBasisPrices(basisID);
  const companyLocationOptions = useCompanyLocationOptions(
    user?.id!,
    yearFilter!,
    symbol
  );

  // console.log('company location options', companyLocationOptions);

  const { setRoots } = useWebsocketContext();
  const msgBasis: any = useWebSocketDataV2('Basis');

  const chartData = useMemo(() => {
    const datasetsMap: { [key: string]: any[] } = {};
    if (!basisPrices.data) return { datasets: [] };
    basisPrices.data.forEach((item: any) => {
      if (!datasetsMap[item.location]) {
        datasetsMap[item.location] = [];
      }
      datasetsMap[item.location].push({
        x: new Date(item.Date),
        y: parseFloat(item.basis),
        company: item.company,
      });
    });

    const datasets = Object.keys(datasetsMap).map((location) => ({
      backgroundColors: {
        up: '#80bc00',
        down: '#eab208',
        unchanged: 'rgba(143, 143, 143, 1)',
      },
      borderColors: {
        up: '#80bc00',
        down: '#eab208',
        unchanged: 'rgba(143, 143, 143, 1)',
      },
      label: location,
      data: datasetsMap[location],
      borderColor: '#80bc00',
      backgroundColor: '#80bc00',
      fill: false,
    }));

    return { datasets };
  }, [basisPrices.data]);

  useEffect(() => {
    setRoots([CropToCode[crop]]);
  }, [crop]);

  useEffect(() => {
    let updatedData =
      basis.data
        ?.map((item: any) => {
          let newBasis = {};

          if (msgBasis.data && msgBasis.data.basis_id === item.basis_id) {
            newBasis = msgBasis.data;

            setHighlightedRows((prev) => [
              ...prev.filter((row) => row.basis_id !== item.basis_id),
              { basis_id: item.basis_id, time: new Date() },
            ]);
          }

          return {
            date: moment(item.date).format('YYYY-MM-DD'),
            commodity: item.commodity,
            crop_year: item.crop_year,
            company: item.company,
            city: item.city,
            state: item.state,
            basis: item.basis,
            cashprice: item.cashprice,
            basis_chg: item.basis_chg,
            rawchange: item.rawchange,
            facility_type: item.facility_type,
            basis_id: item.basis_id,
            ...newBasis,
          };
        })
        .sort(
          // sort by basis
          (a, b) => parseFloat(b.basis) - parseFloat(a.basis)
        ) ?? [];

    setTableData(updatedData);
  }, [basis.data, msgBasis.data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setHighlightedRows((prev) =>
        prev.filter((row) => now.getTime() - row.time.getTime() < 5000)
      );
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const basisId = useMemo(() => {
    return (
      user?.id +
      '-' +
      CropToCode[crop] +
      MonthToCode[month] +
      year.slice(-1) +
      '-' +
      companyLocation.split(' & ').join('-')
    );
  }, [user?.id, crop, month, year, companyLocation]);

  useEffect(() => {
    methods.setValue(
      'month',
      cropToMonthOptions[crop as keyof typeof cropToMonthOptions][
        cropToMonthOptions[crop as keyof typeof cropToMonthOptions].length - 1
      ].value
    );
  }, [crop]);

  const currentContractDetails = useMemo(() => {
    const basisContract = basis?.data?.find((item: any) => {
      return item.basis_id === basisId;
    });
    if (typeof basisContract?.basis_chg === 'string') {
      basisContract.basis_chg = parseFloat(basisContract.basis_chg).toFixed(3).toString();
    }
    return {
      basis: basisContract?.basis,
      change: basisContract?.basis_chg,
      basis_id: basisContract?.basis_id,
      unix_timestamp: basisContract?.unix_timestamp,
    };
  }, [basis?.data, basisId]);

  const [updatedContract, setUpdatedContract] = useState<any>(null);

  useEffect(() => {
    if (
      msgBasis &&
      msgBasis.data?.basis_id === currentContractDetails.basis_id &&
      (!currentContractDetails.unix_timestamp ||
        msgBasis.data?.unix_timestamp > currentContractDetails.unix_timestamp)
    ) {
      if (typeof msgBasis.data?.change === 'string') {
        msgBasis.data.change = parseFloat(msgBasis.data?.change).toFixed(3).toString();
      }
      const tmpUpdateContract = {
        ...currentContractDetails,
        basis: msgBasis.data?.basis
          ? msgBasis.data?.basis
          : currentContractDetails.basis,
        change: msgBasis.data?.basis_chg,
        unix_timestamp: msgBasis.data?.unix_timestamp,
      };
      setUpdatedContract(tmpUpdateContract);
    } else {
      setUpdatedContract(currentContractDetails);
    }
  }, [msgBasis.data, currentContractDetails, msgBasis.data]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <p className="text-lg pb-2 text-darkGray">Contract</p>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-4 pb-6 rounded-md">
              <Select
                textClassName="text-xl"
                options={basisCommodityOptions}
                defaultValue={'Corn'}
                name="crop"
                onChange={(e) => {}}
              />

              <Select
                textClassName="text-xl"
                options={
                  basisContractMonths[crop as keyof typeof basisContractMonths]
                }
                name="month"
                value={month}
                defaultValue={'december'}
              />

              <Select
                options={yearOptions}
                className="text-base-bold"
                textClassName="text-xl"
                name="year"
                defaultValue={'2024'}
              />
              {companyLocationOptions && companyLocationOptions.length > 0 && (
                <Select
                  textClassName="text-xl"
                  options={companyLocationOptions}
                  name="companyLocation"
                  defaultValue={companyLocationOptions[0].value}
                />
              )}
            </div>
          </div>
          <div className="flex items-center space-x-8 mb-4">
            <div
              className={`${parseFloat(updatedContract?.change) > 0 ? 'text-primary' : parseFloat(updatedContract?.change) === 0 ? user?.network_partner === 'Heartland' ? 'text-black' : 'text-white' : 'text-red'} flex items-center space-x-2`}
            >
              <span className="text-2xl text-base-bold">
                ${updatedContract?.basis ?? '0'}
              </span>
              <div className="flex items-center space-x-1">
                {updatedContract?.change &&
                parseFloat(updatedContract?.change) > 0 ? (
                  <ArrowUpIcon className="w-4 h-4" />
                ) : (
                  <ArrowDownIcon className="w-4 h-4" />
                )}
                <span className="text-xl text-base-bold">
                  ${updatedContract?.change ?? '0'} (
                  {(
                    (100 * parseFloat(updatedContract?.change ?? '0')) /
                    parseFloat(updatedContract?.basis ?? '1')
                  ).toFixed(2)}
                  %)
                </span>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="card p-6 pt-4">
        <Line data={chartData} options={basisChartOptions(symbol)} />
      </div>

      <div className="card mt-4">
        <TableLayout
          icon={commodityOptions.find((item: any) => crop === item.value)?.icon}
          title={`All ${crop} ${capitalizeFirst(month)} ${year} Basis`}
        >
          <Table
            configs={basisTableConfigs}
            data={tableData ?? []}
            highlights={highlightedRows.map((row) => row.basis_id)}
            indexKey="date"
            loading={basis.isLoading}
            pagination={{
              size: 20,
            }}
          />
        </TableLayout>
      </div>
    </>
  );
}

export default BasisChart;
