import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { groupBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { ReactNode, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UnitTypeOptions } from '../../../../constants';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import {
  useCreatePrescriptionProduct,
  useUpdatePrescriptionProduct,
} from '../../../../hooks/field/use-field-prescription';
import { useSeedVarieties } from '../../../../hooks/variety/use-variety';
import {
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Checkbox from '../../../commons/Checkbox';
import ComboSelectInput from '../../../commons/inputs/ComboSelectInput';
import TextInput from '../../../commons/inputs/TextInput';
import Select from '../../../commons/Select';
import {
  calculateCostPerAcre,
  calculateTotalCost,
  calculateTotalUnit,
} from './helpers';

const seedsPerCropUnit: {
  [key: string]: {
    [key: string]: number;
  };
} = {
  Corn: {
    BAG: 80000,
    UNIT: 80000 * 50,
  },
  Soybeans: {
    BAG: 140000,
    UNIT: 140000 * 50,
  },
  Wheat: {
    BAG: 0,
    UNIT: 0 * 50,
  },
};

export default function SeedingRateForm({
  crop,
  selectedZone,
  title,
  edit,
  setIsEditing,
  zones,
  selectedProduct,
  onFlip,
}: {
  crop: string;
  selectedZone: FieldPrescriptionWithProducts;
  title?: string | ReactNode;
  edit?: boolean;
  setIsEditing?: (value: boolean) => void;
  zones: FieldPrescriptionWithProducts[];
  selectedProduct?: PrescriptionProductRes;
  onFlip?: () => void;
}) {
  const user = useUser();
  const year = useYearFilter();
  const { data: seedVarieties } = useSeedVarieties(
    year ?? '',
    crop,
    user?.network_partner || ''
  );
  const { mutateAsync: create, isPending: isCreating } =
    useCreatePrescriptionProduct();
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdatePrescriptionProduct();

  const editDefaultValue = {
    brand: selectedProduct?.brand ?? '',
    variety: selectedProduct?.variety,
    maturity: Number(selectedProduct?.maturity),
    retailer: selectedProduct?.retailer,
    price_per_unit: Number(selectedProduct?.price_per_unit),
    seeds_per_unit: Number(selectedProduct?.seeds_per_unit),
    unit_type: selectedProduct?.unit_type ?? '',
    seeding_rate: Number(selectedProduct?.seeding_rate ?? 0),
    total_units: Number(selectedProduct?.total_units ?? 0),
    total_cost: Number(selectedProduct?.total_cost ?? 0),
    cost_per_acre: Number(selectedProduct?.cost_per_acre ?? 0),
    percent_of_area: Number(selectedProduct?.percent_of_area ?? 0),
    user_id_agrivar_zone: `${user?.id}_${year}-${selectedZone.agrivar_zone}`,
    discount_percentage: Number(selectedProduct?.discount_percentage ?? 0),
  };

  const form = useForm({
    defaultValues: edit
      ? editDefaultValue
      : {
          brand: '',
          variety: '',
          maturity: 0,
          retailer: '',
          price_per_unit: 0,
          seeds_per_unit: 0,
          unit_type: '',
          seeding_rate: Number(
            selectedZone.seeding_rate ??
              selectedZone.recommended_seeding_rate ??
              0
          ),
          total_units: 0,
          total_cost: 0,
          cost_per_acre: 0,
          percent_of_area: 100,
          discount_percentage: 0,
        },
  });

  const handleCreate = async ({
    total_cost,
    total_units,
    applyAllZones,
    cost_per_acre,
    percent_of_area,
    ...values
  }: any) => {
    if (!year || !user) return;
    const appliedZones = applyAllZones
      ? zones.map((item) => ({
          zone: item.agrivar_zone,
          acres: item.area,
          total_cost: calculateTotalCost({
            seedingRate: Number(values.seeding_rate),
            pricePerUnit: values.price_per_unit,
            seedsPerUnit: values.seeds_per_unit,
            numberOfAcres: Number(item.area),
            discountPercentage: values.discount_percentage,
          }).toString(),
          total_units: calculateTotalUnit({
            seedingRate: Number(values.seeding_rate),
            seedsPerUnit: values.seeds_per_unit,
          }).toString(),
          cost_per_acre: calculateCostPerAcre({
            seedingRate: Number(values.seeding_rate),
            pricePerUnit: values.price_per_unit,
            seedsPerUnit: values.seeds_per_unit,
          }).toString(),
          percent_of_area: percent_of_area.toString(),
        }))
      : [
          {
            zone: selectedZone.agrivar_zone,
            acres: selectedZone.area,
            total_cost: total_cost.toString(),
            total_units: total_units.toString(),
            cost_per_acre: cost_per_acre.toString(),
            percent_of_area: percent_of_area.toString(),
          },
        ];

    try {
      await create({
        input_type: 'seed',
        maturity_type: 'Relative Maturity',
        crop: crop,
        year: year,
        userId: user.id,
        field_index: selectedZone.field_index,
        zone: appliedZones,
        variety: values.variety,
        brand_name: values.brand_name,
        price_per_unit: values.price_per_unit,
        maturity: String(values.maturity),
        retailer: values.retailer,
        seeds_per_unit: String(values.seeds_per_unit),
        unit_type: values.unit_type,
        field: selectedZone.field,
        seeding_rate: String(values.seeding_rate),
        product: '',
      });
      setIsEditing?.(false);
      enqueueSnackbar('Seeding rate and variety added successfully', {
        variant: 'success',
      });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      });
    }
  };

  const handleUpdate = async (values: any) => {
    if (!selectedProduct) return;
    try {
      await update({
        ...selectedProduct,
        ...Object.fromEntries(
          Object.entries(values).map(([key, value]) => [key, String(value)])
        ),
      });
      setIsEditing?.(false);
      enqueueSnackbar('Seeding rate and variety updated successfully', {
        variant: 'success',
      });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      });
    }
  };

  const groupByBrand = useMemo(
    () => groupBy(seedVarieties, 'brand'),
    [seedVarieties]
  );
  const brandOptions = useMemo(() => {
    return Object.keys(groupByBrand)?.map((brand) => ({
      label: brand,
      value: brand,
    }));
  }, [groupByBrand]);

  const brand = form.watch('brand');
  const varietyOptions = useMemo(() => {
    const varietySet = Array.from(
      new Set(groupByBrand[brand]?.map((v) => v.seedname))
    );
    return varietySet.map((variety) => ({
      label: variety,
      value: variety,
    }));
  }, [brand, groupByBrand]);

  const variety = form.watch('variety');
  const unit = form.watch('unit_type');
  const pricePerUnit = form.watch('price_per_unit');
  const seedsPerUnit = form.watch('seeds_per_unit');
  const seedingRate = form.watch('seeding_rate');
  const discountPercentage = form.watch('discount_percentage');

  // set cost based on selected variety
  const selectedVariety = useMemo(
    () =>
      seedVarieties?.find(
        (item) => item.seedname === variety && item.brand === brand
      ),
    [variety, brand, seedVarieties]
  );
  useEffect(() => {
    if (!selectedProduct?.price_per_unit) {
      if (!selectedVariety) {
        form.setValue('price_per_unit', 0);
      } else {
        form.setValue('price_per_unit', Number(selectedVariety.price));
      }
    }
  }, [selectedProduct?.price_per_unit, selectedVariety]);

  // set seeds per unit based on crop
  useEffect(() => {
    form.setValue('seeds_per_unit', seedsPerCropUnit[crop][unit]);
  }, [crop, unit]);

  // set maturity based on selected variety
  useEffect(() => {
    if (selectedVariety)
      form.setValue('maturity', Number(selectedVariety.maturity));
  }, [form, selectedVariety]);

  // Calculate
  const totalCost = useMemo(() => {
    return calculateTotalCost({
      seedingRate: Number(seedingRate),
      numberOfAcres: Number(selectedZone.area),
      pricePerUnit: pricePerUnit,
      seedsPerUnit: seedsPerUnit,
      discountPercentage,
    });
  }, [
    seedingRate,
    selectedZone.area,
    pricePerUnit,
    seedsPerUnit,
    discountPercentage,
  ]);

  const totalUnit = useMemo(() => {
    return calculateTotalUnit({
      seedingRate: Number(seedingRate),
      seedsPerUnit: Number(seedsPerUnit),
    });
  }, [seedingRate, seedsPerUnit]);

  const costPerAcre = useMemo(() => {
    return calculateCostPerAcre({
      seedingRate: Number(seedingRate),
      pricePerUnit: pricePerUnit,
      seedsPerUnit: seedsPerUnit,
    });
  }, [pricePerUnit, seedingRate, seedsPerUnit]);

  useEffect(() => {
    form.setValue('total_cost', totalCost);
    form.setValue('total_units', totalUnit);
    form.setValue('cost_per_acre', costPerAcre);
  }, [totalCost, totalUnit, costPerAcre]);

  return (
    <div className="space-y-8">
      <CardHeader
        title={
          title ?? (
            <span>
              Add:{' '}
              <span className="text-primary">{`Zone ${selectedZone.agrivar_zone1}`}</span>
            </span>
          )
        }
        tooltip={'tooltip'}
      />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(edit ? handleUpdate : handleCreate)}
          className="space-y-4"
        >
          <ComboSelectInput name="brand" label="Brand" options={brandOptions} />
          <ComboSelectInput
            label="Variety"
            name="variety"
            options={varietyOptions}
          />
          <Select
            label="Select Unit Type"
            name="unit_type"
            options={UnitTypeOptions}
          />
          <TextInput
            type="number"
            label="Percent of Area"
            name="percent_of_area"
          />
          <TextInput
            name="price_per_unit"
            placeholder="Please enter dollar amount"
            label="Price Per Unit"
          />
          <TextInput
            name="retailer"
            placeholder="Please enter retailer"
            label="Retailer"
          />
          <TextInput
            name="maturity"
            placeholder="Please enter age by days"
            label="Maturity by Days"
          />
          <TextInput
            type="number"
            name="seeds_per_unit"
            placeholder="Please enter seed per unit"
            label="Seed Per Unit"
          />
          <TextInput
            type="number"
            name="seeding_rate"
            placeholder="Please enter seeding rate"
            label="Seeding Rate"
          />
          <TextInput type="number" name="cost_per_acre" label="Cost Per Acre" />
          <TextInput
            type="number"
            name="total_units"
            placeholder="Please enter total units"
            label="Total Units"
          />
          <TextInput
            type="number"
            name="discount_percentage"
            label="Discount Percentage"
          />
          <TextInput
            type="number"
            name="total_cost"
            placeholder="Please enter total cost"
            label="Total Cost"
          />
          {!edit && (
            <>
              <Checkbox name="applyAllZones" label="Apply to all Zones" />
              <div className="flex space-x-4">
                <Button disabled={isUpdating} onClick={() => onFlip?.()}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  startIcon={<PlusCircleIcon className="size-6" />}
                  shadow
                  disabled={isCreating || !form.formState.isValid}
                  loading={isCreating}
                >
                  Add To Seeding
                </Button>
              </div>
            </>
          )}
          {edit && (
            <div className="flex space-x-4">
              <Button
                disabled={isUpdating}
                onClick={() => setIsEditing?.(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isUpdating || !form.formState.isValid}
                loading={isUpdating}
              >
                Update
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
