import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import { useDeletePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import { useCreateNote, useGetNotes } from '../../../../hooks/note/use-note';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { convertToFloat } from '../../../../utils';
import Checkbox from '../../../commons/Checkbox';
import Select from '../../../commons/Select';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

interface SeedingRateListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionWithProducts[];
  onSelect: (zone: string) => void;
  loading: boolean;
  setIsEditing: (value: boolean) => void;
  selectedProductByZone: Record<string, PrescriptionProductRes>;
  setSelectedProductByZone: (
    zone: string,
    value: PrescriptionProductRes
  ) => void;
  crop: string;
}

export default function SeedingRateList({
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
  selectedProductByZone,
  setSelectedProductByZone,
  crop,
}: SeedingRateListProps) {
  const user = useUser();
  const year = useYearFilter();
  const mainUser = useMainUser();
  const { mutate: deleteSeeding } = useDeletePrescriptionProduct();
  const { mutate: createNote } = useCreateNote();

  const { data: notes, isFetching: isFetchingNotes } = useGetNotes({
    userId: user?.id || '',
    year: year || '',
    type: 'seed_variety',
    crop: crop,
  });

  const enhancedData = useMemo(() => {
    return data.map((item) => {
      const selectedProduct = selectedProductByZone[item.agrivar_zone];
      let seeds = 0;
      let yield_per_seed = 0;
      let yield_per_seed_average = 0; // new variable for average yield per seed
      const seeds_per_bushel_dict: { [key: string]: number } = {
        corn: 120000,
        soybeans: 70000,
        wheat: 1000000,
      };
      const seedingRate = item.seeding_rate
        ? parseFloat(item.seeding_rate)
        : NaN;
      const averageSeedingRate = item.average_seeding_rate
        ? parseFloat(item.average_seeding_rate)
        : NaN; // average seeding rate
      const zoneYield = item.user_adjusted_yield
        ? parseFloat(item.user_adjusted_yield)
        : item.zone_yield
          ? parseFloat(item.zone_yield)
          : NaN;
      const averageYield = item.average_yield
        ? parseFloat(item.average_yield)
        : NaN; // average yield
      let seeds_per_bushel = seeds_per_bushel_dict[crop.toLowerCase()];
      if (!isNaN(zoneYield) && !isNaN(seeds_per_bushel)) {
        seeds = zoneYield * seeds_per_bushel;
      }
      // calculate seeds per zone based on seeding rate
      if (!isNaN(seedingRate) && seedingRate !== 0) {
        yield_per_seed = seeds / seedingRate;
        yield_per_seed = parseFloat(yield_per_seed.toFixed(1));
      }
      // calculate average seeds per zone based on average seeding rate
      if (
        !isNaN(averageSeedingRate) &&
        averageSeedingRate !== 0 &&
        !isNaN(averageYield) &&
        seeds_per_bushel
      ) {
        const averageSeeds = averageYield * seeds_per_bushel;
        yield_per_seed_average = averageSeeds / averageSeedingRate;
        yield_per_seed_average = parseFloat(yield_per_seed_average.toFixed(1));
      }
      return {
        ...item,
        custom_seeding_rate:
          item?.seeding_rate ?? item?.recommended_seeding_rate ?? 0,
        yield_per_seed,
        yield_per_seed_average,
        note: notes?.find((note) => note.agrivar_zone === item.agrivar_zone)
          ?.note,
        product: {
          cost_per_acre: convertToFloat(selectedProduct?.cost_per_acre),
        },
      };
    });
  }, [crop, data, notes, selectedProductByZone]);

  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: 'Select Zone',
        key: 'id',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => (
          <Checkbox
            name="id"
            label={`Zone ${item.agrivar_zone1}`}
            checked={selectedZone.agrivar_zone === item.agrivar_zone}
            onChange={(checked: boolean) =>
              checked && onSelect(item.agrivar_zone)
            }
            rounded
          />
        ),
      },
      { name: 'Acres', key: 'area', unit: 'acres' },
      {
        name: 'Seeding Rate',
        key: 'custom_seeding_rate',
        type: 'number',
        subTotal: (data) => {
          const allAcres = data?.reduce(
            (acc, curr) => acc + Number(curr['area'] ?? 0),
            0
          );
          const avg = data?.reduce(
            (acc, curr) =>
              acc +
              (Number(curr['custom_seeding_rate'] ?? 0) *
                Number(curr['area'] ?? 0)) /
                allAcres,
            0
          );
          return avg;
        },
      },
      {
        name: 'Historical Average Seeding Rate',
        key: 'average_seeding_rate',
        type: 'number',
      },
      {
        name: 'Expected Seeds Per Seed Planted',
        key: 'yield_per_seed',
        type: 'number',
        emptyValue: 0,
      },
      {
        name: 'Historical Seed Yield Per Seed Planted',
        key: 'yield_per_seed_average',
        type: 'number',
        emptyValue: 0,
      },
      {
        name: 'Variety',
        key: 'variety',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const options = item.products.map((product) => ({
            value: product.variety,
            label: product.variety,
          }));
          if (!options.length) return <></>;
          return (
            <Select
              key={JSON.stringify(options)}
              className="w-52"
              name="variety"
              options={options}
              onChange={(e) =>
                setSelectedProductByZone(
                  item.agrivar_zone,
                  item.products.find(
                    (product) => product.variety === e.target.value
                  ) as PrescriptionProductRes
                )
              }
            />
          );
        },
      },
      {
        name: 'Cost Per Acre',
        key: 'product.cost_per_acre',
        type: 'number',
        emptyValue: 0,
      },
    ],
  };

  const rowActions: RowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionWithProducts) => {
      deleteSeeding({
        uniqueId: selectedProductByZone[item.agrivar_zone].uniqueid,
        userId: user?.id ?? '',
        year: year ?? '',
        zone: item.agrivar_zone,
      });
    },
    note: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: 'seed_variety',
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: [item.agrivar_zone],
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note', { variant: 'error' });
            },
          }
        );
      },
      onSaveAll: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: 'seed_variety',
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: data.map((zone) => zone.agrivar_zone),
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note', { variant: 'error' });
            },
          }
        );
      },
      title: 'Add Zone Note',
    },
  };

  return (
    <TableLayout title={'Seeding and Varieties'}>
      <Table
        configs={tableConfigs}
        data={enhancedData}
        loading={loading || isFetchingNotes}
        rowActions={rowActions}
        subTotalLabel='Average'
      />
    </TableLayout>
  );
}
