import clsx from 'clsx';

interface NumberDisplayProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'style'>,
    Intl.NumberFormatOptions {
  value: number;
  locales?: string;
  numberStyle?: 'unit' | 'currency' | 'percent' | 'decimal';
}

function NumberDisplay({
  className,
  value,
  unit,
  numberStyle,
  locales = 'en-US',
  unitDisplay,
  maximumFractionDigits = 2,
}: NumberDisplayProps) {
  if (Number.isNaN(value)) return null;
  let formatedValue = value.toString();
  try {
    formatedValue = value.toLocaleString(locales, {
      style: numberStyle,
      unit,
      unitDisplay,
      maximumFractionDigits,
    });
  } catch (error) {
    // for unit not supported
    return (
      <span className={clsx(className)}>
        {value.toLocaleString(locales, {
          style: numberStyle,
          maximumFractionDigits,
        })}{' '}
        {unit}
      </span>
    );
  }

  return <span className={clsx(className)}>{formatedValue}</span>;
}

export default NumberDisplay;
