import * as Auth from 'aws-amplify/auth';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const resetPassword = async (username: string) => {
    setLoading(true);
    try {
      const res = await Auth.resetPassword({
        username: username,
      });
      enqueueSnackbar('Password reset code sent', { variant: 'success' });
      return res;
    } catch (error: any) {
      enqueueSnackbar('Failed to reset new password', {
        variant: 'error',
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmResetPassword = async ({
    username,
    confirmationCode,
    newPassword,
  }: {
    username: string;
    confirmationCode: string;
    newPassword: string;
  }) => {
    setLoading(true);
    try {
      await Auth.confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });
      enqueueSnackbar('Password reset successfully', {
        variant: 'success',
      });
      navigate('/');
    } catch (error: any) {
      enqueueSnackbar('Failed to reset new password', {
        variant: 'error',
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    resetPassword,
    confirmResetPassword,
    isLoading,
  };
};
