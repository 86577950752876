import ResetPasswordPage from '../../../pages/main/reset-password';
import LoginPage from '../../../pages/main/login';
import NewPasswordPage from '../../../pages/main/new-password';
import SignUpPage from '../../../pages/main/sign-up';
import AdvisorSignUpPage from '../../../pages/main/sign-up/AdvisorSignUpPage';
import VerifySMSPage from '../../../pages/main/verify-sms';

const authRoutes = [
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'register/:networkPartner?',
    element: <SignUpPage />,
  },
  {
    path: 'register/advisor',
    element: <AdvisorSignUpPage />,
    expect: true,
  },
  {
    path: 'verify-sms',
    element: <VerifySMSPage />,
  },
  {
    path: 'new-password',
    element: <NewPasswordPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
];

export default authRoutes;
