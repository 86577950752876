import { useEffect, useState } from 'react';


export const useDateSet = (s3Keys: string[], selectedTab: string) => {
  const [dateSet, setDateSet] = useState<any[]>([]);

  useEffect(() => {
    const result = s3Keys.map((key) => {
        const date = key.split('/')[1].split('_')[1];
        return date;
        });
    setDateSet(result);
  }, [s3Keys, selectedTab]);

  return {
    dateSet,
  };
};
