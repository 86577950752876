import { useQuery } from '@tanstack/react-query';
import { getFertilizerProductsApi } from '../../api/fertilizer-api';
import { FertilizerProductRes } from '../../types';

export const useFertilizerProducts = (partner: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['fertilizer-products'],
    enabled: !!partner,
    queryFn: () => getFertilizerProductsApi(partner),
  });

  return { data: data?.data as FertilizerProductRes[], ...rest };
};
