import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Papa from 'papaparse';
import FileDownloadIcon from '../../../assets/icons/FileDownloadIcon';
import IconButton from '../inputs/IconButton';
import Menu from '../menu/Menu';

type DownloadButtonProps = {
  data: any[];
};

export default function TableDownloadButton({ data }: DownloadButtonProps) {
  // Handle download
  const downloadCSV = () => {
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'object') {
          item[key] = JSON.stringify(item[key], null, 2);
        }
        if (key === 'index' || key === 'uniqueid') {
          delete item[key];
        }
      });
    });
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'inbound-grain.csv';
    link.click();
  };

  // Handle download PDF
  const downloadPDF = () => {
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'object') {
          item[key] = JSON.stringify(item[key], null, 2);
        }
        if (key === 'index' || key === 'uniqueid') {
          delete item[key];
        }
      });
    });
    const csv = Papa.unparse(data); // Convert data to CSV
    const rows = csv.split('\n').map((row) => row.split(','));
    const doc = new jsPDF({ orientation: 'landscape' }); // Initialize jsPDF instance
  
    autoTable(doc, {
      head: [rows[0]], // First row as the header
      body: rows.slice(1), // Rest as the body
      styles: {
        fontSize: 10, // Adjust font size for better fit
        cellPadding: 2, // Add some padding to cells
        overflow: 'linebreak', // Allow line breaks
      },
      startY: 10, // Start below top margin
      margin: { top: 10, bottom: 10, left: 10, right: 10 }, // Add margins for spacing
      pageBreak: 'auto', // Automatically create new pages when needed
      didDrawPage: (data) => {
        // Optional: Add page numbers
        const pageCount = (doc as any).internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height || pageSize.getHeight();
  
        doc.text(
          `Page ${pageCount}`,
          pageSize.width / 2,
          pageHeight - 10,
          { align: 'center' }
        );
      },
    });
  
    doc.save('inbound-grain.pdf'); // Save the generated PDF
  };
  

  const items = [
    {
      value: 'csv',
      label: 'Download CSV',
      onClick: downloadCSV,
    },
    {
      value: 'pdf',
      label: 'Download PDF',
      onClick: downloadPDF,
    },
    {
      value: 'email',
      label: 'Send to Email',
      onClick: () => {},
    },
  ];

  return (
    <Menu items={items}>
      <IconButton className="w-8" color="primary">
        <FileDownloadIcon fill="white" />
      </IconButton>
    </Menu>
  );
}
