import { theme } from 'twin.macro';
import BeanIcon from '../../assets/icons/BeanIcon';
import CornIcon from '../../assets/icons/CornIcon';
import CornIconBigColored from '../../assets/icons/CornIconBigColored';
import CornIconColored from '../../assets/icons/CornIconColored';
import SoybeansIconBigColored from '../../assets/icons/SoybeansIconBigColored';
import SoybeansIconColored from '../../assets/icons/SoybeansIconColored';
import WheatconBigColored from '../../assets/icons/WheatconBigColored';
import WheatIcon from '../../assets/icons/WheatIcon';

interface CropIconProps {
  cropName: string;
  colored?: boolean;
  size?: 'small' | 'medium' | 'large';
  fill?: string;
}

export default function CropIcon({
  cropName,
  colored = false,
  size = 'small',
  fill,
}: CropIconProps) {
  let fillColor = fill ?? theme`colors.yellow`;

  if (size === 'large') {
    switch (cropName) {
      case 'Corn':
        return <CornIconBigColored />;
      case 'Soybeans':
        return <SoybeansIconBigColored />;
      case 'Wheat':
        return <WheatconBigColored />;
      default:
        return <span className="size-[150px]"></span>;
    }
  }

  if (size === 'small') {
    switch (cropName) {
      case 'Corn':
        return colored ? <CornIconColored /> : <CornIcon fill={fillColor} />;
      case 'Soybeans':
        return colored ? (
          <SoybeansIconColored />
        ) : (
          <BeanIcon fill={fillColor} />
        );
      case 'Wheat':
        return colored ? <WheatIcon /> : <WheatIcon fill={fillColor} />;
      default:
        return <span className="size-6"></span>;
    }
  }

  return <span></span>;
}
