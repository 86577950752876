import { Field, Input, Label } from '@headlessui/react';
import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormFieldProps } from '../../../types';
import { get } from 'lodash';
import { SpinningIcon } from '../../../assets/icons';

interface TextInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'name'>,
    FormFieldProps {
  endAdornment?: any;
  unit?: string;
  name: string;
  loading?: boolean;
}

function TextInput(props: TextInputProps) {
  const {
    label,
    className,
    endAdornment,
    unit,
    name,
    disabled,
    required,
    max,
    min,
    loading,
    step = 'any',
    onChange: propOnChange,
    ...rest
  } = props;
  const formContext = useFormContext();
  const error = get(formContext?.formState.errors, name);

  if (!formContext) {
    // In case you dont get the values by handleSubmit of react-hook-form
    // you forgot to wrap the form with <FormProvider> in the parent component
  }

  const { onChange, ...register } = formContext?.register(name, {
    max: max,
    min: min,
    required: required ? `${label} is required` : false,
  });

  return (
    <Field className="space-y-1 w-full relative">
      {label && (
        <Label>
          {label} {required && <span className="text-secondary">*</span>}
        </Label>
      )}
      <div className="relative">
        <Input
          autoComplete="off"
          className={clsx(
            error && 'border border-red',
            'px-4 pr-8 py-2 rounded w-full border border-base-1000 bg-base-1000 inputStyle',
            disabled ? 'text-base-700' : 'text-base-000',
            className
          )}
          style={{
            boxShadow:
              '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
          }}
          disabled={disabled}
          {...register}
          step={step}
          {...rest}
          onChange={(e) => {
            onChange(e);
            propOnChange?.(e);
          }}
        />
        {loading && (
          <span className="absolute right-4 top-1/2 -translate-y-1/2">
            <SpinningIcon />
          </span>
        )}
        {endAdornment && (
          <span className="absolute right-4 top-1/2 -translate-y-1/2">
            {endAdornment}
          </span>
        )}
      </div>
      {error && <p className="text-red">{`${error?.message}`}</p>}
    </Field>
  );
}

export default TextInput;
