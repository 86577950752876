import { useMemo } from 'react';
import { FieldPrescriptionRes } from '../../../../types';
import { convertToFloat } from '../../../../utils';
import KnobContainer from '../knob';

interface YieldGoalInfoProps {
  selectedZone: FieldPrescriptionRes;
  onChange: (value: number) => void;
  onUpdate: (value: number) => void;
  isEditing: boolean;
  loading: boolean;
  setIsEditing: (value: boolean) => void;
}
export default function YieldGoalInfo({
  selectedZone,
  onChange,
  onUpdate,
  isEditing,
  loading,
  setIsEditing,
}: YieldGoalInfoProps) {
  const yieldGoal = convertToFloat(
    selectedZone?.user_adjusted_yield ?? selectedZone.zone_yield ?? 0
  );
  const avgYield = convertToFloat(selectedZone.average_yield);
  const agriVarYieldGoal = convertToFloat(selectedZone.zone_yield);

  const max = useMemo(
    () => convertToFloat(selectedZone.zone_yield) * 1.5,
    [selectedZone.agrivar_zone]
  );

  const min = 0;

  return (
    <div>
      <h4 className="display-sm-bold">
        Selected Zone:{' '}
        <span className="text-primary">{`Zone ${selectedZone.agrivar_zone1}`}</span>
      </h4>
      <div className="flex flex-col items-center mt-8">
        <KnobContainer
          id={selectedZone.agrivar_zone1}
          name={'yieldGoal'}
          onChange={onChange}
          onUpdate={onUpdate}
          max={max}
          min={min}
          value={yieldGoal}
          marks={[
            {
              name: 'Historical Average Yield',
              value: avgYield,
              color: 'bg-blue',
            },
            {
              name: 'AgriVaR Yield Goal',
              value: agriVarYieldGoal,
              color: 'bg-red',
            },
          ]}
          loading={loading}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </div>
    </div>
  );
}
