import { CreateNotePayload, GetNotesParams } from '../types/note';
import apiClient from './api-client';

export const getNotesApi = ({ userId, year, type, crop }: GetNotesParams) => {
  return apiClient.get(
    `/notes?userId=${userId}&year=${year}&type=${type}&crop=${crop}`
  );
};

export const createNoteApi = (payload: CreateNotePayload) => {
  return apiClient.post(`/notes`, payload);
};
