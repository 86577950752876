import { IconProps } from '../../types';

export default function RadarIcon({ fill = '#5C7284' }: IconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 8.77148C13.5938 8.34961 12.7969 8.11523 11.9531 8.11523C9.46875 8.11523 7.45312 10.1309 7.45312 12.6152C7.45312 15.0996 9.46875 17.1152 11.9531 17.1152C14.4844 17.1152 16.5 15.0996 16.5 12.6152H19.5C19.5 16.084 17.1562 18.9902 13.9688 19.8809C13.5938 19.1309 12.8438 18.6152 11.9531 18.6152C11.1094 18.6152 10.3594 19.1309 9.98438 19.8809C7.54688 19.1777 5.625 17.3496 4.82812 14.959C5.53125 14.584 5.95312 13.834 5.95312 12.9902C5.95312 12.0996 5.4375 11.3027 4.64062 10.9746C5.4375 7.64648 8.39062 5.11523 11.9531 5.11523C13.6406 5.11523 15.1875 5.67773 16.4062 6.61523L17.4844 5.53711C15.9844 4.36523 14.0625 3.61523 12 3.61523C7.64062 3.61523 3.98438 6.70898 3.14062 10.834C2.20312 11.0684 1.5 11.959 1.5 12.9902C1.5 14.1152 2.29688 15.0527 3.375 15.2402C4.26562 18.2402 6.70312 20.584 9.79688 21.3809C9.98438 22.3652 10.9219 23.1152 12 23.1152C13.0312 23.1152 13.9688 22.3652 14.1562 21.3809C18.0938 20.3965 21 16.834 21 12.6152H24C24 19.2715 18.6094 24.6152 12 24.6152C5.34375 24.6152 0 19.2715 0 12.6152C0 6.00586 5.34375 0.615234 12 0.615234C14.9062 0.615234 17.5312 1.69336 19.6406 3.38086L21.7031 1.36523C22.125 0.896484 22.8281 0.896484 23.25 1.36523C23.7188 1.78711 23.7188 2.49023 23.25 2.91211L14.7188 11.4434C14.9062 11.8184 15 12.2402 15 12.6152C15 14.3027 13.6406 15.6152 12 15.6152C10.3125 15.6152 9 14.3027 9 12.6152C9 10.9746 10.3125 9.61523 12 9.61523C12.375 9.61523 12.7969 9.70898 13.125 9.89648L14.25 8.77148Z"
        fill={fill}
      />
    </svg>
  );
}
