import { PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../commons/Button';
import clsx from 'clsx';
import CardHeader from '../../../commons/CardHeader';

interface ChemicalInfoProps {
  onFlip: () => void;
}

export default function ChemicalInfo({ onFlip }: ChemicalInfoProps) {
  return (
    <div className="space-y-8">
      <CardHeader title="Chemicals (lbs. Per Acre)" tooltip="tooltip" />

      <div className={clsx('flex justify-center')}>
        <Button
          color="primary"
          size="lg"
          shadow
          startIcon={<PlusCircleIcon className="size-6" />}
          onClick={() => onFlip()}
        >
          Add Chemicals
        </Button>
      </div>
    </div>
  );
}
