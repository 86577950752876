import { useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import { usePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import FlipLayout from '../../commons/FlipLayout';
import { getZone, getZonesWithProducts } from '../helpers';
import ChemicalForm from './ChemicalForm';
import ChemicalInfo from './ChemicalInfo';
import ChemicalList from './ChemicalList';

export default function AddChemicals({
  crop,
  presctiptions,
  loading,
}: {
  crop: string;
  presctiptions: FieldPrescriptionRes[];
  loading: boolean;
}) {
  const user = useUser();
  const mainUser = useMainUser();
  const year = useYearFilter();
  const { data: chemicals, isFetching } = usePrescriptionProduct({
    type: 'chemicals',
    year: year ?? '',
    crop,
    userId: user?.id || '',
  });

  const [selectedZone, setSelectedZone] = useState(
    () => presctiptions[0]?.agrivar_zone ?? ''
  );
  const [list, setList] = useState<FieldPrescriptionWithProducts[]>([]);
  const [selectedProductByZone, setSelectedProductZone] = useState(
    list.reduce(
      (acc, item) => {
        acc[item.agrivar_zone] = {} as PrescriptionProductRes;
        return acc;
      },
      {} as Record<string, PrescriptionProductRes>
    )
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleSelectChemical = (
    zone: string,
    chemical: PrescriptionProductRes
  ) => {
    setSelectedProductZone((prev) => ({
      ...prev,
      [zone]: chemical,
    }));
  };

  useEffect(() => {
    setList(getZonesWithProducts(presctiptions, chemicals, 'chemicals'));
  }, [presctiptions, chemicals]);

  useEffect(() => {
    setSelectedProductZone((prev) => {
      return {
        ...prev,
        [selectedZone]:
          chemicals?.find(
            (item) =>
              getZone(item.user_id_agrivar_zone) === selectedZone &&
              item.input_type === 'chemicals'
          ) || ({} as PrescriptionProductRes),
      };
    });
  }, [chemicals, list, selectedZone]);

  const selectedZoneObj = useMemo(() => {
    return (
      list?.find((item) => item.agrivar_zone === selectedZone) ??
      ({} as FieldPrescriptionWithProducts)
    );
  }, [list, selectedZone]);

  return (
    <DisclosureContentLayout
      left={
        <>
          {isEditing ? (
            <ChemicalForm
              key={`${selectedZone}-${selectedProductByZone[selectedZone]?.uniqueid}`}
              crop={crop}
              selectedZone={selectedZoneObj}
              zones={list}
              setIsEditing={setIsEditing}
              edit
              selectedProduct={selectedProductByZone[selectedZone]}
              title={
                <h4>
                  Selected Zone:{' '}
                  <span className="text-primary">{`Zone ${selectedZoneObj.agrivar_zone1}`}</span>
                </h4>
              }
            />
          ) : (
            <FlipLayout>
              {({ side, setSide }) => (
                <div>
                  {side === 'front' && (
                    <ChemicalInfo onFlip={() => setSide('back')} />
                  )}
                  {side === 'back' && (
                    <ChemicalForm
                      crop={crop}
                      selectedZone={selectedZoneObj}
                      zones={list}
                      onFlip={() => setSide('front')}
                    />
                  )}
                </div>
              )}
            </FlipLayout>
          )}
        </>
      }
      right={
        <ChemicalList
          crop={crop}
          data={list}
          onSelect={setSelectedZone}
          loading={loading || isFetching}
          selectedZone={selectedZoneObj}
          selectedProductByZone={selectedProductByZone}
          setSelectedProductByZone={handleSelectChemical}
          setIsEditing={setIsEditing}
        />
      }
    />
  );
}
