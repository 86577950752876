import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../Button';

export default function TableFilter({
  children,
  form,
}: {
  children: React.ReactNode;
  form: ReturnType<typeof useForm>;
}) {
  const [clearKey, setClearKey] = useState(0);

  return (
    <FormProvider {...form} key={clearKey}>
      <div className="grid grid-cols-6 gap-4 w-full">
        {children}
        <div className="flex items-end">
          <Button
            onClick={() => {
              form.reset();
              setClearKey((prev) => prev + 1);
            }}
            className="!w-fit h-[41px]"
          >
            Clear
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}
