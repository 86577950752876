import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteInvoiceApi, getInvoicesApi, updateInvoiceDetailsApi } from '../../api/invoice-api';
import { InvoiceItem } from '../../types/invoice';

export const useGetInvoices = ({
  userId,
  partner,
}: {
  userId: string;
  partner: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['invoices', userId, partner],
    queryFn: () => getInvoicesApi({ userId, partner }),
    enabled: !!userId && !!partner,
  });
  return { data: data?.data as InvoiceItem[], ...rest };
};

export const useDeleteInvoice = () => {
  const mutation = useMutation({
    mutationKey: ['invoices'],
    mutationFn: (payload: any) => deleteInvoiceApi(payload.uniqueid, payload.index),
  });
  return mutation;
}

export const useUpdateInvoiceDetails = (userId: string, partner: string) => {
  const mutation = useMutation({
    mutationKey: ['invoices', userId, partner],
    mutationFn: (payload: any) => updateInvoiceDetailsApi(payload),
  });
  return mutation;
}

