import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import TextInput from '../../../components/commons/inputs/TextInput';
import Modal from '../../../components/commons/modal';
import Select from '../../../components/commons/Select';
import Table, { TableConfigs } from '../../../components/commons/table/Table';
import { PasswordRegex } from '../../../constants';
import {
  useResetUserPassword,
  useSendTempPassword,
  useUpdateUser,
  useUsers,
} from '../../../hooks/user/use-users';
import { UserProfileRes } from '../../../types';

const validateSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Invalid password')
    .matches(PasswordRegex, 'Invalid password')
    .required()
    .label('Password'),
});

export default function UserList() {
  const [filters, setFilters] = useState({});
  const [selectedUser, setSelectedUser] = useState<
    UserProfileRes | undefined
  >();
  const { data: users } = useUsers(filters);
  const { mutateAsync: sendTempPassword, isPending: isTempPwdPending } =
    useSendTempPassword();
  const { mutate: updateUser } = useUpdateUser();
  const { mutateAsync: resetPassword, isPending: isResetPasswordPending } =
    useResetUserPassword();

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(validateSchema),
  });

  const handleSubmitResetPassword = async (values: any) => {
    if (!selectedUser) return;
    try {
      await resetPassword({
        email: selectedUser.email,
        password: values.password,
      });
      form.reset();
      setSelectedUser(undefined);
      enqueueSnackbar('Password reset successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to reset password', { variant: 'error' });
    }
  };

  const handleChange = async (
    key: string,
    value: boolean,
    item: UserProfileRes
  ) => {
    const payload = {
      id: item.id,
      email: item.email,
      field: key,
      value: value,
    };
    updateUser(payload);
  };

  const tableConfigs: TableConfigs = {
    cols: [
      {
        key: 'email',
        name: 'Email',
      },
      {
        key: 'first_name',
        name: 'First Name',
      },
      {
        key: 'last_name',
        name: 'Last Name',
      },
      {
        key: 'username',
        name: 'Username',
      },
      {
        key: 'address',
        name: 'Address',
      },
      {
        key: 'city',
        name: 'City',
      },
      {
        key: 'state',
        name: 'State',
      },
      {
        key: 'phone',
        name: 'Phone',
      },
      {
        key: 'real_time',
        name: 'Show Real-Time Prices',
        type: 'element',
        element: (item: UserProfileRes) => (
          <span className="flex justify-center">
            <Checkbox
              name="real_time"
              onChange={(checked: boolean) =>
                handleChange('real_time', checked, item)
              }
              defaultChecked={item.real_time}
            />
          </span>
        ),
      },
      {
        key: 'is_admin',
        name: 'Admin',
        type: 'element',
        element: (item: UserProfileRes) => (
          <span className="flex justify-center">
            <Checkbox
              name="is_admin"
              onChange={(checked: boolean) =>
                handleChange('is_admin', checked, item)
              }
              defaultChecked={item.is_admin}
            />
          </span>
        ),
      },
      {
        key: 'is_active',
        name: 'Active',
        type: 'element',
        element: (item: UserProfileRes) => (
          <span className="flex justify-center">
            <Checkbox
              name="is_active"
              onChange={(checked: boolean) =>
                handleChange('is_active', checked, item)
              }
              defaultChecked={item.is_active}
            />
          </span>
        ),
      },
      {
        key: 'is_advisor_client',
        name: 'Advisor Client',
        type: 'element',
        element: (item: UserProfileRes) => (
          <span className="flex justify-center">
            <Checkbox
              name="is_advisor_client"
              onChange={(checked: boolean) =>
                handleChange('is_advisor_client', checked, item)
              }
              defaultChecked={item.is_advisor_client}
            />
          </span>
        ),
      },
      {
        key: 'advisor_real_time',
        name: 'Show Real-Time Prices For Advisor Client',
        type: 'element',
        element: (item: UserProfileRes) => (
          <span className="flex justify-center">
            <Checkbox
              name="advisor_real_time"
              onChange={(checked: boolean) =>
                handleChange('advisor_real_time', checked, item)
              }
              defaultChecked={item.advisor_real_time}
            />
          </span>
        ),
      },
      {
        key: 'temp_password',
        name: 'Temporary Password',
      },
    ],
  };

  const extraActions = (item: UserProfileRes) => {
    return (
      <div className="flex flex-col space-y-2">
        <Link to={`/user/${item.id}/app/dashboard`}>
          <Button color="primary" size="sm">
            Dashboard
          </Button>
        </Link>
        <Link to={`/user/${item.id}/app/data-inputs`}>
          <Button color="primary" size="sm">
            Enter Client Data
          </Button>
        </Link>
        <Button
          disabled={isResetPasswordPending}
          size="sm"
          color="primary"
          onClick={() => setSelectedUser(item)}
          loading={isResetPasswordPending}
        >
          Reset Password
        </Button>
        <Button
          disabled={isTempPwdPending}
          size="sm"
          color="primary"
          onClick={() =>
            sendTempPassword({ userId: item.id, email: item.email })
          }
          loading={isTempPwdPending}
        >
          Send Temporary Password
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-end mb-2">
        <Select
          fieldClassName="w-[200px]"
          name="is_completed"
          options={[
            {
              label: 'All',
              value: '',
            },
            {
              value: 'true',
              label: 'Registration Complete',
            },
            {
              value: 'false',
              label: 'Registration Incomplete',
            },
          ]}
          onChange={(e) => setFilters({ is_completed: e.target.value })}
        />
      </div>
      <div className="max-h-[1000px]  overflow-y-scroll">
        <Table
          configs={tableConfigs}
          data={users || []}
          extraActions={extraActions}
        />
      </div>

      <Modal
        onClose={{
          handler: () => {
            setSelectedUser(undefined);
            form.reset();
          },
          btnText: 'Cancel',
        }}
        onConfirm={{
          handler: form.handleSubmit(handleSubmitResetPassword),
          btnText: 'Submit',
        }}
        title={
          <span className="flex justify-between">
            Reset User Password
            <span className="text-primary">
              {selectedUser?.first_name} {selectedUser?.last_name}{' '}
              <span className="font-light">({selectedUser?.email})</span>
            </span>
          </span>
        }
        open={!!selectedUser}
        loading={form.formState.isSubmitting}
        disabled={!form.formState.isValid || isResetPasswordPending}
      >
        <FormProvider {...form}>
          <form className="flex space-x-4">
            <TextInput name="password" label="Password" required />
          </form>
        </FormProvider>
        <div className="text-base-100 ml-4 mt-4">
          Password must at least:
          <ul className="list-disc pl-4">
            <li>8 characters long</li>
            <li>One uppercase</li>
            <li>One lowercase</li>
            <li>One number</li>
            <li>One special character (!@#$%^&*)</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}
