import apiClient from './api-client';

export const getSeedVarietiesApi = (
  year: string,
  crop: string,
  network_partner: string
) => {
  return apiClient.get(
    `variety/seed?crop=${crop}&year=${year}&network_partner=${network_partner}`
  );
};
