import { IconProps } from '../../types';

const FileDownloadIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.896875 0 0 0.896875 0 2V14C0 15.1031 0.896875 16 2 16H10C11.1031 16 12 15.1031 12 14V5H8C7.44688 5 7 4.55312 7 4V0H2ZM8 0V4H12L8 0ZM6.75 7.25V10.4406L7.71875 9.47188C8.0125 9.17813 8.4875 9.17813 8.77812 9.47188C9.06875 9.76562 9.07187 10.2406 8.77812 10.5312L6.52812 12.7812C6.23438 13.075 5.75938 13.075 5.46875 12.7812L3.21875 10.5312C2.925 10.2375 2.925 9.7625 3.21875 9.47188C3.5125 9.18125 3.9875 9.17813 4.27812 9.47188L5.24687 10.4406L5.25 7.25C5.25 6.83437 5.58437 6.5 6 6.5C6.41563 6.5 6.75 6.83437 6.75 7.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default FileDownloadIcon;
