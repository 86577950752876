import clsx from 'clsx';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import PoweredByArivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import { NETWORK_PARTNER } from '../../../constants';
import {
  useMainUser,
  useSetControlledUser,
} from '../../../contexts/auth-context';
import { useUserById } from '../../../hooks/user/use-users';
import { useWebSocket } from '../../../hooks/websocket/use-websocket';
import DashboardHeader from './header/DashboardHeader';

function DashboardLayout() {
  const mainUser = useMainUser();
  const params = useParams();
  const navigate = useNavigate();
  const setControlledUser = useSetControlledUser();
  const controlledUserID =
    params.userId ||
    (localStorage.getItem('is_admin') === '1'
      ? localStorage.getItem('JD_user_id') ||
        localStorage.getItem('climate_user_id')
      : '');
  const { data: controlledUser } = useUserById(controlledUserID ?? '');

  if (
    (mainUser?.is_admin || mainUser?.is_advisor_client) &&
    !controlledUserID
  ) {
    navigate('/', { replace: true });
  }

  if ((mainUser?.is_admin || mainUser?.is_advisor_client) && controlledUserID) {
    setControlledUser?.(controlledUser);
  }

  // @TODO find a better way to call useWebSocket
  useWebSocket();
  useEffect(() => {
    document
      .querySelector('html')
      ?.setAttribute(
        'data-theme',
        controlledUser?.network_partner ??
          mainUser?.network_partner ??
          NETWORK_PARTNER['new-vision']
      );
    return () => {
      document
        .querySelector('html')
        ?.setAttribute('data-theme', NETWORK_PARTNER.Agrivar);
    };
  }, [controlledUser?.network_partner, mainUser?.network_partner]);

  return (
    <div
      className={clsx('max-w-[1600px] px-4 py-4 mx-auto', 'md:px-8 md:py-8')}
    >
      <DashboardHeader />
      <div id="main" className="mt-8">
        <Outlet />
      </div>
      <footer className="flex justify-end mt-8">
        <span className="border-2 border-[#80bc00] py-1.5 px-4 rounded-xl shadow-[0px_4px_16px_0px_#000000B2]">
          <img src={PoweredByArivarLogo} alt="powered by agrivar logo" />
        </span>
      </footer>
    </div>
  );
}

export default DashboardLayout;
