import { useEffect, useState } from 'react';
import { getWxDataCenter } from '../../utils';

export const useFieldsCenter = (wData: any) => {
  const [center, setCenter] = useState<{
    lat: number;
    lon: number;
  }>();


  useEffect(() => {
    if (wData) {
        const result = getWxDataCenter(wData);
        if (result) {
          setCenter(result);
        }
        return;
      }
  }, [wData]);

  return {
    center,
    setCenter,
  };
};
