import { IconProps } from '../../types';

const FutureIcon = ({ fill = '#F9FAFB' }: IconProps) => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_122_913)">
        <path
          d="M18.02 24.452H1.40495C-0.121842 21.9017 -0.00802172 18.7992 0.00798421 18.6374C0.00798421 18.4586 0.153816 18.3128 0.332549 18.3128C0.527288 18.2968 4.91291 18.1341 7.57612 20.7813C8.19324 21.4144 8.64763 22.1293 9.00598 22.8603V16.3957H10.4029V22.8603C10.7604 22.1293 11.2148 21.4144 11.8488 20.7813C14.512 18.1332 18.8816 18.2959 19.0764 18.3128C19.2551 18.3128 19.4009 18.4595 19.4009 18.6374C19.417 18.7841 19.5477 21.8857 18.02 24.452Z"
          fill={fill}
        />
        <path
          d="M9.7067 0.451965C4.97695 0.451965 1.14264 4.28716 1.14264 9.01603C1.14264 13.7449 4.97695 17.5801 9.7067 17.5801C14.4365 17.5801 18.2708 13.7458 18.2708 9.01603C18.2708 4.28627 14.4365 0.451965 9.7067 0.451965ZM9.32789 8.19884H10.0784C11.631 8.19884 12.8821 9.538 12.7283 11.1208C12.6109 12.3132 11.6683 13.2256 10.5203 13.4532V14.5203C10.5203 14.6386 10.4243 14.7337 10.3069 14.7337H9.10737C8.9891 14.7337 8.88595 14.6377 8.88595 14.5203V13.4683C7.70863 13.27 6.78918 12.2981 6.68514 11.0906C6.67002 10.9652 6.78117 10.8549 6.90566 10.8549H8.10522C8.20837 10.8549 8.30351 10.9207 8.31863 11.0239C8.39955 11.5094 8.81926 11.8775 9.327 11.8775H10.0188C10.5337 11.8775 11.005 11.5316 11.0859 11.031C11.1961 10.3908 10.7026 9.83144 10.0775 9.83144H9.45149C8.06076 9.83144 6.81674 8.82307 6.68425 7.43944C6.54464 6.01136 7.54501 4.78246 8.88506 4.56104V3.51621C8.88506 3.39083 8.98821 3.2948 9.10648 3.2948H10.306C10.4234 3.2948 10.5194 3.39083 10.5194 3.51621V4.56104C11.7048 4.76023 12.6242 5.73214 12.7274 6.94593C12.7345 7.07042 12.6313 7.17357 12.5068 7.17357H11.2993C11.2041 7.17357 11.1081 7.10687 11.0939 7.01173C11.013 6.52622 10.5861 6.15008 10.0784 6.15008H9.38658C8.87884 6.15008 8.40755 6.49598 8.31952 7.00373C8.21637 7.64485 8.70989 8.19617 9.32789 8.19617V8.19884Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_122_913">
          <rect
            width="19.4107"
            height="24"
            fill="white"
            transform="translate(0 0.451965)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FutureIcon;
