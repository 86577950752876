import AddSoilSampleForm from '../../../components/data-inputs/soil-samples/AddSoilSampleForm';
import SoilSampleList from '../../../components/data-inputs/soil-samples/SoilSampleList';

export default function SoilSamples() {
  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-3 space-y-8">
        <div className="card !rounded-lg p-4">
          <AddSoilSampleForm />
        </div>
      </div>

      <div className="card !rounded-lg col-span-9 overflow-hidden h-fit">
        <SoilSampleList />
      </div>
    </div>
  );
}
