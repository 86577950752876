import { useQuery } from '@tanstack/react-query';
import { getBasisAPI } from '../../api/basis-api';
import { BasisRes } from '../../types';
import { useMemo } from 'react';

export const useBasis = ({
  userId,
  year,
  symbols,
  crop,
  enabled = true,
}: {
  userId: string;
  year?: string;
  symbols?: string[];
  crop?: string;
  enabled?: boolean;
}) => {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ['basis', userId, year, symbols, crop],
    queryFn: () => getBasisAPI({ userId, year, symbols, crop }),
    enabled: !!userId && enabled,
  });

  return {
    data: data?.data as BasisRes[] | [],
    allData: data?.data?.items as BasisRes[],
    isLoading,
    ...rest,
  };
};

// helpers
export const getBasisOptions = (data: BasisRes[]) => {
  return (
    data
      ?.sort((a, b) => a.company.localeCompare(b.company))
      ?.map((item) => ({
        label: `${item.company} - ${item.location}`,
        value: item.basis_id,
      })) || []
  );
};
export const useCompanyLocationOptions = (
  userId: string,
  year: string,
  symbol: string
) => {
  const basis = useBasis({
    userId,
    year,
    symbols: [symbol],
  });

  const companyLocationOptions = useMemo(() => {
    const options: any = [];
    basis.data?.forEach((item: any) => {
      if (item.symbol === symbol) {
        // Filter by matching symbol
        const key = `${item.company} & ${item.location}`;
        if (!options.some((option: any) => option.value === key)) {
          options.push({
            value: key,
            label: `${item.company} & ${item.location}`,
          });
        }
      }
    });
    return options.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }, [basis.data, symbol]);

  return companyLocationOptions;
};

export const useLocationOptions = (
  userId: string,
  year: string,
  symbol: string
) => {
  const basis = useBasis({
    userId,
    year,
    symbols: [symbol],
  });

  const locationOptions = useMemo(() => {
    const options: any = [];
    basis.data?.forEach((item: any) => {
      if (item.symbol === symbol) {
        // Filter by matching symbol
        const key = item.location;
        if (!options.some((option: any) => option.value === key)) {
          options.push({
            value: key,
            label: item.location,
          });
        }
      }
    });
    return options;
  }, [basis.data, symbol]);

  return locationOptions;
};
