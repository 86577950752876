import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import { useDeletePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import { useCreateNote, useGetNotes } from '../../../../hooks/note/use-note';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import Checkbox from '../../../commons/Checkbox';
import Select from '../../../commons/Select';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';
import { getZone } from '../helpers';

interface ChemicalListProps {
  data: FieldPrescriptionWithProducts[];
  onSelect: (zone: string) => void;
  loading?: boolean;
  selectedZone: FieldPrescriptionRes;
  setSelectedProductByZone: (zone: string, value: any) => void;
  selectedProductByZone: Record<string, PrescriptionProductRes>;
  setIsEditing: (value: boolean) => void;
  crop: string;
}

export default function ChemicalList({
  data,
  onSelect,
  loading,
  selectedZone,
  setSelectedProductByZone,
  selectedProductByZone,
  setIsEditing,
  crop,
}: ChemicalListProps) {
  const user = useUser();
  const year = useYearFilter();
  const mainUser = useMainUser();

  const { mutate: deleteChemical, isPending: isDeleting } =
    useDeletePrescriptionProduct();
  const { mutate: createNote } = useCreateNote();

  const { data: notes, isFetching: isFetchingNotes } = useGetNotes({
    userId: user?.id || '',
    year: year || '',
    type: 'chemicals',
    crop: crop,
  });

  const enhancedData = useMemo(() => {
    return data.map((item: FieldPrescriptionWithProducts) => {
      return {
        ...item,
        product: {
          brand: selectedProductByZone[item.agrivar_zone]?.brand,
          total_units: selectedProductByZone[item.agrivar_zone]?.total_units,
          price_per_unit:
            selectedProductByZone[item.agrivar_zone]?.price_per_unit,
          rate_per_acre:
            selectedProductByZone[item.agrivar_zone]?.rate_per_acre,
          unit: selectedProductByZone[item.agrivar_zone]?.unit,
          application_time:
            selectedProductByZone[item.agrivar_zone]?.application_time,
          total_cost: selectedProductByZone[item.agrivar_zone]?.total_cost,
          location: selectedProductByZone[item.agrivar_zone]?.location,
        },
        note: notes?.find((note) => note.agrivar_zone === item.agrivar_zone)
          ?.note,
      };
    });
  }, [data, notes, selectedProductByZone]);

  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: 'Select Zone',
        key: 'id',
        type: 'element',
        element: (item) => (
          <Checkbox
            name="id"
            label={`Zone ${item.agrivar_zone1}`}
            checked={selectedZone.agrivar_zone === item.agrivar_zone}
            onChange={(checked: boolean) =>
              checked && onSelect(item.agrivar_zone)
            }
            rounded
          />
        ),
      },
      { name: 'Brand', key: 'product.brand' },
      { name: 'Unit', key: 'unit' },
      {
        name: 'Location',
        key: 'product.location',
        subTotal: true,
      },
      {
        name: 'Price Per Unit',
        key: 'product.price_per_unit',
        type: 'currency',
        subTotal: true,
      },
      {
        name: 'Rate Per Acre',
        key: 'product.rate_per_acre',
        type: 'number',
        subTotal: true,
      },
      {
        name: 'Total Units',
        key: 'product.total_units',
        type: 'number',
        subTotal: true,
      },
      {
        name: 'Total Cost',
        key: 'product.total_cost',
        type: 'currency',
        subTotal: true,
      },
      { name: 'Application Period', key: 'product.application_time' },
      {
        name: 'Product',
        key: 'product',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const options = item.products
            ?.filter((chemical) => {
              return (
                getZone(chemical.user_id_agrivar_zone) === item.agrivar_zone
              );
            })
            ?.map((chemical) => ({
              value: chemical.product,
              label: `${chemical.application_time} ${chemical.product}`,
            }));
          if (!options.length) return <></>;
          return (
            <Select
              key={JSON.stringify(options)}
              className="w-52"
              name="product"
              options={options}
              onChange={(e) =>
                setSelectedProductByZone(
                  item.agrivar_zone,
                  item.products?.find(
                    (chemical) => chemical.product === e.target.value
                  ) as PrescriptionProductRes
                )
              }
            />
          );
        },
      },
    ],
  };

  const rowActions: RowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionWithProducts) => {
      deleteChemical({
        uniqueId: selectedProductByZone[item.agrivar_zone].uniqueid,
        userId: user?.id ?? '',
        year: year ?? '',
        zone: item.agrivar_zone,
      });
    },
    note: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: 'chemicals',
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: [item.agrivar_zone],
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note', { variant: 'error' });
            },
          }
        );
      },
      onSaveAll: (item: FieldPrescriptionRes, value: string, onClose) => {
        createNote(
          {
            userId: user?.id || '',
            year: year || '',
            crop: crop,
            input_type: 'chemicals',
            note: value,
            field: item.field,
            field_index: item.field_index,
            agrivar_zones: data.map((zone) => zone.agrivar_zone),
            user: {
              id: mainUser?.id || '',
              username: mainUser?.username || '',
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Note added to all zones successfully', {
                variant: 'success',
              });
              onClose();
            },
            onError: () => {
              enqueueSnackbar('Failed to add note to all zones', {
                variant: 'error',
              });
            },
          }
        );
      },
      title: 'Add Note',
    },
  };

  return (
    <TableLayout title={'Chemical Application'}>
      <Table
        data={enhancedData}
        configs={tableConfigs}
        subTotalRowVariant="secondary"
        loading={loading || isDeleting || isFetchingNotes}
        rowActions={rowActions}
      />
    </TableLayout>
  );
}
