import { useState, useEffect } from 'react';

export const useCountdown = (initialCount = 60) => {
  const [countdown, setCountdown] = useState(initialCount);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const startCountdown = () => setCountdown(initialCount);

  return { countdown, startCountdown };
};
