import { IconProps } from '../../types';

export default function HailIcon({ fill = '#5C7284' }: IconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 6.61523C21.9844 6.61523 24 8.63086 24 11.1152C24 13.5996 21.9844 15.6152 19.5 15.6152H4.5C2.01562 15.6152 0 13.5996 0 11.1152C0 9.14648 1.26562 7.45898 3.09375 6.89648C3 6.56836 3 6.24023 3 5.86523C3 3.00586 5.34375 0.615234 8.25 0.615234C10.2656 0.615234 12 1.78711 12.8906 3.47461C13.5469 2.67773 14.5781 2.11523 15.75 2.11523C17.8125 2.11523 19.5 3.80273 19.5 5.86523C19.5 6.14648 19.4531 6.38086 19.4062 6.66211C19.4062 6.66211 19.4531 6.61523 19.5 6.61523ZM18 17.1152C18.7969 17.1152 19.5 17.8184 19.5 18.6152C19.5 19.459 18.7969 20.1152 18 20.1152C17.1562 20.1152 16.5 19.459 16.5 18.6152C16.5 17.8184 17.1562 17.1152 18 17.1152ZM9 21.6152C9.79688 21.6152 10.5 22.3184 10.5 23.1152C10.5 23.959 9.79688 24.6152 9 24.6152C8.15625 24.6152 7.5 23.959 7.5 23.1152C7.5 22.3184 8.15625 21.6152 9 21.6152ZM15 21.6152C15.7969 21.6152 16.5 22.3184 16.5 23.1152C16.5 23.959 15.7969 24.6152 15 24.6152C14.1562 24.6152 13.5 23.959 13.5 23.1152C13.5 22.3184 14.1562 21.6152 15 21.6152ZM12 17.1152C12.7969 17.1152 13.5 17.8184 13.5 18.6152C13.5 19.459 12.7969 20.1152 12 20.1152C11.1562 20.1152 10.5 19.459 10.5 18.6152C10.5 17.8184 11.1562 17.1152 12 17.1152ZM3 21.6152C3.79688 21.6152 4.5 22.3184 4.5 23.1152C4.5 23.959 3.79688 24.6152 3 24.6152C2.15625 24.6152 1.5 23.959 1.5 23.1152C1.5 22.3184 2.15625 21.6152 3 21.6152ZM6 17.1152C6.79688 17.1152 7.5 17.8184 7.5 18.6152C7.5 19.459 6.79688 20.1152 6 20.1152C5.15625 20.1152 4.5 19.459 4.5 18.6152C4.5 17.8184 5.15625 17.1152 6 17.1152Z"
        fill={fill}
      />
    </svg>
  );
}
