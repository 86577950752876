import apiClient from './api-client';

export type ExportPresParams = {
  fileType: string; // Fertilizer | Seeding
  userIdYear: string;
  crop: string;
  fieldIndex: string;
  userEmail: string;
  convertFunction?: string;
  partner?: string | null;
};

export type ExportPresParamsV2 = {
  partner: string; // climate | jd-operations
  fileType: string; // Fertilizer | Seeding
  userIdYear: string; // 620ED3_2024
  crop: string;
  fieldIndex: string;
  userEmail: string;
  url: string;
};

export const exportPrescriptionApi = ({
  fileType,
  userIdYear,
  crop,
  fieldIndex,
  userEmail,
  convertFunction,
  partner,
}: ExportPresParams) => {
  let query = '';
  if (convertFunction) query += `&convert_function=${convertFunction}`;
  if (partner) {
    const partner1 = partner === 'jd-operations' ? 'John Deere' : 'Climate';
    query += `&partner=${partner1}`;
  }

  return apiClient.get(
    `/convert-point-to-polygon?fileType=${fileType}&userId=${userIdYear}&crop=${crop}&fieldIndex=${fieldIndex}&email=${userEmail}${query}`
  );
};

export const exportPrescriptionApiV2 = ({
  partner,
  userIdYear,
  url,
}: ExportPresParamsV2) => {
  return apiClient.post(`field/prescription/export/${partner}`, {
    userId: userIdYear,
    url,
  });
};
