import { erf } from 'mathjs';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import { useUpdateFieldPrescription } from '../../../../hooks/field/use-field-prescription';
import { FieldPrescriptionRes } from '../../../../types';
import { convertToFloat } from '../../../../utils';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import YieldGoalInfo from './YieldGoalInfo';
import YieldGoalList from './YieldGoalList';

interface SetYieldGoalProps {
  prescriptions: FieldPrescriptionRes[];
  loading: boolean;
  crop: string;
}

export type Zone = {
  zone: string;
  name: string;
  acres: number;
  yieldGoal: number;
  historicalAvg: number;
  agrivarRec: number;
  expectedRevenuePerAcre: number;
};

function calculateProbability(yieldGoal: number, mean: number, stdDev: number) {
  const z = (yieldGoal - mean) / stdDev;
  const p1 = 0.5 * (1 + erf(z / Math.sqrt(2))) * 100;
  return (100 - p1).toFixed(2);
}

export default function SetYieldGoal({
  loading,
  prescriptions: prescriptionsProp,
  crop,
}: SetYieldGoalProps) {
  const user = useUser();
  const mainUser = useMainUser();
  const year = useYearFilter();
  const { mutateAsync: update, isPending } = useUpdateFieldPrescription();

  const [selectedZone, setSelectedZone] = useState<string>(
    prescriptionsProp[0]?.agrivar_zone || ''
  );
  const [isEditing, setIsEditing] = useState(false);
  const [prescriptions, setPrescriptions] = useState<FieldPrescriptionRes[]>(
    []
  );

  useEffect(() => {
    setPrescriptions(prescriptionsProp);
  }, [prescriptionsProp]);

  const handleSelectZone = (zone: string) => {
    setSelectedZone(zone);
  };

  const handleChangeGoal = (value: number) => {
    setPrescriptions((prev) =>
      prev.map((item) =>
        item.agrivar_zone === selectedZone
          ? { ...item, user_adjusted_yield: value.toString() }
          : item
      )
    );
  };

  const selectedZoneObj = useMemo(() => {
    return (
      prescriptions?.find((pres) => pres.agrivar_zone === selectedZone) ??
      ({} as FieldPrescriptionRes)
    );
  }, [prescriptions, selectedZone]);

  // knob
  const handleUpdateGoal = async (value: number) => {
    const updatedZone = prescriptions.find(
      (item) => item.agrivar_zone === selectedZone
    );
    if (!updatedZone || !year || !user || !mainUser) return;

    const mean = convertToFloat(selectedZoneObj.predicted_yield);
    const stdDev = convertToFloat(selectedZoneObj.sigma);
    const probability = calculateProbability(value, mean, stdDev);

    try {
      await update({
        user: mainUser,
        user_id: user.id,
        year: updatedZone.crop_year,
        user_id_crop: updatedZone.user_id_crop,
        agrivar_zone: updatedZone.agrivar_zone,
        field: updatedZone.field,
        field_index: updatedZone.field_index,
        key: 'user_adjusted_yield',
        user_adjusted_yield: value.toString(),
        value: value.toString(),
        yield_probability: probability.toString(),
      });
      enqueueSnackbar('Yield goal updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Failed to update yield goal', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <DisclosureContentLayout
      left={
        <YieldGoalInfo
          key={JSON.stringify(selectedZoneObj)}
          selectedZone={selectedZoneObj}
          onChange={handleChangeGoal}
          onUpdate={handleUpdateGoal}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          loading={isPending}
        />
      }
      right={
        <YieldGoalList
          crop={crop}
          data={prescriptions}
          selectedZone={selectedZoneObj}
          onSelect={handleSelectZone}
          loading={loading}
          setIsEditing={setIsEditing}
        />
      }
    />
  );
}
