import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tick,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  CategoryScale
);

interface SingleLineChartProps {
  labels: string[];
  data: unknown[];
  label?: string;
  width?: number;
  height?: number;
  backgroundColor?: {
    startColor: string;
    stopColor: string;
  };
  borderColor?: string;
  stepSize?: number;
  scales?: ChartOptions['scales'];
  scalesColor?: string;
  yTicksCallback?: (
    tickValue: string | number,
    index: number,
    ticks: Tick[]
  ) => string | number | string[] | number[] | null | undefined;
}

const SingleLineChart = ({
  labels,
  data,
  label,
  width = 240,
  height = 150,
  backgroundColor = {
    startColor: 'rgba(128, 188, 0, 0.6)',
    stopColor: 'rgba(128, 188, 0, 0)',
  },
  borderColor = '#80BC00',
  scalesColor,
  stepSize = 5000,
  scales,
  yTicksCallback: yTicksCallbackProp,
}: SingleLineChartProps) => {
  const defaultData = {
    labels: labels,
    datasets: [
      {
        label,
        lineTension: 0,
        borderWidth: 4,
        pointRadius: 0,
        borderColor,
        borderCapStyle: 'round' as const,
        fill: true,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, height * 1.05);
          gradient.addColorStop(0, backgroundColor.startColor);
          gradient.addColorStop(1, backgroundColor.stopColor);
          return gradient;
        },
        data: data,
      },
    ],
  };

  const defaultOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: width && height ? width / height : 1,
    interaction: {
      intersect: false,
      mode: 'nearest' as const,
    },
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'black',
        displayColors: false,
      },
      legend: {
        display: label ? true : false,
        position: 'right',
        labels: {
          color: 'white',
          font: {
            family: 'RobotoCondensed',
            size: 16,
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: scalesColor,
          font: {
            family: 'RobotoCondensed',
          },
          stepSize: stepSize,
          callback: yTicksCallbackProp,
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            day: 'YY MMM',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            family: 'RobotoCondensed',
          },
        },
      },
      ...scales,
    },
  };

  return (
    <Line
      key={JSON.stringify(data)}
      // @ts-ignore
      options={defaultOptions}
      data={defaultData}
      width={width}
      height={height}
    />
  );
};

export default SingleLineChart;
