import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import IconButton from './inputs/IconButton';

interface PaginationProps {
  page: number;
  totalPage: number;
  setPage: (page: number) => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  onPageChange?: (page: number) => void;
}

export const usePagination = ({
  size,
  total,
  onPageChange,
}: {
  size: number;
  total: number;
  onPageChange?: (page: number) => void;
}) => {
  const [page, setPage] = useState(1);
  const pageSize = useMemo(() => size ?? 10, [size]);

  const skip = useMemo(() => (page - 1) * pageSize, [page, pageSize]);
  const endIndex = useMemo(() => page * pageSize, [page, pageSize]);
  const totalPage = useMemo(
    () => Math.ceil(total / pageSize),
    [total, pageSize]
  );

  const onPrevPage = () => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  };
  const onNextPage = () => {
    if (page < totalPage) {
      setPage((page) => page + 1);
    }
  };

  useEffect(() => {
    onPageChange?.(page);
  }, [page]);

  return {
    page,
    setPage,
    pageSize,
    skip,
    endIndex,
    totalPage,
    onPrevPage,
    onNextPage,
  };
};

export default function Pagination({
  page,
  totalPage,
  setPage,
  onPrevPage,
  onNextPage,
  onPageChange,
}: PaginationProps) {
  const handleChangePage = (page: number) => {
    setPage(page);
    onPageChange?.(page);
  };

  return (
    <nav className="flex space-x-2.5 justify-center w-full p-2">
      <IconButton onClick={() => handleChangePage(1)}>
        <ChevronDoubleLeftIcon className="size-3" />
      </IconButton>
      <IconButton onClick={onPrevPage}>
        <ChevronLeftIcon className="size-3" />
      </IconButton>

      {/* Numbers */}
      {/* First page */}
      <IconButton
        className={clsx(
          'rounded !text-md-regular size-6 !p-0',
          page === 1 &&
            '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
        )}
        onClick={() => handleChangePage(1)}
      >
        1
      </IconButton>

      {/* Second page */}
      {totalPage > 1 && (page <= 3 || page === totalPage) && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0',
            page === 2 &&
              '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
          )}
          onClick={() => {
            handleChangePage(2);
          }}
        >
          2
        </IconButton>
      )}

      {/* Third page */}
      {totalPage > 2 && (page <= 3 || page === totalPage) && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0',
            page === 3 &&
              '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
          )}
          onClick={() => handleChangePage(3)}
        >
          3
        </IconButton>
      )}

      {/* Dots around page > 3 */}
      {totalPage > 4 && (
        <IconButton className={clsx('rounded !text-md-regular size-6 !p-0')}>
          ...
        </IconButton>
      )}

      {/* Current page if > 3 */}
      {totalPage > 3 && page > 3 && page < totalPage && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0 group-data-[theme="Heartland"]/root:text-base-000',
            '!bg-none !bg-primary'
          )}
        >
          {page}
        </IconButton>
      )}

      {/* Dots around page > 3 */}
      {totalPage > 3 && page > 3 && page < totalPage && (
        <IconButton className={clsx('rounded !text-md-regular size-6 !p-0')}>
          ...
        </IconButton>
      )}

      {/* Last page */}
      {totalPage > 3 && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0',
            page === totalPage &&
              '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
          )}
          onClick={() => handleChangePage(totalPage)}
        >
          {totalPage}
        </IconButton>
      )}
      <IconButton onClick={onNextPage}>
        <ChevronRightIcon className="size-3" />
      </IconButton>
      <IconButton onClick={() => handleChangePage(totalPage)}>
        <ChevronDoubleRightIcon className="size-3" />
      </IconButton>
    </nav>
  );
}
