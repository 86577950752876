import { IconProps } from '../../types';

export default function ZoomOutIcon({ fill = '#5C7284' }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6875 18.3984C20.0781 18.7891 20.0781 19.375 19.6875 19.7266C19.5312 19.9219 19.2969 20 19.0625 20C18.7891 20 18.5547 19.9219 18.3984 19.7266L13.1641 14.4922C11.7578 15.625 10 16.25 8.125 16.25C3.63281 16.25 0 12.6172 0 8.125C0 3.67188 3.63281 0 8.125 0C12.5781 0 16.25 3.67188 16.25 8.125C16.25 10.0391 15.5859 11.7969 14.4531 13.1641L19.6875 18.3984ZM8.125 14.375C11.5625 14.375 14.375 11.6016 14.375 8.125C14.375 4.6875 11.5625 1.875 8.125 1.875C4.64844 1.875 1.875 4.6875 1.875 8.125C1.875 11.6016 4.64844 14.375 8.125 14.375ZM11.25 7.1875C11.7578 7.1875 12.1875 7.61719 12.1875 8.125C12.1875 8.67188 11.7578 9.0625 11.2109 9.0625H4.96094C4.45312 9.0625 4.0625 8.67188 4.0625 8.125C4.0625 7.61719 4.45312 7.1875 4.96094 7.1875H11.25Z"
        fill={fill}
      />
    </svg>
  );
}
