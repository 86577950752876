export function calculateUnitPerAcre({
  amount,
  percentNeed,
  percentInProduct,
}: {
  percentNeed: number;
  percentInProduct: number;
  amount: number;
}) {
  return (amount * percentNeed) / percentInProduct;
}

export function calculateTotalUnit({
  amount,
  percentNeed,
  percentInProduct,
  numberOfAcres,
}: {
  percentNeed: number;
  percentInProduct: number;
  amount: number;
  numberOfAcres: number;
}) {
  const unitPerAcre = calculateUnitPerAcre({
    amount,
    percentNeed,
    percentInProduct,
  });
  return unitPerAcre * numberOfAcres;
}

export function calculateCostPerAcre({
  amount,
  percentNeed,
  percentInProduct,
  pricePerUnit,
  unit,
}: {
  amount: number;
  percentNeed: number;
  percentInProduct: number;
  pricePerUnit: number;
  unit: string;
}) {
  let totalUnitPerAcre = calculateUnitPerAcre({
    amount,
    percentNeed,
    percentInProduct,
  }); // in lbs
  // if (unit === 'GAL') {
  //   totalUnitPerAcre = totalUnitPerAcre / 8.34;
  // } else if (unit === 'TONS') {
  //   totalUnitPerAcre = totalUnitPerAcre / 2000;
  // }

  if (unit === 'TONS') {
    totalUnitPerAcre = totalUnitPerAcre / 2000;
  }

  return totalUnitPerAcre * pricePerUnit;
}

export function calculateTotalCost({
  costPerAcre,
  numberOfAcres,
}: {
  costPerAcre: number;
  numberOfAcres: number;
}) {
  return costPerAcre * numberOfAcres;
}

export function calculateCostPerAcreByCost({
  totalCost,
  numberOfAcres,
}: {
  totalCost: number;
  numberOfAcres: number;
}) {
  return numberOfAcres === 0 ? 0 : totalCost / numberOfAcres;
}

export function getOriginalValue(value: number, percent: number) {
  return value / (1 - percent / 100);
}
