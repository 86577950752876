import { IconProps } from '../../types';

const OptionIcon = ({ fill = '#F9FAFB' }: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_122_1101)">
        <path
          d="M18.5116 13.5616C15.5043 13.5616 13.066 15.9999 13.066 19.0072C13.066 22.0146 15.5043 24.4528 18.5116 24.4528C21.519 24.4528 23.9573 22.0146 23.9573 19.0072C23.9573 15.9999 21.519 13.5616 18.5116 13.5616ZM21.5831 18.5326L18.6755 22.4197C18.5935 22.5284 18.4306 22.5284 18.3486 22.4197L15.441 18.5326C15.3404 18.3981 15.4366 18.2066 15.604 18.2066H17.207V15.7158C17.207 15.6036 17.2987 15.5118 17.4109 15.5118H19.6132C19.7254 15.5118 19.8171 15.6036 19.8171 15.7158V18.2066H21.4201C21.5884 18.2066 21.6837 18.3981 21.5831 18.5326Z"
          fill={fill}
        />
        <path
          d="M10.8904 5.89761C10.8904 2.89026 8.45299 0.451965 5.44564 0.451965C2.43829 0.451965 0 2.89026 0 5.89761C0 8.90495 2.43829 11.3432 5.44564 11.3432C8.45299 11.3432 10.8913 8.90495 10.8913 5.89761H10.8904ZM8.35325 6.69731H6.75028V9.18814C6.75028 9.30035 6.65855 9.39208 6.54635 9.39208H4.34404C4.23184 9.39208 4.14011 9.30035 4.14011 9.18814V6.69731H2.53714C2.36883 6.69731 2.27354 6.50584 2.37417 6.37137L5.28178 2.48417C5.36371 2.37553 5.52668 2.37553 5.60861 2.48417L8.51622 6.37137C8.61685 6.50584 8.52067 6.69731 8.35325 6.69731Z"
          fill={fill}
        />
        <path
          d="M23.2983 2.3969L17.386 3.3382C17.0512 3.39163 16.9176 3.80217 17.158 4.04172L18.6541 5.53693L11.4025 12.7886L9.74785 11.1349C9.54035 10.9274 9.20462 10.9274 8.99713 11.1349L0.155866 19.977C-0.0516297 20.1845 -0.0516297 20.5202 0.155866 20.7277L1.38481 21.9576C1.5923 22.1651 1.92804 22.1651 2.13553 21.9576L9.37293 14.7202L11.0267 16.3748C11.2342 16.5823 11.5699 16.5823 11.7774 16.3748L20.6338 7.51838L22.1299 9.01538C22.3694 9.25493 22.78 9.12224 22.8334 8.7874L23.7738 2.87422C23.8183 2.59549 23.577 2.35504 23.2983 2.39957V2.3969Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_122_1101">
          <rect
            width="23.9573"
            height="24"
            fill="white"
            transform="translate(0 0.451965)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OptionIcon;
