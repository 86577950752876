import { useQuery } from '@tanstack/react-query';
import { getFuturesContractsApi } from '../../api/contracts-api';
import { ContractRes } from '../../types';
import { futuresContracts } from '../../constants';

export const useFuturesContracts = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['contracts/futures'],
    queryFn: getFuturesContractsApi,
  });

  return { data: data?.data as ContractRes[] | [], ...rest };
};

// helpers
export const getPriceOptions = (cropName: string, year: string | undefined) => {
  if (!cropName || !year) return [];

  return (futuresContracts as any)[cropName]?.[year] || [];
};
