import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useDeleteInsurance,
  useInsurances,
} from '../../../hooks/insurance/use-insurance';
import Table, { TableConfigs } from '../../commons/table/Table';
import TableLayout from '../../dashboard/commons/TableLayout';
import EditCropInsuranceForm from './EditCropInsuranceForm';

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'legal_description',
      name: 'Legal Description',
    },
    {
      key: 'commodity',
      name: 'Commodity',
    },
    {
      key: 'intended_use',
      name: 'Intended Use',
    },
    {
      key: 'irrigation_practice',
      name: 'Irrigation',
    },
    {
      key: 'percent_coverage',
      name: 'Org. Pract.',
    },
    {
      key: 'insurance_plan',
      name: 'Ins. Type',
    },
    {
      key: 'options',
      name: 'Options',
    },
    {
      key: 'percent_coverage',
      name: '% Coverage',
    },
    {
      key: 'premium_per_acre',
      name: 'Premium / Acre',
    },
    {
      key: 'share_percentage',
      name: 'Share %',
    },
  ],
};

export default function CropInsuranceList() {
  const user = useUser();
  const year = useYearFilter();

  const { data, isFetching } = useInsurances({
    userId: user?.id ?? '',
    year: year ?? '',
  });
  const { mutate: deleteInsurance } = useDeleteInsurance();

  const rowActions = {
    edit: {
      onSelect: (item: any) => {},
      form: ({ item, onClose }: { item: any; onClose: () => void }) => {
        return <EditCropInsuranceForm data={item} onClose={onClose} />;
      },
    },
    delete: (item: any) => {
      deleteInsurance({
        uniqueId: item.unique_id,
        legalDescription: item.legal_description,
      });
    },
    note: {
      onSave: (item: any, value: any) => {},
      title: 'Add Insurance Note',
    },
  };

  const filters = [
    { name: 'Crop', key: 'crop', handler: (value: any) => {} },
    { name: 'Crop Class', key: 'cropClass', handler: (value: any) => {} },
  ];

  return (
    <TableLayout title="Crop Insurances" filters={filters}>
      <Table
        configs={tableConfigs}
        data={data}
        pagination={{
          size: 10,
        }}
        rowActions={rowActions}
        loading={isFetching}
      />
    </TableLayout>
  );
}
