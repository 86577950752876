import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { ReactNode } from 'react';
import Button from '../Button';
import clsx from 'clsx';

export default function Modal({
  title,
  children,
  open = false,
  onClose,
  onConfirm,
  loading,
  disabled,
  size = 'md',
}: {
  title: string | ReactNode;
  children: React.ReactNode;
  open?: boolean;
  onClose: {
    handler: (value: boolean) => void;
    btnText?: string;
  };
  onConfirm?: {
    handler: () => void;
    btnText?: string;
  };
  loading?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}) {
  return (
    <Dialog
      open={open}
      as="div"
      className="relative z-10 focus:outline-none"
      onClose={onClose.handler}
    >
      <div className="fixed inset-0 bg-black/60" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 z-10">
          <DialogPanel
            className={clsx('w-full card p-4 shadow-2xl space-y-4', {
              'max-w-4xl': size === 'lg',
              'max-w-3xl': size === 'md',
              'max-w-xl': size === 'sm',
            })}
          >
            <DialogTitle as="h3" className="display-sm-bold">
              {title}
            </DialogTitle>
            <div>{children}</div>
            <div className="mt-4 flex gap-4 justify-end">
              <Button
                onClick={() => onClose.handler(false)}
                className="!w-fit"
                disabled={loading}
              >
                {onClose.btnText ?? 'Cancel'}
              </Button>
              {onConfirm && (
                <Button
                  onClick={() => onConfirm.handler()}
                  color="primary"
                  className="!w-fit"
                  disabled={disabled || loading}
                  loading={loading}
                >
                  {onConfirm.btnText ?? 'Confirm'}
                </Button>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
