import InboundGrain from '../../components/dashboard/risk-management/inbound-grain';
import Invoices from '../../pages/dashboard/invoices';
import FarmExpenses from '../../pages/data-inputs/farm-expenses';

const financialsRoutes = [
  {
    path: 'financials',
    children: [
      {
        index: true,
        element: <FarmExpenses />,
      },
      {
        path: 'farm-expenses',
        element: <FarmExpenses />,
      },
      {
        path: 'invoices',
        element: <Invoices />,
      },
      {
        path: 'inbound-grain-tickets',
        element: <InboundGrain />,
      },
    ],
  },
];

export default financialsRoutes;
