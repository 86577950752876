import { IconProps } from '../../types';

const BasisIcon = ({ fill = '#F9FAFB' }: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_122_1137)">
        <path
          d="M5.13821 19.2897H5.87199C6.15695 19.2897 6.43033 19.4117 6.62268 19.6236C6.8177 19.84 6.90853 20.1188 6.87826 20.4082C6.82661 20.9158 6.35553 21.3129 5.80609 21.3129H4.86126C4.54068 21.3129 4.20941 21.117 4.15777 20.8009C4.13194 20.6459 3.99213 20.532 3.83006 20.532H2.69021C2.59315 20.532 2.49875 20.5738 2.43464 20.6451C2.37141 20.7145 2.34292 20.8027 2.35093 20.8935C2.4667 22.0485 3.38659 22.9666 4.54514 23.0904V24.191C4.54514 24.3344 4.66268 24.452 4.80605 24.452H6.08482C6.22819 24.452 6.34574 24.3344 6.34574 24.191V23.0521C7.59957 22.801 8.55419 21.7902 8.67084 20.5818C8.75722 19.7038 8.44466 18.8649 7.79726 18.2514C7.25672 17.7393 6.5176 17.4891 5.77225 17.4891H5.01888C4.73303 17.4891 4.45876 17.3671 4.26819 17.1543C4.07317 16.9388 3.98234 16.66 4.01261 16.3715C4.06426 15.8639 4.53534 15.4659 5.08478 15.4659H6.02337C6.34663 15.4659 6.68057 15.6618 6.734 15.9806C6.75982 16.1346 6.90052 16.2477 7.0617 16.2477H8.20155C8.29861 16.2477 8.39123 16.2068 8.45712 16.1346C8.52035 16.0652 8.54884 15.977 8.53994 15.8862C8.42507 14.7303 7.50606 13.8122 6.34663 13.6884V12.586C6.34663 12.4426 6.23086 12.3268 6.08749 12.3268H4.80783C4.66446 12.3268 4.54692 12.4444 4.54692 12.5878V13.708C3.09717 13.9538 2.07666 15.2869 2.22092 16.7598C2.35984 18.1792 3.64216 19.2906 5.13999 19.2906L5.13821 19.2897Z"
          fill={fill}
        />
        <path
          d="M18.5109 13.5611C15.5037 13.5611 13.0655 15.9993 13.0655 19.0065C13.0655 22.0138 15.5037 24.452 18.5109 24.452C21.5182 24.452 23.9564 22.0138 23.9564 19.0065C23.9564 15.9993 21.5182 13.5611 18.5109 13.5611ZM21.5823 18.5319L18.6748 22.4189C18.5929 22.5276 18.4299 22.5276 18.348 22.4189L15.4405 18.5319C15.3398 18.3974 15.436 18.206 15.6034 18.206H17.2063V15.7152C17.2063 15.603 17.2981 15.5113 17.4103 15.5113H19.6125C19.7247 15.5113 19.8164 15.603 19.8164 15.7152V18.206H21.4193C21.5876 18.206 21.6829 18.3974 21.5823 18.5319Z"
          fill={fill}
        />
        <path
          d="M10.89 5.8974C10.89 2.89017 8.45267 0.451965 5.44544 0.451965C2.4382 0.451965 0 2.89017 0 5.8974C0 8.90464 2.4382 11.3428 5.44544 11.3428C8.45267 11.3428 10.8909 8.90464 10.8909 5.8974H10.89ZM8.35294 6.69708H6.75003V9.18782C6.75003 9.30002 6.65831 9.39174 6.5461 9.39174H4.34388C4.23168 9.39174 4.13996 9.30002 4.13996 9.18782V6.69708H2.53705C2.36874 6.69708 2.27346 6.50562 2.37409 6.37115L5.28158 2.4841C5.36351 2.37546 5.52647 2.37546 5.6084 2.4841L8.5159 6.37115C8.61653 6.50562 8.52035 6.69708 8.35294 6.69708Z"
          fill={fill}
        />
        <path
          d="M18.2046 7.68643H18.9384C19.2234 7.68643 19.4967 7.80843 19.6891 8.02037C19.8841 8.23676 19.9749 8.51549 19.9447 8.8049C19.893 9.31249 19.4219 9.70965 18.8725 9.70965H17.9277C17.6071 9.70965 17.2758 9.51374 17.2242 9.19761C17.1983 9.04267 17.0585 8.92868 16.8965 8.92868H15.7566C15.6596 8.92868 15.5652 8.97054 15.501 9.04178C15.4378 9.11124 15.4093 9.1994 15.4173 9.29023C15.5331 10.4452 16.453 11.3633 17.6115 11.4871V12.5878C17.6115 12.7311 17.7291 12.8487 17.8725 12.8487H19.1512C19.2946 12.8487 19.4121 12.7311 19.4121 12.5878V11.4488C20.666 11.1977 21.6206 10.187 21.7373 8.97855C21.8236 8.10051 21.5111 7.26166 20.8637 6.6481C20.3231 6.13606 19.584 5.88583 18.8387 5.88583H18.0853C17.7994 5.88583 17.5252 5.76383 17.3346 5.551C17.1396 5.33549 17.0487 5.05677 17.079 4.76824C17.1307 4.26066 17.6017 3.8626 18.1512 3.8626H19.0898C19.413 3.8626 19.747 4.05851 19.8004 4.37731C19.8262 4.53137 19.9669 4.64446 20.1281 4.64446H21.268C21.365 4.64446 21.4576 4.6035 21.5235 4.53137C21.5868 4.46191 21.6153 4.37375 21.6063 4.28292C21.4915 3.12704 20.5725 2.20893 19.413 2.08515V0.982707C19.413 0.839336 19.2973 0.72357 19.1539 0.72357H17.8742C17.7309 0.72357 17.6133 0.841117 17.6133 0.984488V2.10474C16.1636 2.35052 15.1431 3.68361 15.2873 5.1565C15.4262 6.57597 16.7086 7.68732 18.2064 7.68732L18.2046 7.68643Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_122_1137">
          <rect
            width="23.9564"
            height="24"
            fill="white"
            transform="translate(0 0.451965)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BasisIcon;
