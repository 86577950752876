import * as Auth from 'aws-amplify/auth';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetCognitoUser, useSetIsAuthenticated } from '../../contexts/auth-context';
import { getNextLoginFlowRoute } from '../../utils';
import { fetchUserAttributes } from '../../auth';

export const useConfirmNewPassword = () => {
  const navigate = useNavigate();
  const setIsAuthenticated = useSetIsAuthenticated();
  const setCognitoUser = useSetCognitoUser();
  const [isLoading, setLoading] = useState(false);

  const confirmNewPassword = async (password: string) => {
    setLoading(true);
    try {
      const { isSignedIn, nextStep } = await Auth.confirmSignIn({
        challengeResponse: password,
      });

      if (isSignedIn) {
        const userAttrs = await fetchUserAttributes();
        const role = userAttrs?.['custom:role'];
        const network_partner = userAttrs?.['custom:network_partner'];
        setCognitoUser?.({
          role,
          network_partner,
        });
        setIsAuthenticated?.(true);
        enqueueSnackbar('Password confirmed', {
          variant: 'success',
        });
      }
      const nextRoute = getNextLoginFlowRoute(nextStep.signInStep);
      navigate(nextRoute, { replace: true });
    } catch (error: any) {
      enqueueSnackbar('Failed to confirm new password', {
        variant: 'error',
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    confirmNewPassword,
    isLoading,
  };
};
