import { Tab, TabGroup, TabList } from '@headlessui/react';
import {
  BanknotesIcon,
  ChartBarIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/24/solid';
import { Fragment, useMemo } from 'react';
import { useMarketContext } from '../../../contexts/market-context';

const TabSelector = () => {
  const { currentTab, setCurrentTab } = useMarketContext();

  const tabItems = useMemo(
    () => [
      {
        id: 1,
        label: 'Futures',
        icon: (
          <ChartBarIcon
            className={`w-6 h-6 ${currentTab === 0 ? 'text-primary' : ''}`}
          />
        ),
      },
      {
        id: 2,
        label: 'Options',
        icon: (
          <BanknotesIcon
            className={`w-6 h-6 ${currentTab === 1 ? 'text-primary' : ''}`}
          />
        ),
      },
      {
        id: 3,
        label: 'Spreads',
        icon: (
          <ChartBarIcon
            className={`w-6 h-6 ${currentTab === 2 ? 'text-primary' : ''}`}
          />
        ),
      },
      {
        id: 4,
        label: 'Basis',
        icon: (
          <PresentationChartBarIcon
            className={`w-6 h-6 ${currentTab === 3 ? 'text-primary' : ''}`}
          />
        ),
      },
      // {
      //   id: 5,
      //   label: 'Fundamental',
      //   icon: (
      //     <ChartBarIcon
      //       className={`w-6 h-6 ${currentTab === 4 ? 'text-primary' : ''}`}
      //     />
      //   ),
      // },
    ],
    [currentTab]
  );

  return (
    <TabGroup selectedIndex={currentTab} className="" onChange={setCurrentTab}>
      <TabList className="flex card space-x-4 px-4 py-2 rounded-md justify-between">
        {tabItems.map((tab, index) => (
          <Tab as={Fragment} key={tab.id}>
            {({ selected }) => (
              <button
                className={`flex items-center space-x-2 px-4 py-2 rounded-md ${
                  selected ? 'text-primary' : ''
                }`}
                style={{ outline: 'none' }}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </button>
            )}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
};

export default TabSelector;
