import { IconProps } from '../../types';

export default function DieIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      version="1.1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="icons"
        stroke="none"
        stroke-width="1"
        fill="#fff"
        fill-rule="evenodd"
      >
        <g
          id="ui-gambling-website-lined-icnos-casinoshunter"
          transform="translate(-86.000000, -416.000000)"
          fill="#252528"
          fill-rule="nonzero"
        >
          <g id="square-filled" transform="translate(50.000000, 120.000000)">
            <path
              fill={fill}
              d="M60.5,296 C62.4329966,296 64,297.567003 64,299.5 L64,320.5 C64,322.432997 62.4329966,324 60.5,324 L39.5,324 C37.5670034,324 36,322.432997 36,320.5 L36,299.5 C36,297.567003 37.5670034,296 39.5,296 L60.5,296 Z M60.5,297.75 L39.5,297.75 C38.5335017,297.75 37.75,298.533502 37.75,299.5 L37.75,320.5 C37.75,321.466498 38.5335017,322.25 39.5,322.25 L60.5,322.25 C61.4664983,322.25 62.25,321.466498 62.25,320.5 L62.25,299.5 C62.25,298.533502 61.4664983,297.75 60.5,297.75 Z M45.625,314.375 C46.5914983,314.375 47.375,315.158502 47.375,316.125 C47.375,317.091498 46.5914983,317.875 45.625,317.875 C44.6585017,317.875 43.875,317.091498 43.875,316.125 C43.875,315.158502 44.6585017,314.375 45.625,314.375 Z M54.375,314.375 C55.3414983,314.375 56.125,315.158502 56.125,316.125 C56.125,317.091498 55.3414983,317.875 54.375,317.875 C53.4085017,317.875 52.625,317.091498 52.625,316.125 C52.625,315.158502 53.4085017,314.375 54.375,314.375 Z M45.625,308.25 C46.5914983,308.25 47.375,309.033502 47.375,310 C47.375,310.966498 46.5914983,311.75 45.625,311.75 C44.6585017,311.75 43.875,310.966498 43.875,310 C43.875,309.033502 44.6585017,308.25 45.625,308.25 Z M54.375,308.25 C55.3414983,308.25 56.125,309.033502 56.125,310 C56.125,310.966498 55.3414983,311.75 54.375,311.75 C53.4085017,311.75 52.625,310.966498 52.625,310 C52.625,309.033502 53.4085017,308.25 54.375,308.25 Z M45.625,302.125 C46.5914983,302.125 47.375,302.908502 47.375,303.875 C47.375,304.841498 46.5914983,305.625 45.625,305.625 C44.6585017,305.625 43.875,304.841498 43.875,303.875 C43.875,302.908502 44.6585017,302.125 45.625,302.125 Z M54.375,302.125 C55.3414983,302.125 56.125,302.908502 56.125,303.875 C56.125,304.841498 55.3414983,305.625 54.375,305.625 C53.4085017,305.625 52.625,304.841498 52.625,303.875 C52.625,302.908502 53.4085017,302.125 54.375,302.125 Z"
              id="dice"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
