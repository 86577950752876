import { createContext, useContext } from 'react';
import { WeatherContextProps } from '../types';

export const WeatherContext = createContext<WeatherContextProps | undefined>(
  undefined
);

export const useWeatherContext = (): WeatherContextProps => {
  const context = useContext(WeatherContext);
  if (context === undefined) {
    throw new Error('useWeatherContext must be used within a WeatherProvider');
  }
  return context;
};
