import { useQuery } from '@tanstack/react-query';
import { getSeedVarietiesApi } from '../../api/variety-api';
import { SeedVarietiesRes } from '../../types';

export const useSeedVarieties = (
  year: string,
  crop: string,
  network_partner: string
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['variety/seed'],
    queryFn: () => getSeedVarietiesApi(year, crop, network_partner),
    enabled: !!year && !!crop && !!network_partner,
  });

  return { data: data?.data as SeedVarietiesRes[] | [], ...rest };
};
