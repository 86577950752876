import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createInsuranceApi,
  deleteInsuranceApi,
  getInsurancesApi,
} from '../../api/insurance-api';
import {
  CreateInsurancePayload,
  DeleteInsurancePayload,
  InsuranceItem,
} from '../../types/insurance';
import { enqueueSnackbar } from 'notistack';

export const useInsurances = ({
  userId,
  year,
  scenarioId,
}: {
  userId: string;
  year: string;
  scenarioId?: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['insurances', userId, year, scenarioId],
    queryFn: () =>
      getInsurancesApi({
        userId,
        year,
        scenarioId,
      }),
  });

  return { data: data?.data as InsuranceItem[], ...rest };
};

export const useCreateInsurance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: CreateInsurancePayload) =>
      createInsuranceApi(payload),
    mutationKey: ['CREATE', 'insurance'],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['insurance'] });
    },
  });
};

export const useDeleteInsurance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: DeleteInsurancePayload) =>
      deleteInsuranceApi(payload),
    mutationKey: ['DELETE', 'insurance/delete'],
    onSuccess: () => {
      enqueueSnackbar('Insurance deleted', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete insurance', { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['insurances'] });
    },
  });
};
