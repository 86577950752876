import { useQuery } from '@tanstack/react-query';
import { getChemicalProductsApi } from '../../api/chemical-api';
import { ChemicalProduct } from '../../types/prescriptions';

export const useGetChemicalProducts = (partnerNetwork: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['chemical-products', partnerNetwork],
    queryFn: () => getChemicalProductsApi(partnerNetwork),
    enabled: !!partnerNetwork,
  });

  return { data: data?.data as ChemicalProduct[], ...rest };
};
