import clsx from 'clsx';
import { useEffect } from 'react';
import { NETWORK_PARTNER } from '../../../constants';
import { useMainUser } from '../../../contexts/auth-context';
import DashboardLayout from '../dashboard/DashboardLayout';
import AdminHeader from './header/AdminHeader';

function AdminLayout() {
  const mainUser = useMainUser();
  useEffect(() => {
    document
      .querySelector('html')
      ?.setAttribute(
        'data-theme',
        mainUser?.network_partner ?? NETWORK_PARTNER['new-vision']
      );
    return () => {
      document
        .querySelector('html')
        ?.setAttribute('data-theme', NETWORK_PARTNER.Agrivar);
    };
  }, [mainUser?.network_partner]);

  return (
    <div className={clsx('max-w-[1600px] py-4 mx-auto', 'md:py-8')}>
      <AdminHeader />
      <div id="main" className="mt-8">
        <DashboardLayout />
      </div>
    </div>
  );
}

export default AdminLayout;
