import { ListObjectsV2Command, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import * as turf from '@turf/turf';
import { area } from '@turf/turf';
import { tableFromIPC } from 'apache-arrow';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Feature, FeatureCollection } from 'geojson';
import apiClient, { apiClientWithoutToken } from './api/api-client';
import {
  BaseSymbol,
  CodeToCrop,
  CodeToMonth,
  CodeToYear,
  DefaultGeoJSONValue,
  DefaultGetPositionParams,
  futuresContracts,
  MonthToCode,
  NETWORK_PARTNER,
  tabToUnit,
} from './constants';
import * as parquetModule from './helpers/arrow1';
import { GoogleGeocodeResponse } from './types';
import { theme } from 'twin.macro';
import moment from 'moment';

const client = new S3Client({
  // The AWS Region where the Amazon Simple Storage Service (Amazon S3) bucket will be created. Replace this with your Region.
  region: 'us-east-2',
  credentials: fromCognitoIdentityPool({
    // Replace the value of 'identityPoolId' with the ID of an Amazon Cognito identity pool in your Amazon Cognito Region.
    identityPoolId: 'us-east-2:b24d7618-4605-4744-8bfb-9aea97cad24d',
    // Replace the value of 'region' with your Amazon Cognito Region.
    clientConfig: { region: 'us-east-2' },
  }),
});

dayjs.extend(utc);

export const getCropColors = (cropName: string, network = '') => {
  const colorsByNetwork = {
    [NETWORK_PARTNER.Agrivar]: {
      Wheat: '#62a2eb',
      CRP: '#80bc00',
      Corn: '#ffb71b',
      Soybeans: '#ae9a7d',
      Sunflowers: '#ff7a1b',
      Other: '#f42727',
      Farm: '#ffb71b',
    },
    [NETWORK_PARTNER.heartland]: {
      Wheat: '#005881',
      CRP: '#00815D',
      Corn: '#D4AF37',
      Soybeans: '#814700',
      Sunflowers: '#ff7a1b',
      Other: '#9B002B',
      Farm: '#ffb71b',
    },
  };
  switch (cropName) {
    case 'Wheat':
      return colorsByNetwork[network]?.Wheat ?? '#62a2eb';
    case 'CRP':
      return colorsByNetwork[network]?.CRP ?? '#80bc00';
    case 'Corn':
      return colorsByNetwork[network]?.Corn ?? '#ffb71b';
    case 'Soybeans':
      return colorsByNetwork[network]?.Soybeans ?? '#ae9a7d';
    case 'Sunflowers':
      return colorsByNetwork[network]?.Sunflowers ?? '#ff7a1b';
    case 'Other':
      return colorsByNetwork[network]?.Other ?? '#f42727';
    case 'Farm':
      return colorsByNetwork[network]?.Farm ?? '#ffb71b';
    default:
      return '#1b2227';
  }
};

export const convertToFloat = (value?: string) => {
  if (Number.isNaN(value)) return 0;
  return isNaN(parseFloat(value ?? '0')) ? 0 : parseFloat(value ?? '0');
};

export const parseUnit8ArrayFromCssColorHex = (
  colorHex: string
): Uint8Array => {
  const hex = colorHex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return new Uint8Array([r, g, b]);
};

export const getFullSymbol = (crop: string, year: string) => {
  const suffix = year.toString().slice(-1);
  return `${BaseSymbol[crop as keyof typeof BaseSymbol]}${suffix}`;
};

export const parseCropBySymbol = (symbol: string) => {
  return Object.keys(BaseSymbol).find(
    (key) => BaseSymbol[key as keyof typeof BaseSymbol] === symbol.slice(0, 3)
  );
};

//Convert the date i.e. Jan '22 to 01-01-2022
export const convertDate = (date: string) => {
  const _date = date.split("'");
  const year = '20' + _date[1];
  const prefixMonth = _date[0].trim().slice(0, 3);
  let month;

  switch (prefixMonth) {
    case 'Jan':
      month = '01';
      break;
    case 'Feb':
      month = '02';
      break;
    case 'Mar':
      month = '03';
      break;
    case 'Apr':
      month = '04';
      break;
    case 'May':
      month = '05';
      break;
    case 'Jun':
      month = '06';
      break;
    case 'Jul':
      month = '07';
      break;
    case 'Aug':
      month = '08';
      break;
    case 'Sep':
      month = '09';
      break;
    case 'Oct':
      month = '10';
      break;
    case 'Nov':
      month = '11';
      break;
    case 'Dec':
      month = '12';
      break;
    default:
      break;
  }

  return `01-${month}-${year}`;
};

export const sortByDate = (data: any[]) => {
  return data?.sort((a, b) => {
    const before = dayjs.utc(a.date);
    const after = dayjs.utc(b.date);
    return before.isBefore(after) ? -1 : 1;
  });
};

// export const sortObjectByDate = (obj: any, dateKey = "date") => {
//   let sortedObj: { [key: string]: any } = {};
//   Object.keys(obj || {}).map((key) => {
//     let items = obj[key];
//     items.sort((a: any, b: any) => {
//       const before = dayjs.utc(a[dateKey], "MM-YYYY");
//       const after = dayjs.utc(b[dateKey], "MM-YYYY");

//       return before.isBefore(after) ? -1 : 1;
//     });
//     sortedObj[key] = items;
//     return sortedObj;
//   });
//   return sortedObj;
// };

export const groupBy = (array: any[], key: string) => {
  if (!array) return {};
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const parseUserIdCrop = (user_id_crop: string) => {
  return user_id_crop.split('-')[1];
};

// For fetch basis data
export const getDefaultSymbols = (year: string) => {
  const today = moment();
  const suffix = year.toString().slice(-1);
  const cornList = (futuresContracts as any)['Corn']?.[year] || [];
  const wheatList = (futuresContracts as any)['Wheat']?.[year] || [];
  const soybeansList = (futuresContracts as any)['Soybeans']?.[year] || [];
  const corn = cornList.filter(
    (c: any) => today.isAfter(c.startDate) && today.isBefore(c.endDate)
  );
  const soybeans = soybeansList.filter(
    (c: any) => today.isAfter(c.startDate) && today.isBefore(c.endDate)
  );
  const wheat = wheatList.filter(
    (c: any) => today.isAfter(c.startDate) && today.isBefore(c.endDate)
  );

  const corn1 =
    corn.length === 0 ? cornList?.[0]?.value || `ZCZ${suffix}` : corn[0].value;
  const soybeans1 =
    soybeans.length === 0
      ? soybeansList?.[0]?.value || `ZSX${suffix}`
      : soybeans[0].value;
  const wheat1 =
    wheat.length === 0
      ? wheatList?.[0]?.value || `KEZ${suffix}`
      : wheat[0].value;
  return {
    Corn: corn1,
    Soybeans: soybeans1,
    Wheat: wheat1,
  } as { [key: string]: string };
};

export const shortCurrency = (
  value: number | string,
  maximumFractionDigits = 1
) => {
  const displayValue = value === undefined ? 0 : value;
  return displayValue.toLocaleString('en-US', {
    notation: 'compact',
    currency: 'USD',
    style: 'currency',
    maximumFractionDigits,
  });
};

export const shortNumber = (
  value: number | string,
  maximumFractionDigits = 0
) => {
  const displayValue = value === undefined ? 0 : value;
  return displayValue.toLocaleString('en-US', {
    maximumFractionDigits,
    notation: 'compact',
  });
};

export const convertSymbolToContract = (symbol: string) => {
  if (!symbol) return;
  const symbol1 = symbol.slice(-4);
  const cropCode = symbol1.slice(0, 2);
  const monthCode = symbol1.slice(2, 3);
  const yearCode = parseInt(symbol1.slice(3));

  const crop = CodeToCrop[cropCode as keyof typeof CodeToCrop] ?? null;
  const month = CodeToMonth[monthCode as keyof typeof CodeToMonth] ?? null;
  const year = CodeToYear[yearCode as keyof typeof CodeToYear] ?? null;

  return { crop, month, year };
};

export const getTime = (value: string) => {
  try {
    const date = value ? new Date(value) : new Date();
    const time = Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      hourCycle: 'h23',
      minute: '2-digit',
    }).format(date);
    return `${time} CT`;
  } catch (error) {
    return '';
  }
};

export const convertToContractSymbolByCrop = (
  crop: string,
  contractDate: string
) => {
  if (!contractDate || !crop) return null;

  const cropRoots: { [key: string]: string } = {
    Corn: 'ZC',
    Soybeans: 'ZS',
    Wheat: 'KE',
    'HRW Wheat': 'KE',
    'SRW Wheat': 'ZW',
  };

  const monthCodes: { [key: string]: string } = {
    Jan: 'F',
    Feb: 'G',
    Mar: 'H',
    Apr: 'J',
    May: 'K',
    Jun: 'M',
    Jul: 'N',
    Aug: 'Q',
    Sep: 'U',
    Oct: 'V',
    Nov: 'X',
    Dec: 'Z',
  };

  const root = cropRoots[crop];
  const month = monthCodes[contractDate?.slice(0, 3)];
  const year = contractDate?.slice(-1);
  if (!root || !month) return null;

  return `${root}${month}${year}`;
};

export const getNextLoginFlowRoute = (
  flow:
    | 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE'
    | 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION'
    | 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
    | 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
    | 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP'
    | 'CONFIRM_SIGN_UP'
    | 'RESET_PASSWORD'
    | 'DONE'
    | 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
) => {
  switch (flow) {
    case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
      return '/verify-sms';
    case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
      return '/new-password';
    case 'DONE':
      return '/';
    default:
      return '/login';
  }
};

export const getLatLong = async ({
  address,
  city,
}: {
  address: string;
  city: string;
}) => {
  const geocoderQuery = encodeURIComponent(
    `${address} ${city}`.replace(/ /g, '+')
  );
  const { data } = await apiClientWithoutToken.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${geocoderQuery}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );
  if (data.results.length === 0) {
    return null;
  }
  const geoData: GoogleGeocodeResponse = data.results[0];
  const lat: number = geoData.geometry.location.lat;
  const lng: number = geoData.geometry.location.lng;
  return { lat, lng };
};

const getCounties = async () => {
  type FDF = {
    fips: string;
    state: string;
    county: string;
  }[];
  const { data } = await apiClientWithoutToken.get(
    'http://www2.census.gov/geo/docs/reference/codes/files/national_county.txt'
  );

  const fdf: FDF = data.split('\n').reduce((acc: FDF, line: string) => {
    const row = line.split(',');
    return [
      ...acc,
      {
        fips: row[1] + row[2],
        county: row[3].replace(' County', ''),
        state: row[0],
      },
    ];
  }, [] as FDF);
  return fdf;
};

export const getFips = async ({ lat, lng }: { lat: number; lng: number }) => {
  const { data } = await apiClientWithoutToken.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );

  if (data.results && data.results.length > 0) {
    const geoData: GoogleGeocodeResponse = data.results[0];

    const stateCode = geoData.address_components.find((component) =>
      component.types.includes('administrative_area_level_1')
    )?.short_name;

    const countyCode = geoData.address_components.find((component) =>
      component.types.includes('administrative_area_level_2')
    )?.short_name;

    if (stateCode && countyCode) {
      const counties = await getCounties();
      const fips = counties?.find(
        (county) => county.state === stateCode && county.county === countyCode
      );
      return fips?.fips;
    }
  }
};

export function calculateNextDate(startDate: Date, days: number): string {
  // Create a new date object to avoid mutating the original date
  const resultDate = new Date(startDate);

  // Add the number of days to the result date
  resultDate.setDate(resultDate.getDate() + days);

  // Format the date as "Jun 6, 2024" using Intl.DateTimeFormat
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    resultDate
  );

  return formattedDate;
}

export function calculateDaysDifference(date1: Date, date2: Date): number {
  // Get the time difference in milliseconds
  const timeDifference = date2.getTime() - date1.getTime();

  // Convert time difference from milliseconds to days
  const dayDifference = timeDifference / (1000 * 3600 * 24);

  return Math.round(dayDifference);
}

export function parseContractDate(dateStr: string): Date {
  const [month, year] = dateStr.split(" '");
  const yearFull = 2000 + parseInt(year); // Convert '26' to 2026
  const monthIndex = new Date(Date.parse(month + ' 1, 2000')).getMonth(); // Use a fixed year to get the correct month index

  return new Date(yearFull, monthIndex);
}

export async function getS3Keys(bucket: string, prefix: string) {
  const command = new ListObjectsV2Command({
    Bucket: bucket,
    Prefix: prefix,
  });

  try {
    let isTruncated: boolean | undefined = true;
    let contents: any = [];
    while (isTruncated) {
      const { Contents, IsTruncated, NextContinuationToken } =
        await client.send(command);
      const contentsList = Contents?.map((c) => c.Key) || [];

      contents = contents.concat(contentsList);
      isTruncated = IsTruncated;
      command.input.ContinuationToken = NextContinuationToken;
    }

    return contents;
  } catch (err) {
    console.error('Failed to list S3 keys:', err);
    return [];
  }
}

export const getLatestKeys = async (bucket: string, prefix: string) => {
  const wxkeys = await getS3Keys(bucket, prefix);
  return wxkeys.sort();
};

export const getParquetFile = async (bucket: string, objectName: string) => {
  const res = await apiClient.post(`/presignedlink`, {
    s3_bucket: bucket,
    object_name: objectName,
  });
  try {
    const response = await fetch(res.data.body);
    if (response.status === 404) return false;
    else return response;
  } catch (e: any) {
    return e;
  }
};

export const parseParquetData = async (data: any) => {
  await parquetModule.default();
  const parquetUint8Array = new Uint8Array(await data.arrayBuffer());
  const arrowUint8Array = parquetModule.readParquet(parquetUint8Array);
  const arrowTable = tableFromIPC(arrowUint8Array);
  return arrowTable;
};

export async function getParquetData(bucket: string, objectName: string) {
  let resp;
  try {
    resp = await getParquetFile(bucket, objectName);
    if (resp?.status === 404) {
      // resp = await getParquetFile(s3_bucket, defaultObjectName);
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
    // resp = await getParquetFile(s3_bucket, defaultObjectName);
  }
  const _data = resp.status === 200 ? await parseParquetData(resp) : [];
  return _data;
}

export const convertBigInt64ToInt = (value: any) => {
  if (typeof value === 'bigint') {
    return Number(value);
  } else {
    return value;
  }
};

export function customRoundUp(number: number) {
  // Determine the magnitude of the number (10^x closest to the number)
  const magnitude = Math.pow(10, Math.floor(Math.log10(number)));

  // Determine the rounding target based on the magnitude
  let roundingTarget;
  if (magnitude < 1000) {
    // For numbers less than 1000, round up to the next 100 or 1000
    roundingTarget = magnitude < 100 ? 100 : 1000;
  } else {
    // For numbers 1000 and above, round up to the next magnitude
    roundingTarget = Math.pow(10, Math.floor(Math.log10(number)) + 1);
  }

  // Calculate the rounded value
  const roundedValue = Math.ceil(number / roundingTarget) * roundingTarget;

  return roundedValue;
}

export const getYearOptions = (total = 10, yearAhead = 1, full = true) => {
  // full = true: 2022, 2023, 2024, ... full = false: 22, 23, 24, ...
  return [...Array(total)].map((_, index) => ({
    label: `${new Date().getFullYear() + yearAhead - index}`,
    value: `${(new Date().getFullYear() + yearAhead - index).toString().substring(full ? 0 : 2)}`,
  }));
};

export const getCrop2 = (crop: string) => {
  let crop2: string;
  switch (crop) {
    case 'Corn for Grain':
    case 'Corn for Silage':
      crop2 = 'Corn';
      break;
    case 'Soybeans GMO':
    case 'Soybeans Non-GMO':
      crop2 = 'Soybeans';
      break;
    case 'Spring Wheat':
    case 'Winter Wheat':
      crop2 = 'Wheat';
      break;
    case 'Sunflowers':
      crop2 = 'Sunflowers';
      break;
    case 'Sorghum':
      crop2 = 'Sorghum';
      break;
    case 'Other':
      crop2 = 'Other';
      break;
    default:
      crop2 = crop;
      break;
  }
  return crop2;
};

export function formatAlertDate(dateString: string): string {
  // Create a new Date object from the input string
  const [year, month] = dateString.split('-');

  const date = new Date(parseInt(year), parseInt(month) - 1); // Months are 0-indexed in JavaScript

  // Format the month using toLocaleString
  const monthFormatted = date.toLocaleString('en-US', { month: 'short' });

  // Extract the last two digits of the year
  const yearFormatted = `'${date.getFullYear().toString().slice(-2)}`;

  return `${monthFormatted} ${yearFormatted}`;
}

export function formatSpreadDate(
  month1: string,
  month2: string,
  year1: string,
  year2: string
): string {
  const month1Formatted = month1.slice(0, 3);
  const month2Formatted = month2.slice(0, 3);
  return `${capitalizeFirst(month1Formatted)} '${year1.slice(2, 4)} - ${capitalizeFirst(month2Formatted)} '${year2.slice(2, 4)}`;
}

export function getArea(feature: Feature) {
  return (area(feature) / 4046.8564224).toFixed(2);
}

export const getPositionInfo = async (params: { geometry: string }) => {
  const res = await apiClientWithoutToken.get(
    `https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/3/query?`,
    {
      params: { ...DefaultGetPositionParams, ...params },
    }
  );
  return res.data;
};

export function getGeoJSON(feature: Feature) {
  if (!feature) return;
  const result = { ...DefaultGeoJSONValue, features: [feature] };
  return result;
}

export function parseOptionsContract(contract: string) {
  // Extract components from the contract string
  const commodityCode = contract.substring(1, 3);
  const monthCode = contract.charAt(3);
  const yearCode = contract.substring(4, 5);
  const optionTypeCode = contract.charAt(6);
  const strikePriceCents = contract.substring(7);

  let year = '';
  if (parseInt(yearCode) < 7) {
    year = '2' + yearCode;
  } else {
    year = '1' + yearCode;
  }

  // Map option type code to option type
  const optionTypeCodes = {
    C: 'call',
    P: 'put',
  };

  const commodity = CodeToCrop[commodityCode as keyof typeof CodeToCrop] || '';
  const month = CodeToMonth[monthCode as keyof typeof CodeToMonth] || '';
  const optionType =
    optionTypeCodes[optionTypeCode as keyof typeof optionTypeCodes] || '';
  const strikePrice = (parseInt(strikePriceCents, 10) / 100).toFixed(2); // Convert cents to dollars

  return {
    commodity,
    month,
    year,
    optionType,
    strikePrice: `$${strikePrice}`,
  };
}

export function constructOptionsSymbol(
  root: string,
  month: string,
  year: string,
  optionType: string,
  strikePrice: string
) {
  const monthCode = MonthToCode[month as keyof typeof MonthToCode];
  const yearCode = year.slice(-1);
  let optionTypeCode = '';
  if (optionType === 'call') {
    optionTypeCode = 'C';
  } else {
    optionTypeCode = 'P';
  }
  return `O${root}${monthCode}${yearCode} ${optionTypeCode}${strikePrice}`;
}

export const formatStrikePriceToDollars = (strikePrice: string) => {
  return `$${(parseInt(strikePrice) / 100).toFixed(2)}`;
};

export const filterWxData = (
  wData: any,
  filter: any,
  fieldFilter = '',
  zoneFilter = '',
  fieldsFilter = '',
  zonesFilter = '',
  cropFilter?: string
) => {
  if (fieldFilter) {
    const result: { [key: string]: any } = {};
    if (!wData.field_index) return {};
    for (let index = 0; index < wData.field.length; index++) {
      const fieldArray = new Uint8Array(wData.field[index]);
      const field = new TextDecoder().decode(fieldArray);

      if (fieldFilter === field) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  }

  if (zoneFilter) {
    const result: { [key: string]: any } = {};
    if (!wData.field_index) return {};
    for (let index = 0; index < wData.field_index.length; index++) {
      const zoneArray = new Uint8Array(wData.agrivar_zone2[index]);
      const zone = new TextDecoder().decode(zoneArray);

      if (zoneFilter === zone) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  }

  if (fieldsFilter) {
    const result: { [key: string]: any } = {};
    if (!wData.field_index) return {};
    for (let index = 0; index < wData.field_index.length; index++) {
      const fieldArray = new Uint8Array(wData.field_index[index]);
      let field = new TextDecoder().decode(fieldArray);
      field = field.toString();

      if (fieldsFilter.includes(field)) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  }

  if (zonesFilter) {
    const result: { [key: string]: any } = {};
    if (!wData.agrivar_zone2) return {};
    for (let index = 0; index < wData.agrivar_zone2.length; index++) {
      const zoneArray = new Uint8Array(wData.agrivar_zone2[index]);
      let zone = new TextDecoder().decode(zoneArray);
      zone = zone.toString();

      if (zonesFilter.includes(zone)) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  }

  if (cropFilter) {
    const result: { [key: string]: any } = {};
    if (!wData.crop) return {};
    for (let index = 0; index < wData.crop.length; index++) {
      const cropArray = new Uint8Array(wData.crop[index]);
      const crop = new TextDecoder().decode(cropArray);

      if (cropFilter === crop) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  }

  if (!filter) return wData;

  const filterKey = filter.key?.toLowerCase();
  const filterRole = filter.role;

  if (filterKey === 'all') return wData;
  else if (filterRole === 1) {
    const result: { [key: string]: any } = {};
    if (!wData.crop) return {};
    for (let index = 0; index < wData.crop.length; index++) {
      const cropArray = new Uint8Array(wData.crop[index]);
      const crop = new TextDecoder().decode(cropArray);

      if (crop?.toLowerCase() === filterKey) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  } else {
    const fieldIndex = filterKey?.split('-')[1];
    const result: { [key: string]: any } = {};
    if (!wData.field_index) return {};
    for (let index = 0; index < wData.field_index.length; index++) {
      const fieldArray = new Uint8Array(wData.field_index[index]);
      const field = new TextDecoder().decode(fieldArray);

      if (parseInt(fieldIndex) === parseInt(field)) {
        Object.keys(wData).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(wData[key][index]);
        });
      }
    }
    return result;
  }
};

export const filterWxDataByValue = (wData: any, values: any) => {
  if (!values || values.length !== 2) return wData;

  const value1 = parseFloat(values[0]);
  const value2 = parseFloat(values[1]);

  let min = value1;
  let max = value2;

  if (value1 > value2) {
    min = value2;
    max = value1;
  }

  if (!wData.value) return {};

  const result: {
    [key: string]: any;
  } = {};
  for (let index = 0; index < wData.value.length; index++) {
    const value = wData.value[index];

    if (!(value < min || value > max)) {
      Object.keys(wData).forEach((key) => {
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(wData[key][index]);
      });
    }
  }
  return result;
};

export const getWxDataCenter = (wxData: any) => {
  if (!wxData.length) return undefined;
  let result = {
    lat: 0,
    lon: 0,
  };
  const lats: any[] = [];
  const lons: any[] = [];
  wxData.forEach((wData: any) => {
    lats.push(...wData.lat);
    lons.push(...wData.lon);
  });

  const lat = lats.reduce((a, b) => a + b) / lats.length;
  const lon = lons.reduce((a, b) => a + b) / lons.length;

  result.lat = lat;
  result.lon = lon;

  return result;
};

export const getDateSet = (keys: any) => {
  const obs: any[] = keys.map(
    (key: any) => key.split('_')[key.split('_').length - 4]
  );
  return [...Array.from(new Set(obs))];
};

export const getTimeSet = (keys: any) => {
  const obj: any[] = keys.map((key: any) => key.split(' ')[1]?.slice(0, 5));
  return [...Array.from(new Set(obj))];
};

export const parseParquetData2 = async (data: any) => {
  await parquetModule.default();
  const parquetUint8Array = new Uint8Array(await data.arrayBuffer());
  const arrowUint8Array = parquetModule.readParquet(parquetUint8Array);
  const arrowTable = tableFromIPC(arrowUint8Array);
  return arrowTable;
};

export async function getParquetData2(s3_bucket: string, object_name: string) {
  let resp;
  try {
    resp = await getParquetFile(s3_bucket, object_name);
    if (resp.status === 404) {
      // resp = await getParquetFile(s3_bucket, defaultObjectName);
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
    // resp = await getParquetFile(s3_bucket, defaultObjectName);
  }
  const _data = resp.status === 200 ? await parseParquetData2(resp) : [];
  return _data;
}

export const getwxData = async (bucket: string, skey: string) => {
  const latestData = await getParquetData2(bucket, skey);
  // @ts-ignore
  const data = latestData.data[0];
  const sTypes = data.type.children;

  const wxData: {
    [key: string]: any;
  } = {};

  for (let index = 0; index < sTypes.length; index++) {
    const type = sTypes[index];
    // @ts-ignore
    const wValues = latestData.getChildAt(index).data[0].values;
    // @ts-ignore
    const valueOffsets = latestData.getChildAt(index).data[0].valueOffsets;

    if (valueOffsets) {
      const values = [];

      for (let i = 0; i < valueOffsets.length; i++) {
        const value = [];
        const offset = valueOffsets[i];
        const next_offset = valueOffsets[i + 1];

        if (next_offset && next_offset !== 0) {
          for (let j = offset; j < next_offset; j++) {
            value.push(wValues[j]);
          }

          values.push(value);
        }
      }

      wxData[type.name] = values;
    } else {
      wxData[type.name] = wValues;
    }
  }

  return wxData;
};

export const getWxDataViewBounds = (wxData: any) => {
  if (!wxData.length) return undefined;
  let minLon = 180;
  let minLat = 90;
  let maxLon = -180;
  let maxLat = -90;
  let result = null;
  wxData.map((wData: any) => {
    for (const item of wData.lat) {
      if (item < minLat) minLat = item;
      if (item > maxLat) maxLat = item;
    }
    for (const item of wData.lon) {
      if (item < minLon) minLon = item;
      if (item > maxLon) maxLon = item;
    }
  });

  result = [
    [minLon, minLat],
    [maxLon, maxLat],
  ];

  return result;
};

export const capitalizeFirst = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeAll = (str: string): string => {
  if (!str) return '';
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export function formatDate(dateStr: string): string {
  if (!dateStr || dateStr.includes(':')) return format(parseISO('2024-01-01'), 'MMM d, yyyy');
  const parsedDate = parseISO(dateStr);
  return format(parsedDate, 'MMM d, yyyy');
}

export function createSquareCoordinates(
  lon: number,
  lat: number,
  offset: number = 0.000045
): number[][] {
  return [
    [lon - offset, lat + offset], // Top-left
    [lon + offset, lat + offset], // Top-right
    [lon + offset, lat - offset], // Bottom-right
    [lon - offset, lat - offset], // Bottom-left
    [lon - offset, lat + offset], // Close the loop
  ];
}

export const parseMoney = (value: string) => {
  if (!value) return;
  return Number.parseFloat(
    Number(value.toString()?.replace(/[^0-9.-]+/g, '')).toFixed(2)
  );
};

// get median of an array
const getMedian = (values: number[]) => {
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) {
    return values[half];
  }
  return (values[half - 1] + values[half]) / 2.0;
};

export const getCenter = (features: FeatureCollection[]) => {
  if (features?.length) {
    const bboxes = features
      .filter(
        (feat: any) => !!feat?.features?.[0]?.geometry?.coordinates?.length
      )
      .map((feat: any) => (feat.bbox ? feat.bbox : turf.bbox(feat)));
    const lon = getMedian([
      ...bboxes.map((bbox: any) => bbox[0]),
      ...bboxes.map((bbox: any) => bbox[2]),
    ]);
    const lat = getMedian([
      ...bboxes.map((bbox: any) => bbox[1]),
      ...bboxes.map((bbox: any) => bbox[3]),
    ]);

    return { lon, lat };
  }
};

export const getDate = (value?: string) => {
  try {
    const date = value ? new Date(value) : new Date();
    const dateArray = Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
      .format(date)
      .split('/');
    return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
  } catch (error) {
    return '';
  }
};

export const removeQuotes = (str: string): string => {
  return str.replace(/^"|"$/g, '');
};

export const lineChartColorsByNetwork = (network?: string) => {
  switch (network) {
    case NETWORK_PARTNER.heartland: {
      return {
        startColor: 'rgba(0, 129, 93, 0.6)',
        stopColor: 'rgba(0, 129, 93, 0)',
        borderColor: 'rgba(0, 129, 93, 1)',
        scalesColor: '#646568',
      };
    }
    default:
      return {
        startColor: 'rgba(128, 188, 0, 0.6)',
        stopColor: 'rgba(128, 188, 0, 0)',
        borderColor: '#80BC00',
        scalesColor: 'white',
      };
  }
};

export const fillColors = (network?: string) => {
  let active = theme`colors.base.000`;
  let inactive = theme`colors.base.900`;

  switch (network) {
    case NETWORK_PARTNER.heartland:
      active = theme`colors.primary`;
      inactive = theme`colors.base.500`;
      break;
    default:
      break;
  }

  return { active, inactive };
};

export const formatXBin = (x_bin: string, typeParam: string) => {
  const unit = tabToUnit[typeParam as keyof typeof tabToUnit];

  // Function to format number with unit
  const formatNumberWithUnit = (num: string) => {
    // For $ unit, we want $ to appear before the number
    if (unit === '$') {
      return `$${num}`;
    }
    // For % unit, we want no space between the number and %
    if (unit === '%') {
      return `${num}%`;
    }
    // For other units, the unit comes after the number with a space
    return `${num} ${unit}`;
  };

  // Handle the range case (e.g. "90-100")
  // if (x_bin.includes('-')) {
  //   const parts = x_bin.split('-').filter(Boolean); // Remove empty strings caused by splitting

  //   if (parts.length === 2) {
  //     const [first, second] = parts;

  //     const formattedFirst = formatNumberWithUnit(first);
  //     const formattedSecond = formatNumberWithUnit(second);

  //     return `${formattedFirst} - ${formattedSecond}`;
  //   }
  // }

  // Handle the single negative number case (e.g. "-100")
  // if (x_bin[0] === '-') {
  //   return formatNumberWithUnit(x_bin.slice(1)) + `-${unit}`;
  // }

  // Default single number case
  return formatNumberWithUnit(x_bin);
};

export const spaceFormatXBin = (xbin: string) => {
  if (xbin.includes('-')) {
    const parts = xbin.split('-').filter(Boolean); // Remove empty strings caused by splitting
    if (parts.length === 2) {
      const [first, second] = parts;
      return `${first} - ${second}`;
    }
  }
  return xbin;
};


export function formatTimeTo12Hour(time24: string) {
  if (!time24 || !time24.includes(':')) return '';
  let [hours, minutes] = time24?.split(':').map(Number);
  let period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // If hours is 0 (midnight), set to 12
  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
}