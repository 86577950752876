import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { FertilizerType } from '.';
import {
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { customRoundUp } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import KnobContainer from '../knob';

export default function FertilizerInfo({
  selectedZone,
  onFlip,
  fertilizer,
  onUpdate,
  loading,
  selectedProduct,
}: {
  selectedZone: FieldPrescriptionWithProducts;
  onFlip: () => void;
  fertilizer: FertilizerType;
  onChange?: (value: number, fertilizerShortName: string) => void;
  onUpdate: (value: number, fertilizerShortName: string) => void;
  loading: boolean;
  selectedProduct: PrescriptionProductRes;
}) {
  const fertilizerShortName = fertilizer.id.toLowerCase();
  const rate =
    Number(
      selectedZone?.[`${fertilizerShortName}_rate`] ??
        selectedZone?.[`recommended_${fertilizerShortName}_rate`]
    ) ?? 0;

  const avgApplied = Number(
    selectedZone?.[`average_${fertilizerShortName}_rate`] ?? 0
  );

  const agrivarApplied = Number(
    selectedZone?.[`recommended_${fertilizerShortName}_rate`] ?? 0
  );

  const marks = [
    {
      name: `Average ${fertilizer.name} Applied`,
      value: avgApplied,
      color: 'bg-blue',
    },
    {
      name: `AgriVaR ${fertilizer.name} Applied`,
      value: agrivarApplied,
      color: 'bg-red',
    },
  ];

  const chartLegend = [
    {
      label: `Low ${fertilizer.name} Yield Response`,
      color: 'bg-primary',
    },
    {
      label: `Expected ${fertilizer.name} Yield Response`,
      color: 'bg-secondary',
    },
    {
      label: `High ${fertilizer.name} Yield Response`,
      color: 'bg-tertiary',
    },
    {
      label: 'Expected Zone Yield',
      color: 'bg-red',
    },
  ];

  const max = useMemo(
    () =>
      customRoundUp(
        Number(
          // @ts-ignore
          selectedZone?.[`recommended_${fertilizerShortName}_rate`]
        )
      ),
    [fertilizer.id, selectedZone]
  );

  return (
    <div className="space-y-6">
      <CardHeader
        title={`${fertilizer.name} (lbs. Per Acre)`}
        tooltip="seeding rate"
      />
      <KnobContainer
        key={rate}
        name={'ferilizer'}
        id={`${selectedZone.agrivar_zone1}-${fertilizerShortName}`}
        min={0}
        max={isNaN(max) ? 1000 : max}
        value={rate}
        marks={marks}
        onChange={(value) => onUpdate(value, fertilizerShortName)}
        onUpdate={(value) => onUpdate(value, fertilizerShortName)}
        loading={loading}
      />

      {/* Chart */}

      <Button
        size="lg"
        color="primary"
        startIcon={<PlusCircleIcon className="size-6" />}
        shadow
        onClick={onFlip}
        disabled={!selectedZone?.field_index}
      >
        {`Add Additional ${fertilizer.name}`}
      </Button>
    </div>
  );
}
