import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import PoweredByArivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import { useSignin } from '../../../hooks/auth/use-signin';
import StyledCard from '../../../components/main/StyledCard';
interface LoginPayload {
  email: string;
  password: string;
}

const validateSchema = yup.object({
  email: yup.string().email().required().label('Email'),
  password: yup.string().required().label('Password'),
});

export default function LoginPage() {
  const navigate = useNavigate();
  const { signIn, isPending } = useSignin();

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validateSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { handleSubmit } = form;

  const handleLogin = async (values: LoginPayload) => {
    await signIn(values.email, values.password);
  };

  const disabled = !form.formState.isValid || isPending;

  return (
    <FormProvider {...form}>
      <div className="flex flex-col justify-center items-center p-4">
        <div className="max-w-screen-sm w-full">
          <img
            src={PoweredByArivarLogo}
            alt="Logo"
            width={'70%'}
            className="mb-4 mx-auto"
          />
          <StyledCard title=" Welcome to AgriVaR!">
            <form
              className="flex flex-col w-full space-y-6 pb-8 px-8"
              onSubmit={handleSubmit(handleLogin)}
            >
              <h3 className="display-sm-regular text-center">
                Please sign in to continue
              </h3>
              <div className="space-y-5">
                <div className="flex space-x-4">
                  <label className="text-xl-regular !font-light pt-1.5 min-w-20">
                    Username
                  </label>
                  <TextInput
                    name="email"
                    placeholder="Enter your email or username here"
                    className="!bg-base-000 !text-base-1100"
                    required
                  />
                </div>
                <div className="flex space-x-4">
                  <label className="text-xl-regular !font-light pt-1.5 min-w-20">
                    Password
                  </label>
                  <TextInput
                    name="password"
                    type="password"
                    placeholder="Enter your password here"
                    className="!bg-base-000 !text-base-1100"
                    required
                  />
                </div>
              </div>

              <Link
                className="text-[#a2a2a2] font-light text-center"
                to="/reset-password"
              >
                Forgot Your Password?
              </Link>

              <div className="space-y-5">
                <Button
                  type="submit"
                  color="primary"
                  disabled={disabled}
                  loading={isPending}
                  size="lg"
                  className="!rounded-md !font-normal"
                >
                  Sign in
                </Button>
                <Button
                  type="button"
                  onClick={() => navigate('/register')}
                  size="lg"
                  className="!rounded-md bg-[#3A3A3A] !font-normal"
                >
                  New to AgriVaR? Sign Up!
                </Button>
              </div>
            </form>
          </StyledCard>
        </div>
      </div>
    </FormProvider>
  );
}
