import { InformationCircleIcon } from '@heroicons/react/24/solid';

interface PageHeaderProps {
  title: string;
  tooltip?: string;
}

function PageHeader(props: PageHeaderProps) {
  const { title, tooltip } = props;

  return (
    <div className="flex space-x-6 items-center justify-center">
      <div id="network-header-logo"></div>
      <span className="flex space-x-2">
        <h3 className="display-2xl-regular">{title}</h3>
        {tooltip && <InformationCircleIcon className="size-4 fill-yellow" />}
      </span>
    </div>
  );
}

export default PageHeader;
