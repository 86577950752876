import { IconProps } from '../../types';

export default function TriangleDownIcon({
  fill = '#c1c1c2',
  width = 8,
  height = 6,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26624 1.90442L4.28968 4.90442C4.12561 5.04504 3.93811 5.11536 3.75061 5.11536C3.53968 5.11536 3.35218 5.04504 3.21155 4.90442L0.23499 1.90442C0.000615 1.69348 -0.0696975 1.36536 0.04749 1.08411C0.164677 0.802856 0.445927 0.615356 0.750615 0.615356H6.72718C7.03186 0.615356 7.28968 0.802856 7.40686 1.08411C7.52405 1.36536 7.47718 1.69348 7.26624 1.90442Z"
        fill={fill}
      />
    </svg>
  );
}
